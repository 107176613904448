import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, ContextMenu, Input, RemoveModal } from '@forma/forma-ui-kit';
import PermissionsTable from '../PermissionsTable';

import { IUserGroupsItem } from 'interfaces/users.interface';

import styles from './user-groups-edit.module.css';

interface UserGroupsItemProps extends IUserGroupsItem {
  allPermissions?: string[],
  onChange: (data: IUserGroupsItem) => void,
  onRemove: (id: string) => void,
}

const UserGroupsItemSkelet = () => {
  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={styles.title}>
          <div className={classNames(styles.name, 'skeleton-loader')} />
        </div>
      </div>
    </div>
  );
};

const UserGroupsItem: FC<UserGroupsItemProps> = ({ id, name, permissions, allPermissions, onChange, onRemove }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [tmpName, setTmpName] = useState<string>(name);
  const [rename, setRename] = useState<boolean>(false);
  const [remove, setRemove] = useState<boolean>(false);

  const handleClickRename = () => {
    setTmpName(name);
    setRename(true);
  };

  const handleClickSave = () => {
    onChange({ id, name: tmpName, permissions });
  };

  const handleClickCancel = () => {
    setRename(false);
  };

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        {rename ? (
          <>
            <div className={styles.title}>
              <Input
                viewStyle="secondary"
                value={tmpName}
                onChange={(e) => setTmpName(e.target.value)}
                className={styles.input}
                autoFocus
              />
            </div>
            <Button
              viewStyle="primary"
              onClick={handleClickSave}
              className={styles.button}
            >
              {t('save')}
            </Button>
            <Button
              viewStyle="tertiary"
              onClick={handleClickCancel}
              className={styles.button}
            >
              {t('cancel')}
            </Button>
          </>
        ) : (
          <>
            <div className={styles.title} onClick={() => setOpen(!open)}>
              <div className={styles.name}>
                {name}
              </div>
              <ReactSVG src="/icons/dropdown-thin.svg" className={classNames(styles.dropdownIcon, open && styles.open)} wrapper="span" />
            </div>
            <ContextMenu
              on="click"
              control={
                <Button
                  className={styles.buttonMore}
                  viewStyle="secondary"
                  icon={<ReactSVG src="/icons/dots-vertical.svg" wrapper="span" />}
                />
              }
              items={[
                {
                  text: t('rename'),
                  onClick: handleClickRename
                },
                {
                  text: t('delete'),
                  onClick: () => setRemove(true)
                }
              ]}
            />
          </>
        )}
      </div>
      {open && (
        <div className={classNames(styles.content, rename && styles.disabled)}>
          <PermissionsTable
            permissions={allPermissions}
            selected={permissions}
            onChange={permissions => onChange({ id, name, permissions })}
          />
        </div>
      )}
      <RemoveModal
        open={remove}
        onClose={() => setRemove(false)}
        title={t('do_yo_want_delete_group')}
        onRemove={() => onRemove(id)}
      />
    </div>
  );
};

export { UserGroupsItemSkelet };
export default UserGroupsItem;

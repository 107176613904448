import { IUserVariableItem, IVariableGroup, IVariableItem, IVariableSchema, IVariablesHierarhyItem } from 'interfaces/variables.interface';

import { baseApi } from '../store';

export const schemasApi = baseApi.enhanceEndpoints({ addTagTypes: ['Variables'] }).injectEndpoints({
  endpoints: builder => ({
    getTemplateSectionsVariables: builder.query<{ [key: string]: IVariableItem }, void>({
      query: () => ({
        url: '/schemas/strictattrs',
        method: 'GET',
      }),
      transformResponse: (result: { schema: IVariableItem[] }) =>
        result.schema.reduce((prev, current) => ({ ...prev, [current.id]: current }), {})
    }),
    getTemplateSectionsGroups: builder.query<{ [key: string]: IVariablesHierarhyItem }, void>({
      query: () => ({
        url: '/schemas/strictattrshierarhy',
        method: 'GET',
      }),
      transformResponse: (result: { schema: IVariablesHierarhyItem[] }) =>
        result.schema.reduce((prev, current) => ({ ...prev, [current.scope]: current }), {})
    }),
    getTemplateVariablesGroups: builder.query<IVariableGroup[], void>({
      query: () => ({
        url: '/schemas/strictattrsfolders',
        method: 'GET',
      }),
      transformResponse: (result: { schema: IVariableGroup[] }) => result.schema
    }),
    getTemplateVariablesTypes: builder.query<IVariableSchema, void>({
      query: () => ({
        url: '/schemas/types',
        method: 'GET',
      }),
      transformResponse: (result: { schema: IVariableSchema }) => result.schema
    }),
    getUserVariables: builder.query<{ [key: string]: IUserVariableItem }, void>({
      query: () => ({
        url: '/cattrs/',
        method: 'GET',
      }),
      providesTags: ['Variables'],
      transformResponse: (result: IUserVariableItem[]) =>
        result.reduce((prev, current) => ({ ...prev, [current.id]: current }), {})
    }),
    updateUserVariable: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/cattrs/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Variables']
    }),
    removeUserVariable: builder.mutation({
      query: id => ({
        url: `/cattrs/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Variables']
    }),
    createUserVariable: builder.mutation({
      query: data => ({
        url: '/cattrs/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Variables']
    }),
    getPermissions: builder.query<string[], void>({
      query: () => ({
        url: '/schemas/permissions',
        method: 'GET'
      }),
      transformResponse: (result: { schema: { permissions: string[] } }) => result.schema.permissions
    }),
  })
});

export const {
  useGetTemplateSectionsVariablesQuery,
  useGetTemplateSectionsGroupsQuery,
  useGetTemplateVariablesGroupsQuery,
  useGetTemplateVariablesTypesQuery,
  useGetUserVariablesQuery,
  useUpdateUserVariableMutation,
  useRemoveUserVariableMutation,
  useCreateUserVariableMutation,
  useGetPermissionsQuery,
} = schemasApi;

import { FC, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ButtonsContainer, LoadingButton, Modal, ThemeContext } from '@forma/forma-ui-kit';
import formatPrice from 'helpers/formatPrice';
import { currencies } from 'data/mock';

import PaymentMethods from '../PaymentMethods';
import ModalSuccess from './ModalSuccess';
import ModalError from './ModalError';
import ModalCalc from './ModalCalc';
import ModalPay from './ModalPay';

import { TPaymentMethod } from 'interfaces/billing.interface';
import { ITariffPrices } from 'interfaces/tariffs.interface';
import { TInvoiceSource } from 'store/billing/billingApi';

import styles from './payment-modal.module.css';

const landingUrl = process.env.REACT_APP_LANDING_URL;

interface PaymentData {
  name: string,
  source: TInvoiceSource,
  duration: number,
  amountToPay: number,
  canChoose: boolean,

  subscriptionId?: string,
  renewPrice?: number,
  renewDate?: string,
}

export interface PaymentActions {
  onSetPayment: () => Promise<string | null>,
  isSetPaymentLoading?: boolean,
  onCreateInvoice: (paymentId: string, billingData: Record<string, string>) => Promise<boolean>,
  isCreateInvoiceLoading?: boolean,
  onDownloadInvoice: () => void
}

interface PaymentModalButtonsProps {
  data: PaymentData,
  renewPrice?: number,
  totalPrice: number,
  billingData: Record<string, string> | null,
  isLoading?: boolean,
  selectedMethod?: TPaymentMethod,
  actions: PaymentActions,
  onClickHowItCalc?: () => void,
  onSubmit: (method: TPaymentMethod, billingData: Record<string, string> | null) => void,
  isSubmitLoading?: boolean
}

const PaymentModalButtons: FC<PaymentModalButtonsProps> = ({
  data, billingData, selectedMethod, isLoading, renewPrice, totalPrice,
  onClickHowItCalc, onSubmit, isSubmitLoading
}) => {
  const { t } = useTranslation();
  const { lang } = useContext(ThemeContext);
  const currency = currencies.ru;

  if (selectedMethod === 'invoice') return (
    <ButtonsContainer className={styles.buttons}>
      <div className={styles.total}>
        {onClickHowItCalc && (
          <span className={styles.totalNoticeLink} onClick={onClickHowItCalc}>
            {t('subscription.how_we_this_calc')}
          </span>
        )}
        {!isLoading && (
          <span className={styles.totalPrice}>{formatPrice(totalPrice, currency)}</span>
        )}
      </div>
      <LoadingButton
        className={classNames(styles.paymentButton, 'button-bill')}
        viewStyle="primary"
        onClick={() => onSubmit(selectedMethod, billingData)}
        disabled={!totalPrice || !data.canChoose || !billingData}
        isLoading={isLoading || isSubmitLoading}
      >
        {t('subscription.bill')}
      </LoadingButton>
    </ButtonsContainer>
  );

  if (selectedMethod === 'card') return (
    <ButtonsContainer className={styles.buttons}>
      {!!renewPrice && (
        <div className={styles.notice}>
          <div>
            <Trans
              i18nKey="subscription.payment_subscription_notice"
              values={{
                count: data.duration / 30,
                total: formatPrice(renewPrice, currency)
              }}
            />
          </div>
          <div>
            <Trans
              i18nKey="subscription.for_know_more_see_faq"
              components={{
                // eslint-disable-next-line
                faq_link: <a className='accent-text' href={`${landingUrl}/${lang}/tariffs/#faq`} target="_blank" />
              }}
            />
          </div>
        </div>
      )}
      <div className={styles.total}>
        {onClickHowItCalc && (
          <span className={styles.totalNoticeLink} onClick={onClickHowItCalc}>
            {t('subscription.how_we_this_calc')}
          </span>
        )}
        {!isLoading && (
          <span className={styles.totalPrice}>{formatPrice(totalPrice, currency)}</span>
        )}
      </div>
      <LoadingButton
        className={classNames(styles.paymentButton, 'button-pay')}
        viewStyle="primary"
        onClick={() => onSubmit(selectedMethod, billingData)}
        disabled={!totalPrice || !data.canChoose}
        isLoading={isLoading || isSubmitLoading}
      >
        {t('subscription.pay')}
      </LoadingButton>
    </ButtonsContainer>
  );

  return null;
};

interface PaymentModalProps {
  open: boolean,
  onClose: (open: false) => void,
  defaultMethod?: TPaymentMethod,
  data: PaymentData,
  prices?: ITariffPrices, // used in 'How it calc modal'
  isLoading?: boolean,
  actions: PaymentActions,
  disableCardPay?: boolean
}

const PaymentModal: FC<PaymentModalProps> = ({
  open, onClose, defaultMethod, isLoading, data, prices, actions, disableCardPay
}) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState<{ success?: boolean, error?: boolean, paymentId?: string } | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<TPaymentMethod | undefined>(defaultMethod);
  const [billingData, setBillingData] = useState<Record<string, string> | null>(null);
  const [isCalcOpen, setCalcOpen] = useState<boolean>(false);

  const handleSubmit = async (method: TPaymentMethod, billingData: Record<string, string> | null) => {
    const paymentId = await actions.onSetPayment();

    if (!paymentId || !method) {
      setStatus({ error: true });
      return;
    }

    if (method === 'card') {
      setStatus({ paymentId });
      return;
    }

    if (method === 'invoice') {
      if (!billingData) {
        setStatus({ paymentId, error: true });
        return;
      }
      const isCreated = actions.onCreateInvoice(paymentId, billingData);
      if (!isCreated) {
        setStatus({ paymentId, error: true });
        return;
      }
      setStatus({ paymentId, success: true });
      return;
    }
  };

  return (
    <Modal
      width="100%"
      maxWidth="985px"
      open={open}
      onClose={onClose}
      title={t('subscription.payment_method')}
      closeOnDocumentClick={false}
    >
      <div className={styles.modal}>
        <div className={styles.methods}>
          <PaymentMethods
            selected={paymentMethod}
            onSelect={setPaymentMethod}
            card={{
              disabled: disableCardPay
            }}
            invoice={{
              disabled: !!data.subscriptionId,
              onFill: setBillingData
            }}
          />
        </div>
        <PaymentModalButtons
          data={data}
          billingData={billingData}
          renewPrice={prices?.renewPrice ?? data.renewPrice}
          totalPrice={prices?.price ?? data.amountToPay}
          isLoading={isLoading}
          selectedMethod={paymentMethod}
          actions={actions}
          onClickHowItCalc={(!isLoading && prices) ? () => setCalcOpen(true) : undefined}
          onSubmit={handleSubmit}
          isSubmitLoading={isLoading || actions.isSetPaymentLoading || actions.isCreateInvoiceLoading}
        />
      </div>

      {prices && (
        <ModalCalc
          open={isCalcOpen}
          onClose={() => setCalcOpen(false)}
          tariffName={data.name}
          prices={prices}
        />
      )}

      <ModalPay
        open={!!(paymentMethod === 'card' && status?.paymentId)}
        onClose={() => { setStatus(null); onClose(false); }}
        id={status?.paymentId ?? ''}
        source={data.source}
        prices={{
          amountToPay: data.amountToPay,
          renewPrice: data.renewPrice,
          renewDate: data.renewDate
        }}
        onSuccess={() => setStatus(prev => prev ? { ...prev, error: false, success: true } : prev)}
        onError={() => setStatus(prev => prev ? { ...prev, error: true } : prev)}
      />

      <ModalError
        open={!!status?.error}
        onClose={() => setStatus(prev => prev ? { ...prev, error: false } : prev)}
        onClickRetry={() => setStatus(prev => prev ? { ...prev, error: false } : prev)}
      />

      <ModalSuccess
        open={!!status?.success}
        onClose={() => {
          setStatus(null);
          setTimeout(() => onClose(false), 200);
        }}
        isInvoice={paymentMethod === 'invoice'}
        onClickDownload={actions.onDownloadInvoice}
        source={data.source}
      />
    </Modal>
  );
};

export default PaymentModal;

import { useEffect, FC } from 'react';
import { DragOverlay, UniqueIdentifier, useDndContext } from '@dnd-kit/core';
import preloadImages from 'helpers/preloadImages';
import { templatesIcons } from 'data/mock';
// import NoItems from '../NoItems';
import { ExplorerListItem, ExplorerListItemSkelet } from './ExplorerListItem';
import DropItem from './DropItem';

import { IFolderListTreeChidlren } from 'interfaces/folders.interface';
import { IUserGroupsItem } from 'interfaces/users.interface';
import {
  IFoldersEditingProps,
  IFoldersActionsProps,
  IFoldersOptionsStateParams,
  IFoldersFavouritesProps
} from 'interfaces/fileExplorer.interface';

import styles from './explorer-list.module.css';

interface ExplorerListProps {
  foldersTree?: IFolderListTreeChidlren[],
  updated?: string | null,
  selected?: string[],
  isLoading?: boolean,
  onToggleMenu: (id: string | null) => void,
  onToggleModal: (open: boolean) => void,
  isModalOpen?: boolean,
  onHoverSubmenuItem: (targetId: string | null) => void,
  favourites?: IFoldersFavouritesProps,
  editing?: IFoldersEditingProps,
  actions: IFoldersActionsProps,
  userGroups?: IUserGroupsItem[],
  hideOptions?: boolean,
  optionsState?: IFoldersOptionsStateParams,
  showEmptyText?: boolean,
  isInGroup?: boolean
}

const SCROLL_OFFSET = 60;

const searchInTree = (folders: IFolderListTreeChidlren[], searchId: UniqueIdentifier): IFolderListTreeChidlren | null => {
  for (const folder of folders) {
    if (folder.id === searchId) return folder;
    else if (folder.children) {
      const res = searchInTree(folder.children, searchId);
      if (res) return res;
    }
  }
  return null;
};

const ExplorerList: FC<ExplorerListProps> = ({
  foldersTree, updated, selected, isLoading, onToggleMenu, onToggleModal, isModalOpen, onHoverSubmenuItem, favourites,
  editing, actions, userGroups, hideOptions, optionsState, showEmptyText, isInGroup
}) => {
  const dndContext = useDndContext();
  const draggingItem = foldersTree && searchInTree(foldersTree, dndContext.active?.data.current?.id);

  useEffect(() => {
    preloadImages([
      ...Object.values(templatesIcons.list.folders),
      ...Object.values(templatesIcons.list.files),
    ]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (updated) {
      // timeout delay for update opened folder content
      setTimeout(() => {
        const elem = document.getElementById(`file_explorer_template_${updated}`);
        if (elem) window.scrollTo({ top: window.scrollY + elem.getBoundingClientRect().top - SCROLL_OFFSET, behavior: 'smooth' });
      }, 200);
    }

    // eslint-disable-next-line
  }, [updated]);

  return (
    <>
      {isLoading ? (
        [...Array(10)].map((item, index) => (
          <ExplorerListItemSkelet key={index} />
        ))
      ) : (
        <DropItem
          id={null}
          dropId="root"
          name=""
          type="folder"
          className={styles.dropContainer}
        >
          <>
            {foldersTree?.map(item => (
              <ExplorerListItem
                item={item}
                updated={updated}
                selected={selected}
                over={
                  (dndContext.over?.data.current?.id !== dndContext.active?.data.current?.folderId) && dndContext.over?.data.current?.id
                }
                optionsState={optionsState}
                userGroups={userGroups}
                onToggleMenu={onToggleMenu}
                onToggleModal={onToggleModal}
                isModalOpen={isModalOpen}
                onHoverSubmenuItem={onHoverSubmenuItem}
                hideOptions={hideOptions}
                editing={editing}
                actions={actions}
                showEmptyText={showEmptyText}
                isInGroup={isInGroup}
                key={item.id}
              />
            ))}
          </>
        </DropItem>
      )}
      {/* <div className={styles.noItems}>
        <NoItems onCreate={editing?.onCreate} favourites={favourites} />
      </div> */}

      <DragOverlay zIndex={10}>
        {!!draggingItem && (
          <ExplorerListItem
            item={draggingItem}
            updated={updated}
            selected={selected}
            optionsState={optionsState}
            userGroups={userGroups}
            onToggleMenu={onToggleMenu}
            onToggleModal={onToggleModal}
            isModalOpen={isModalOpen}
            onHoverSubmenuItem={onHoverSubmenuItem}
            hideOptions={hideOptions}
            editing={editing}
            actions={actions}
            className={styles.draggable}
            showEmptyText={showEmptyText}
          />
        )}
      </DragOverlay>
    </>
  );
};

export default ExplorerList;

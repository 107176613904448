import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { hasAnyPermission, PERMISSIONS } from 'helpers/permissions';
import { getFormaFolders } from './model/getFormaFolders';
import FolderContent from './FolderContent';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useGetFolderContentQuery, useGetFoldersTreeQuery } from 'store/folders/foldersApi';
import { selectOnboarding, selectUserPermissions } from 'store/user/userSlice';
import { selectLayout, setOnboardingModal } from 'store/common/commonSlice';
import { useGetTcRequestsQuery } from 'store/tcrequests/tcrequestsApi';

const Folders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const layout = useAppSelector(selectLayout);
  const userPermissions = useAppSelector(selectUserPermissions);
  const onboarding = useAppSelector(selectOnboarding);
  const canEditOrCreateTemplates = hasAnyPermission(userPermissions ?? [], [PERMISSIONS.TEMPLATES.CREATE, PERMISSIONS.TEMPLATES.EDIT]);

  const { data: tcRequests } = useGetTcRequestsQuery();

  const { data: folder, isLoading: isFoldersLoading, error: pageError }
    = useGetFolderContentQuery(null, { skip: layout === 'list', pollingInterval: 5000 });

  const { data: foldersTree, isLoading: isFoldersTreeLoading }
    = useGetFoldersTreeQuery(undefined, { skip: layout !== 'list', pollingInterval: 5000 });

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status === 404) navigate('/404', { replace: true });
    }
    // eslint-disable-next-line
  }, [pageError]);

  useEffect(() => {
    if (onboarding && tcRequests && !onboarding.templates_sent && !tcRequests.length) {
      navigate('/guide');
    }
    // eslint-disable-next-line
  }, [onboarding, tcRequests]);

  useEffect(() => {
    if (onboarding && !onboarding.templates_list_tooltip && canEditOrCreateTemplates && (folder || foldersTree)) {
      dispatch(setOnboardingModal('templates_list_tooltip'));
    }
    // eslint-disable-next-line
  }, [onboarding, folder, foldersTree, canEditOrCreateTemplates]);

  const formaFolders = getFormaFolders(t);

  return (
    <FolderContent
      type="folders"
      folder={folder}
      foldersItems={folder ? [...formaFolders, ...folder.items] : formaFolders}
      foldersTree={foldersTree ? [...formaFolders, ...foldersTree] : formaFolders}
      isLoading={isFoldersLoading || isFoldersTreeLoading}
    />
  );
};

export default Folders;

import { baseApi } from '../store';

import { IAttachmentCategoriesItem, IAttachmentItem, IAttachmentItemCreate, IAttachmentItemEdit } from 'interfaces/attachments.interface';

export const attachmentsApi = baseApi.enhanceEndpoints({ addTagTypes: ['attachments', 'attachmentsTree', 'attachmentCategories'] }).injectEndpoints({
  endpoints: builder => ({
    getAttachments: builder.query({
      query: data => ({
        url: `/attachments/`,
        method: 'GET',
        params: data
      }),
      providesTags: ['attachments']
    }),
    getAttachmentsTree: builder.query({
      query: data => ({
        url: `/attachments/hierarchy`,
        method: 'GET',
        params: data
      }),
      providesTags: ['attachmentsTree']
    }),
    addAttachment: builder.mutation<IAttachmentItem, IAttachmentItemCreate>({
      query: ({ file, ...data }) => {
        const formData = new FormData();

        formData.append('file', file);
        for (const key of Object.keys(data)) {
          const value = data[key as keyof typeof data];
          if (value) {
            if (typeof value === 'object' && Array.isArray(value)) {
              value.forEach((item, index) => formData.append(`${key}[${index}]`, item));
            } else {
              formData.append(key, value as string | Blob); // dirty type
            }
          }
        }

        return ({
          url: `/attachments/file`,
          method: 'POST',
          body: formData
        });
      },
      invalidatesTags: ['attachmentsTree']
    }),
    removeAttachment: builder.mutation({
      query: id => ({
        url: `/attachments/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => ['attachmentsTree'],
    }),
    getAttachmentById: builder.query({
      query: id => ({
        url: `/attachments/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, id) => [{ type: 'attachments', id }],
    }),
    updateAttachment: builder.mutation<IAttachmentItem, IAttachmentItemEdit>({
      query: ({ id, ...data }) => ({
        url: `/attachments/file/${id}`,
        method: 'PUT',
        body: data
      }),
      onQueryStarted(data, { dispatch, queryFulfilled }) {
        if (data.categories) return;
        const putResult = dispatch(
          attachmentsApi.util.updateQueryData('getAttachmentById', data.id, (draft) => {
            return { ...draft, ...data };
          })
        );
        queryFulfilled.catch(putResult.undo);
      },
      invalidatesTags: (result, error, data) => {
        if (data.categories) return ['attachmentsTree', { type: 'attachments', id: data.id }];
        return ['attachmentsTree'];
      }
    }),
    downloadAttachment: builder.query({
      query: id => ({
        url: `/attachments/${id}/download`,
        method: 'GET',
        responseHandler: (response: Response) => {
          if (!response.ok) return response.text().then(text => JSON.parse(text));
          return response.blob().then(blob => URL.createObjectURL(blob));
        }
      })
    }),
    downloadAttachmentsArchive: builder.query({
      query: data => ({
        url: `/attachments/downloadArchive`,
        method: 'GET',
        params: data,
        responseHandler: (response: Response) => {
          if (!response.ok) return response.text().then(text => JSON.parse(text));
          return response.blob().then(blob => URL.createObjectURL(blob));
        }
      })
    }),
    getAttachmentCategories: builder.query<IAttachmentCategoriesItem[], void>({
      query: () => ({
        url: `/attachmentcategories/`,
        method: 'GET'
      }),
      providesTags: ['attachmentCategories']
    }),
    createAttachmentCategory: builder.mutation<IAttachmentCategoriesItem, { name: string, color: string }>({
      query: data => ({
        url: `/attachmentcategories/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['attachmentCategories']
    }),
  })
});

export const {
  useGetAttachmentsQuery,
  useGetAttachmentsTreeQuery,
  useAddAttachmentMutation,
  useRemoveAttachmentMutation,
  useGetAttachmentCategoriesQuery,
  useCreateAttachmentCategoryMutation,
  useGetAttachmentByIdQuery,
  useUpdateAttachmentMutation,
  useLazyDownloadAttachmentQuery,
  useLazyDownloadAttachmentsArchiveQuery
} = attachmentsApi;

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { PopupPosition } from 'reactjs-popup/dist/types';
import { useAppSelector } from 'store/hooks';
import { Modal, Input, RemoveModal } from '@forma/forma-ui-kit';
import ContextMenu from 'components/ContextMenu';
import { FORMA_FOLDER_ID_PACKS, FORMA_FOLDER_ID_STATIC, FORMA_FOLDER_ID_STRICT, FORMA_FOLDER_ID_TRASH } from 'data/constants';
import { hasPermission, PERMISSIONS } from 'helpers/permissions';

import { IFolderListChidlren, IFolderListTreeChidlren, IFoldersParent } from 'interfaces/folders.interface';
import { IFoldersActionsProps, IFoldersEditingProps } from 'interfaces/fileExplorer.interface';
import { selectUserPermissions } from 'store/user/userSlice';

import styles from './explorer-context-menu.module.css';

interface TemplateContextMenuProps {
  open: boolean,
  item: IFolderListChidlren,
  items?: IFolderListChidlren[] | IFolderListTreeChidlren[],
  parent?: IFoldersParent,
  onToggleMenu: (id: string | null) => void,
  onToggleModal: (open: boolean) => void,
  onHoverSubmenuItem: (id: string | null) => void,
  editing?: IFoldersEditingProps,
  actions?: IFoldersActionsProps,
  position: PopupPosition[] | PopupPosition
}

const TemplateContextMenu: FC<TemplateContextMenuProps> = ({
  open, item, items, parent, onToggleMenu, onToggleModal, onHoverSubmenuItem, editing = {}, actions = {}, position
}) => {
  const { t } = useTranslation();
  const permissions = useAppSelector(selectUserPermissions);
  const canCreate = hasPermission(permissions ?? [], PERMISSIONS.TEMPLATES.CREATE);
  const canEdit = hasPermission(permissions ?? [], PERMISSIONS.TEMPLATES.EDIT);
  const canDelete = hasPermission(permissions ?? [], PERMISSIONS.TEMPLATES.DELETE);

  const [isShowDelete, setShowDelete] = useState(false);
  const [isShowRename, setShowRename] = useState(false);
  const [newName, setName] = useState(item.name);

  const { onRemove, onRename, onCopy, onMove, onDuplicate } = editing;
  const { onFavouritesToggle, onCopyLink, onCopyShared } = actions;

  const isExternal = item.external;
  const canCopyShared = !!(canCreate && isExternal && onCopyShared);
  if (isExternal && !canCopyShared) return null;

  const folders = items?.filter(({ type }) => type === 'folder');
  const canFavorite = !!(!isExternal && onFavouritesToggle);
  const canCopy = !!(canCreate && folders?.length && onCopy);
  const canMove = !!(canEdit && folders?.length && onMove);
  const canCopyToParent = !!(canEdit && parent?.id && onCopy);
  const canMoveToParent = !!(canEdit && parent?.id && onMove);
  const canDuplicate = !!(canCreate && onDuplicate);

  const handleClickRemove = () => {
    if (onRemove) onRemove({ id: item.id, translatedName: item.translatedName, type: item.type });
    setShowDelete(false);
    onToggleModal(false);
    document.body.style.overflow = 'auto';
  };

  const handleClickRename = () => {
    if (onRename) {
      onRename({
        id: item.id,
        type: item.type,
        name: newName,
        parentid: parent?.id,
        groups: item.groups
      });
    }
    setShowRename(false);
    onToggleModal(false);
  };

  const options = isExternal ? [
    canCopyShared && {
      text: t('copy_to_yourself'),
      onClick: () => onCopyShared({ id: item.id, type: item.type }),
    },
  ] : [
    canFavorite && {
      text: item.favorite ? t('remove_from_favourites') : t('to_favourites'),
      onClick: () => onFavouritesToggle({ id: item.id, type: item.type, checked: !item.favorite }),
    },
    canEdit && {
      link: `/editor/${item.translatedName}`,
      text: t('edit')
    },
    canEdit && {
      text: t('rename'),
      onClick: () => {
        setShowRename(true);
        onToggleModal(true);
      },
    },
    canCopy && {
      text: t('copy'),
      items: folders.reduce((acc: any[], { id: targetId, name }) => {
        if ([FORMA_FOLDER_ID_STATIC, FORMA_FOLDER_ID_PACKS, FORMA_FOLDER_ID_STRICT, FORMA_FOLDER_ID_TRASH].includes(targetId)) return acc;
        return [...acc, {
          text: name,
          onClick: () => {
            onCopy({ id: item.id, targetId, name: item.name, type: item.type });
            onHoverSubmenuItem(null);
          },
          onMouseEnter: () => onHoverSubmenuItem(targetId),
          onMouseLeave: () => onHoverSubmenuItem(null),
          disabled: targetId === parent?.id
        }];
      }, [])
    },
    canMove && {
      text: t('move'),
      items: folders.reduce((acc: any[], { id: targetId, name }) => {
        if ([FORMA_FOLDER_ID_STATIC, FORMA_FOLDER_ID_PACKS, FORMA_FOLDER_ID_STRICT, FORMA_FOLDER_ID_TRASH].includes(targetId)) return acc;
        return [...acc, {
          text: name,
          onClick: () => {
            onMove({ id: item.id, targetId, name: item.name, type: item.type });
            onHoverSubmenuItem(null);
          },
          onMouseEnter: () => onHoverSubmenuItem(targetId),
          onMouseLeave: () => onHoverSubmenuItem(null),
          disabled: targetId === parent?.id
        }];
      }, [])
    },
    canCopyToParent && {
      text: t('copy_to_level_up'),
      onClick: () => {
        onCopy({ id: item.id, targetId: parent.parentId ?? null, name: item.name, type: item.type });
        onHoverSubmenuItem(null);
      },
      onMouseEnter: () => onHoverSubmenuItem('back'),
      onMouseLeave: () => onHoverSubmenuItem(null),
    },
    canMoveToParent && {
      text: t('move_to_level_up'),
      onClick: () => {
        onMove({ id: item.id, targetId: parent.parentId ?? null, name: item.name, type: item.type });
        onHoverSubmenuItem(null);
      },
      onMouseEnter: () => onHoverSubmenuItem('back'),
      onMouseLeave: () => onHoverSubmenuItem(null),
    },
    canDuplicate && {
      text: t('duplicate'),
      onClick: () => {
        onDuplicate({ id: item.id, targetId: parent?.id ?? null, name: item.name, type: item.type });
        onHoverSubmenuItem(null);
      }
    },
    canDelete && {
      onClick: () => {
        setShowDelete(true);
        onToggleModal(true);
      },
      text: t('remove_template')
    },
    onCopyLink && {
      onClick: () => onCopyLink({ translatedName: item.translatedName, parentSlug: parent?.translatedName, type: item.type }),
      text: t('copy_link_to_file')
    }
  ].filter(Boolean);

  if (!options.length) return null;

  return (
    <>
      <ContextMenu
        open={open}
        items={options}
        position={position}
        control={open => (
          <button className={classNames(styles.button, open && styles.active)}>
            <ReactSVG src="/icons/more.svg" />
          </button>
        )}
        offsetY={12}
        offsetX={0}
        onOpen={() => onToggleMenu(item.id)}
        onClose={() => onToggleMenu(null)}
      />

      <Modal
        open={isShowRename}
        onClose={() => {
          setShowRename(false);
          onToggleModal(false);
        }}
        title={t('rename_template')}
        size="small"
        closeOnDocumentClick={false}
        // on={[ 'hover', 'focus' ]}
        buttons={[
          {
            children: t('save'),
            onClick: () => handleClickRename()
          },
          {
            children: t('cancel'),
            viewStyle: 'tertiary'
          }
        ]}
      >
        <div className={styles.renameModal}>
          <Input
            id="edit_folder_name"
            name="name"
            placeholder={t('enter_template_name')}
            onChange={e => setName(e.target.value)}
            maxLength={100}
            value={newName}
            meta={{
              showClearButton: true
            }}
          />
        </div>
      </Modal>

      <RemoveModal
        open={isShowDelete}
        onClose={() => {
          setShowDelete(false);
          onToggleModal(false);
        }}
        onRemove={handleClickRemove}
        itemName={item.name}
        title={t('deleting_template')}
      />
    </>
  );
};

export default TemplateContextMenu;

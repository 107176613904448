import { IMailDomain } from 'interfaces/common.interface';
import { baseApi } from '../store';
import { setToken } from './authSlice';

export const authApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    registerUser: builder.mutation({
      query: data => ({
        url: '/auth/register',
        method: 'POST',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken(data.token));
        } catch (error) { }
      }
    }),
    easyRegisterUser: builder.mutation<{ token: string }, { login: string, code: string, roistatVisit?: string }>({
      query: data => ({
        url: '/auth/easyregister',
        method: 'POST',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken(data.token));
        } catch (error) { }
      }
    }),
    checkUser: builder.mutation<{ login: string }, { login: string }>({
      query: data => ({
        url: '/auth/check',
        method: 'POST',
        body: data
      }),
    }),
    verifyUser: builder.mutation<
      { login: string },
      { login: string, roistatVisit?: string }
    >({
      query: data => ({
        url: '/auth/verifyeasyregister',
        method: 'POST',
        body: data
      }),
    }),
    loginUser: builder.mutation<{ token: string }, { login: string, password: string }>({
      query: data => ({
        url: '/auth/login',
        method: 'POST',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken(data.token));
        } catch (error) { }
      }
    }),
    recoverUser: builder.mutation<{ id: string, key: string }, { login: string }>({
      query: data => ({
        url: '/auth/recover',
        method: 'POST',
        body: data
      }),
    }),
    recoverUserPassword: builder.mutation<{ token: string }, { id: string, key: string, password: string }>({
      query: data => ({
        url: '/auth/recoverpassword',
        method: 'POST',
        body: data
      }),
    }),
    confirmRegister: builder.mutation<{ token: string }, { id: string, key: string }>({
      query: data => ({
        url: '/auth/confirm',
        method: 'POST',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken(data.token));
        } catch (error) { }
      }
    }),
    confirmInvite: builder.mutation({
      query: data => ({
        url: '/auth/invitepassword',
        method: 'POST',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken(data.token));
        } catch (error) { }
      }
    }),

    getMailDomain: builder.query<IMailDomain[], { email?: string }>({
      query: data => ({
        url: '/maildomain/',
        method: 'GET',
        params: data
      }),
    }),
  })
});

export const {
  useLoginUserMutation,
  useCheckUserMutation,
  useVerifyUserMutation,
  useRegisterUserMutation,
  useEasyRegisterUserMutation,
  useRecoverUserMutation,
  useRecoverUserPasswordMutation,
  useConfirmRegisterMutation,
  useConfirmInviteMutation,

  useGetMailDomainQuery
} = authApi;

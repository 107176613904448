import { FC, useState, useEffect, useRef, lazy, Suspense } from 'react';
import { unstable_usePrompt as usePromt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import update from 'immutability-helper';
import classNames from 'classnames';
import { LoadingButton, Modal, nanoid, editorCache, mapPastedDataToSides } from '@forma/forma-ui-kit';
import Side, { SidePlaceholder } from './Side';
import VariableAddModal from './VariableAddModal';
import SignatureAdd from './SignatureAdd';
import VariationsSelectModal from './VariationsSelectModal';
import VariationsEditModal from './VariationsEditModal';
import PasteElementsModal from './PasteElementsModal';
import { getLegacyCountsFromTree, legacyCountTattrsById } from './utils';
import {
  appendSidesColors,
  getRemovedVariables,
  sidesUpdateFunctions,
  sideVariableHelpers,
  ProductsTableSelectModal
} from '@forma/forma-ui-kit';
import { TPasteData } from '@forma/forma-ckeditor';

import { useAppDispatch } from 'store/hooks';
import { setOnboardingModal } from 'store/common/commonSlice';
import {
  useGetProductsPropertiesQuery,
  useAddProductsPropertyMutation,
  useUpdateProductsPropertyMutation,
  useRemoveProductsPropertyMutation
} from 'store/products/productsApi';
import { ITemplateContentData } from 'store/templates/templatesApi';
import { IUserVariableItem, IVariableItem, IVariablesHierarhyItem } from 'interfaces/variables.interface';
import {
  ColontitulData,
  ITemplateBlocksTree,
  ITemplateBorders,
  ITemplateContent,
  ITemplateSide,
  ITemplateSideGroup,
  ITemplateTable,
  ITemplateVariation
} from 'interfaces/templates.interface';
import { IProductsItem } from 'interfaces/products.interface';

import styles from './files-editor.module.css';

const LazyColontitul = lazy(() => import('./Colontitul'));

interface FilesEditorProps {
  id: string,
  template?: ITemplateContent,
  products?: IProductsItem[],
  variablesGroups?: { [key: string]: IVariablesHierarhyItem },
  availableVariables?: { [key: string]: IVariableItem },
  userVariables?: { [key: string]: IUserVariableItem },
  onSave: (data: ITemplateContentData) => Promise<boolean>,
  isSaveLoading: boolean
  onboarding?: { [key: string]: boolean } | null,
}

interface ShowingModalParams {
  type: 'variable' | 'signature' | 'variations' | 'variation-edit' | 'products' | 'unused' | 'colontitules' | 'pasteElements',
  sideId?: string,
  id?: string,
  data?: any
}

const defaultMargins = {
  top: '2.54cm',
  bottom: '2.54cm',
  left: '2.54cm',
  right: '2.54cm'
};

const SAVING_INTERVAL = 60;
const CACHE_TIME = 60 * 60 * 24;

const Editor = lazy(() => import('./Editor'));

const FilesEditor: FC<FilesEditorProps> = ({
  id, template, variablesGroups, availableVariables, userVariables, onSave, isSaveLoading, products, onboarding
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const defaultSide: ITemplateSide = { id: nanoid(), name: `${t('side')} 1`, tattrFolders: [], tables: [], carticles: [] };
  const [showingModal, setShowingModal] = useState<ShowingModalParams | null>(null);
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const [sides, setSides] = useState<ITemplateSide[]>(template?.sides ?? [defaultSide]);
  const [colontitulesData, setColontitulesData] = useState<ColontitulData[]>(template?.runningTitles ?? []);
  const [pageMargins, setPageMargins] = useState<ITemplateBorders | null>(null);
  const [pageLandscape, setPageLandscape] = useState<boolean>();
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [fromCache, setFromCache] = useState<{ content: string, sides: ITemplateSide[] } | null>(null);

  const { data: properties } = useGetProductsPropertiesQuery(undefined, { skip: showingModal?.type !== 'products' });
  const [addProperty] = useAddProductsPropertyMutation();
  const [updateProperty] = useUpdateProductsPropertyMutation();
  const [removeProperty] = useRemoveProductsPropertyMutation();

  const handleUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    const confirmationMessage = '';
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  usePromt({ when: isChanged, message: t('you_have_unsaved_changes') });

  useEffect(() => {
    editorCache.check(CACHE_TIME);

    return () => {
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isChanged) window.onbeforeunload = handleUnload;
    else window.onbeforeunload = null;
  }, [isChanged]);

  useEffect(() => {
    if (sides) appendSidesColors(sides);
  }, [sides]);

  useEffect(() => {
    if (!template) return;
    if (template.sides?.length) setSides(template.sides);
    setColontitulesData(template.runningTitles);
    setPageMargins(template.border ?? defaultMargins);
    setPageLandscape(template.landscape ?? false);

    const cachedData = editorCache.get(id);
    if (cachedData && template.content !== cachedData.content) setFromCache(cachedData);
  }, [id, template]);

  useEffect(() => {
    if (!template) return;

    intervalRef.current = setInterval(() => {
      const content = window.editor?.getData();
      if (content && content !== template.content) editorCache.set(id, { content: window.editor.getData(), sides });
    }, SAVING_INTERVAL * 1000);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [id, template, sides]);

  useEffect(() => {
    if (showingModal?.type === 'variations' && !onboarding?.create_variations_modal) {
      setTimeout(() => dispatch(setOnboardingModal('create_variations_modal')));
    } else if (showingModal?.type === 'products' && !onboarding?.create_table_modal) {
      setTimeout(() => dispatch(setOnboardingModal('create_table_modal')));
    }
    // eslint-disable-next-line
  }, [onboarding, showingModal]);

  const handleClickAddVariable = () => setShowingModal({ type: 'variable' });
  const handleClickAddSignature = () => setShowingModal({ type: 'signature' });
  const handleClickAddColontitules = () => setShowingModal({ type: 'colontitules' });
  const handleClickAddVariations = (sideId?: string) => setShowingModal({ type: 'variations', sideId });
  const handleClickEditVariations = (sideId?: string, id?: string) => setShowingModal({ type: 'variation-edit', sideId, id });
  const handleClickAddTable = (sideId?: string) => setShowingModal({ type: 'products', sideId });
  const handleClickEditTable = (sideId?: string, id?: string) => setShowingModal({ type: 'products', sideId, id });

  const closeModal = () => setShowingModal(null);

  const saveDocument = async (sides: ITemplateContent['sides'], colontitulesData: ITemplateContent['runningTitles']) => {
    if (!availableVariables || !pageMargins) return;

    let data = window.editor.getData();
    const blocksTree: ITemplateBlocksTree = window.editor.getCustomBlocksTree();

    // clean empty variants
    data = data.replaceAll(/(<div class="variations-description[^>]*>)(<p>&nbsp;<\/p>)(<\/div>)/g, '$1$3');

    const legacyCounts = getLegacyCountsFromTree(blocksTree);

    const isSuccess = await onSave({
      id: id,
      content: data,
      // legacy mapping for carticles
      sides: sides.map(({ carticles, tattrFolders, ...side }) => ({
        ...side,
        tattrFolders: tattrFolders.map(({ id, name, tattrs }) => ({
          id,
          name,
          tattrs: tattrs.map(({ id }) => ({
            id,
            from: availableVariables[id] ? 'strictattr' : 'cattr', // not legacy param
            countBody: legacyCountTattrsById(blocksTree, `${id}.${side.id}`),
            countVariative: legacyCountTattrsById(blocksTree, `${id}.${side.id}`, true)
          }))
        })),
        carticles: carticles.map(carticle => {
          const counts = legacyCounts.find(({ id }) => id === carticle.id);

          return {
            ...carticle,
            variants: carticle.variants.map(variant => {
              const countsVariant = counts?.variants.find(({ id }) => id === variant.id);
              return {
                ...variant,
                tattrs: countsVariant ? countsVariant.tattrs : [],
                tables: countsVariant ? countsVariant.tables : [],
              };
            }),
            parents: counts?.parents ?? [],
            countBody: counts?.countBody ?? 0,
            countVariative: counts?.countVariative ?? 0
          };
        }),
      })),
      border: pageMargins,
      runningTitles: colontitulesData,
      landscape: pageLandscape ?? false,
      blocksTree,
    });
    if (isSuccess) {
      if (id) editorCache.delete(id);
      setIsChanged(false);
    }
  };

  const handleClickSave = (sides: ITemplateSide[]) => {
    if (!sides) return;

    const content = window.editor.getData();
    const problems: { unused?: string, removed?: string } = {};
    const removedVariables = getRemovedVariables(sides);

    if (removedVariables.length) {
      problems.removed = t('you_have_removed_variables');

      for (const variable of removedVariables) {
        variable.classList.add('error');
        variable.children[0].classList.add('error');
      }
    }

    const { sides: next, hasUnused } = sidesUpdateFunctions.setUnusedParams(sides, content);

    setSides(next);

    if (hasUnused) problems.unused = t('you_have_unused_variables');
    if (problems.unused || problems.removed) setShowingModal({ type: 'unused', data: Object.values(problems) });
    else saveDocument(next, colontitulesData);
  };

  const handleClickSaveAndDelete = () => {
    const removedVariables = getRemovedVariables(sides);
    for (const variable of removedVariables) {
      window.editor?.execute('removeVariables', { id: variable.id, sideId: variable.dataset.sideId });
    }

    let _sides = sides;
    setSides(prev => {
      _sides = sidesUpdateFunctions.clean(prev);
      return _sides;
    });

    if (_sides) saveDocument(_sides, colontitulesData);
    closeModal();
  };

  /* variables */

  const addVariableToTemplate = (sideId: string, sideName: string, tattrId: string, tattrName: string, tattrType: string) => {
    window.editor?.execute('addVariable', {
      id: tattrId,
      text: `${sideName}.${tattrName}`,
      type: tattrType,
      sideId: sideId,
      sideName: sideName
    });
  };

  const changeVariableInTemplate = (tattrId: string, tattrName: string, tattrType: string) => {
    window.editor?.execute('changeVariable', {
      id: tattrId,
      name: tattrName,
      type: tattrType
    });
  };

  /* variables */

  const handleAddVariableToSide = (
    side: { id: string, name: string },
    varData: IVariableItem | IUserVariableItem,
    checked: boolean,
    group?: { id: string, name: string }
  ) => {
    closeModal();
    const hasVariable = sides?.find(({ id, tattrFolders }) =>
      (id === side.id) && tattrFolders.find(({ tattrs }) => !!(tattrs?.find(({ id }) => id === varData.id)))
    );

    if (checked && !hasVariable) {
      setSides(prev => {
        const sideIndex = prev.findIndex(({ id }) => side.id === id);
        return update(prev, {
          [sideIndex]: {
            tattrFolders: {
              $set: sideVariableHelpers.addVariableToFolder(prev[sideIndex].tattrFolders, varData, { id: group?.id, name: group?.name })
            }
          }
        });
      });
    }

    window.editor?.execute('addVariable', {
      id: varData.id, text: `${side.name}.${varData.name}`, type: varData.type, sideId: side.id, sideName: side.name
    });
  };

  const handleChangeVariableInSide = (
    side: { id: string, name: string },
    varData: IVariableItem | IUserVariableItem,
    checked: boolean,
    group?: { id: string, name: string }
  ) => {
    closeModal();
    const hasVariable = sides?.find(({ id, tattrFolders }) =>
      (id === side.id) && tattrFolders.find(({ tattrs }) => !!(tattrs?.find(({ id }) => id === varData.id)))
    );

    if (checked && !hasVariable) {
      setSides(prev => {
        const sideIndex = prev.findIndex(({ id }) => side.id === id);
        return update(prev, {
          [sideIndex]: {
            tattrFolders: {
              $set: sideVariableHelpers.updateVariableInFolders(prev[sideIndex].tattrFolders, varData)
            }
          }
        });
      });
    }

    window.editor?.execute('changeVariable', {
      id: varData.id, name: varData.name, type: varData.type
    });
  };

  const handleRemoveVariableInSide = (
    side: { id: string, name: string },
    id: string,
  ) => {
    closeModal();
    const hasVariable = sides?.find(({ id, tattrFolders }) =>
      (id === side.id) && tattrFolders.find(({ tattrs }) => !!(tattrs?.find(tattr => id === tattr.id)))
    );

    if (hasVariable) {
      setSides(prev => {
        const sideIndex = prev.findIndex(({ id }) => side.id === id);
        return update(prev, {
          [sideIndex]: {
            tattrFolders: {
              $set: sideVariableHelpers.removeVariableFromFolders(prev[sideIndex].tattrFolders, id)
            }
          }
        });
      });
    }
  };

  /* signature */

  const handleAddSignature = (url: string) => {
    window.editor?.execute('signatureInsert', url);
  };

  /* variations */

  const handleCreateVariation = (sideId: string, data: ITemplateVariation) => {
    const sideIndex = sides.findIndex(({ id }) => sideId === id);
    if (sideIndex === -1) return;

    setSides(prev => {
      return update(prev, {
        [sideIndex]: {
          carticles: { $push: [data] }
        }
      });
    });

    window.editor?.execute('variationsBlock', { sideId, sideName: sides[sideIndex].name, ...data });
  };

  const handleSelectVariation = (sideId: string, data: ITemplateVariation) => {
    const sideIndex = sides.findIndex(({ id }) => sideId === id);
    if (sideIndex === -1) return;

    window.editor?.execute('variationsBlock', { sideId, sideName: sides[sideIndex].name, ...data });
  };

  const handleChangeVariations = (sideId: string, items: ITemplateVariation[]) => {
    const sideIndex = sides.findIndex(({ id }) => sideId === id);
    if (sideIndex === -1) return;

    setSides(prev => {
      return update(prev, {
        [sideIndex]: {
          carticles: {
            $set: items
          }
        }
      });
    });
  };

  const handleSaveVariation = (sideId: string, data: ITemplateVariation) => {
    const sideIndex = sides.findIndex(({ id }) => sideId === id);
    if (sideIndex === -1) return;

    setSides(prev => {
      const index = prev[sideIndex].carticles.findIndex(item => item.id === data.id);
      return update(prev, {
        [sideIndex]: {
          carticles: {
            [index]: {
              $merge: data
            }
          }
        }
      });
    });

    window.editor?.execute('changeVariations', { sideId, sideName: sides[sideIndex].name, ...data });
  };

  // const handleRemoveVariation = (sideId: string, id: string) => {
  //   setSides(prev => {
  //     const sideIndex = prev.findIndex(({ id }) => sideId === id);
  //     const index = prev[sideIndex]?.carticles.findIndex(item => item.id === id);
  //     if (!index || index === -1) return prev;
  //     return update(prev, {
  //       [sideIndex]: {
  //         carticles: { $splice: [[index, 1]] }
  //       }
  //     });
  //   });
  // };

  /* tables */

  const handleAddOrChangeTable = (sideId: string, data: ITemplateTable) => {
    closeModal();
    const sideIndex = sides.findIndex(({ id }) => sideId === id);
    if (sideIndex === -1) return;

    const tableIndex = sides[sideIndex].tables.findIndex(({ id }) => data.id === id);
    if (tableIndex === -1) {
      setSides(prev => {
        return update(prev, {
          [sideIndex]: {
            tables: { $push: [data] }
          }
        });
      });
      window.editor?.execute('addProductsTable', { ...data, sideId, sideName: sides[sideIndex].name });
    } else {
      setSides(prev => {
        return update(prev, {
          [sideIndex]: {
            tables: { $splice: [[tableIndex, 1, data]] }
          }
        });
      });
      window.editor?.execute('changeProductsTable', data);
    }
  };

  const handleChangeTable = (sideId: string, data: ITemplateTable) => {
    // closeModal();
    setSides(prev => {
      const sideIndex = prev.findIndex(({ id }) => sideId === id);
      const tableIndex = prev[sideIndex]?.tables.findIndex(({ id }) => data.id === id);
      if (tableIndex === -1) return prev;
      return update(prev, {
        [sideIndex]: {
          tables: { $splice: [[tableIndex, 1, data]] }
        }
      });
    });
    window.editor?.execute('changeProductsTable', data);
  };

  const handlePasteElements = (data: TPasteData) => {
    setSides(prev => {
      // parse in setState for getting actually state value
      const newSides = mapPastedDataToSides(data, prev, availableVariables, userVariables, variablesGroups);
      if (!newSides?.length) return prev;

      setShowingModal({
        type: 'pasteElements',
        data: newSides
      });

      return prev;
    });
  };

  const handleMergeSides = (sides: ITemplateSide[], sidesMapIds: { oldId: string, newId: string }[]) => {
    setSides(prev => {
      let next = [...prev];
      next = sides.map(({ id, name, tables, carticles, tattrFolders }) => {
        let sideIndex = next.findIndex(side => side.id === id);
        if (sideIndex === -1) {
          next.push({ id, name, tattrFolders: [], tables: [], carticles: [] });
          sideIndex = next.length - 1;
        }

        const nextFolders = update(next[sideIndex].tattrFolders, {
          $apply: (prevFolders: ITemplateSideGroup[]) => {
            let nextFolders = [...prevFolders];
            tattrFolders.forEach(folder => {
              folder.tattrs.forEach(tattr => {
                const nextFoldersHasTattr = !!nextFolders.find(({ tattrs }) => !!tattrs.find(({ id }) => tattr.id === id));
                if (nextFoldersHasTattr) return;

                const folderIndex = nextFolders.findIndex(({ id }) => folder.id === id);
                if (folderIndex === -1) nextFolders.push({ ...folder, tattrs: [tattr] });
                else {
                  nextFolders = update(nextFolders, {
                    [folderIndex]: {
                      tattrs: {
                        $push: [tattr]
                      }
                    }
                  });
                }
              });
            });
            return nextFolders;
          }
        });

        const tablesMapIds: { oldId: string, newId: string }[] = [];
        const nextTables = tables.map((table) => {
          const id = nanoid(12);
          window.editor?.execute('changeProductsTable', { id: table.id, newId: id });
          tablesMapIds.push({ oldId: table.id, newId: id });
          return { ...table, id };
        });

        const nextCarticles = carticles
          .filter(carticle => !next[sideIndex].carticles.find(({ id }) => id === carticle.id))
          .map(carticle => ({
            ...carticle,
            variants: carticle.variants.map(variant => ({
              ...variant,
              tables: variant.tables.map(id => (tablesMapIds.find(({ oldId }) => oldId === id)?.newId ?? id)),
              tattrs: variant.tattrs.map(id => {
                const sideId = id?.split('.')?.[1];
                const newId = sidesMapIds.find(({ oldId }) => oldId === sideId)?.newId;
                if (newId) return id.split('.')[0] + '.' + newId;
                return id;
              })
            }))
          }));

        return update(next[sideIndex], {
          name: { $set: name },
          tables: { $push: nextTables },
          carticles: { $push: nextCarticles },
          tattrFolders: { $set: nextFolders }
        });
      });

      sidesMapIds.forEach(({ oldId, newId }) => {
        const side = next.find(({ id }) => id === newId);
        if (!side) return;
        window.editor?.execute('changeVariablesSide', { sideId: oldId, sideName: side.name, newId });
        // window.editor?.execute('changeVariablesSide', { sideId: newId, sideName: side.name });
        window.editor?.execute('changeVariationsSide', { sideId: oldId, sideName: side.name, newId });
        // window.editor?.execute('changeVariationsSide', { sideId: newId, sideName: side.name });

        // window.editor?.execute('changeProductsTablesSide', { sideId: oldId, sideName: side.name, newId });
        // window.editor?.execute('changeProductsTablesSide', { sideId: newId, sideName: side.name });
        side.tables.forEach((table) => {
          window.editor?.execute('changeProductsTable', { ...table, sideId: oldId, newSideId: newId });
        });
      });

      markLostCustomVariables();

      return next;
    });
  };

  const markLostCustomVariables = () => {
    if (!availableVariables || !userVariables) return;
    const contentVariables: NodeListOf<HTMLSpanElement> = document.querySelectorAll('span.variable');
    for (const variable of contentVariables) {
      if (availableVariables[variable.id] || userVariables[variable.id]) continue;
      variable.classList.add('warning');
      variable.children[0].classList.add('warning');
    }
  };

  const handleCreateColumn = async (data: { name: string, type: string }) => {
    const result = await addProperty(data);
    if ('data' in result && result.data.id) return result.data.id;
    return null;
  };

  const handleEditColumn = (data: { id: string, name: string, type: string }) => {
    updateProperty(data);
  };

  const handleRemoveColumn = (id: string) => {
    removeProperty(id);
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Suspense>
          <Editor
            id={id}
            content={template?.content}
            page={(pageMargins && pageLandscape !== undefined) ? {
              margins: pageMargins,
              onChangeMargins: setPageMargins,
              landscape: pageLandscape,
              onChangeOrientation: setPageLandscape
            } : undefined}
            variables={{
              onClickAddVariable: handleClickAddVariable
            }}
            signature={{
              onClickAddSignature: handleClickAddSignature
            }}
            variations={{
              onClickAdd: handleClickAddVariations,
              onClickEdit: handleClickEditVariations,
            }}
            productsTable={{
              products: products,
              onClickAddTable: handleClickAddTable,
              onClickEditTable: handleClickEditTable,
              onChangeTable: handleChangeTable
            }}
            colontitules={{
              onClicklOpenColontitules: handleClickAddColontitules
            }}
            clipboard={{
              onPasteElements: handlePasteElements
            }}
            onChange={() => setIsChanged(true)}
          />
        </Suspense>
      </div>
      <div className={classNames(styles.side, !pageLandscape && styles.sticky, 'styled-scrollbar', 'editor-side' /* class for onboarding */)}>
        {template ? (
          <>
            <LoadingButton
              id="save_template_button"
              isLoading={isSaveLoading}
              className={styles.saveButton}
              onClick={() => handleClickSave(sides)}
              viewStyle="secondary"
              shadow
              fullWidth
              data-testid="save_template_button"
            >
              {t('save_template')}
            </LoadingButton>
            <div className={styles.panel}>
              <Side
                sides={sides}
                variablesGroups={variablesGroups}
                availableVariables={availableVariables}
                userVariables={userVariables}
                onChange={setSides}
                onAddVariable={addVariableToTemplate}
                onChangeVariable={changeVariableInTemplate}
                onClickAddVariations={handleClickAddVariations}
                onClickAddTable={handleClickAddTable}
                fixed={pageLandscape}
              />
            </div>
          </>
        ) : (
          <SidePlaceholder />
        )}
      </div>
      {template &&
        <VariableAddModal
          open={showingModal?.type === 'variable'}
          onClose={closeModal}
          sides={sides}
          variablesGroups={variablesGroups}
          availableVariables={availableVariables}
          userVariables={userVariables}
          onAddVar={handleAddVariableToSide}
          onChangeVar={handleChangeVariableInSide}
          onRemoveVar={handleRemoveVariableInSide}
        />
      }
      <VariationsSelectModal
        open={showingModal?.type === 'variations'}
        onClose={closeModal}
        onCreate={handleCreateVariation}
        onSelect={handleSelectVariation}
        onChangeItems={handleChangeVariations}
        sides={sides}
        sideId={showingModal?.sideId}
      />
      <VariationsEditModal
        open={showingModal?.type === 'variation-edit'}
        onClose={closeModal}
        onSave={handleSaveVariation}
        data={sides.find(({ id }) => showingModal?.sideId === id)?.carticles.find(({ id }) => id === showingModal?.id)}
        sideId={showingModal?.sideId ?? ''}
      />
      <SignatureAdd
        open={showingModal?.type === 'signature'}
        onClose={closeModal}
        onAdd={handleAddSignature}
      />
      <Suspense>
        <LazyColontitul
          open={showingModal?.type === 'colontitules'}
          onClose={closeModal}
          // onAdd={handleAddSignature}
          sides={sides}
          colontitules={colontitulesData}
          onChange={setColontitulesData}
          id={id}
        />
      </Suspense>
      <ProductsTableSelectModal
        open={showingModal?.type === 'products'}
        onClose={closeModal}
        onSelect={handleAddOrChangeTable}
        data={sides.find(({ id }) => showingModal?.sideId === id)?.tables.find(({ id }) => id === showingModal?.id)}
        sides={sides}
        sideId={showingModal?.sideId}
        properties={properties}
        onCreateColumn={handleCreateColumn}
        onEditColumn={handleEditColumn}
        onRemoveColumn={handleRemoveColumn}
      />
      <PasteElementsModal
        open={showingModal?.type === 'pasteElements'}
        onClose={closeModal}
        pastedSides={showingModal?.data}
        sides={sides}
        onSave={handleMergeSides}
      />
      <Modal
        size="small"
        open={showingModal?.type === 'unused'}
        onClose={closeModal}
        title={t('save_document_question')}
        buttons={[
          {
            children: t('save'),
            onClick: handleClickSaveAndDelete,
            viewStyle: 'primary',
          },
          {
            children: t('fix'),
            viewStyle: 'tertiary',
          }
        ]}
      >
        {showingModal?.type === 'unused' && (
          <div className={styles.modalDescription}>
            {showingModal?.data?.map((text: string, index: number) => <div key={index}>{text}</div>)}
            <div style={{ marginTop: '16px' }}>{t('saving_clear_variables')}</div>
          </div>
        )}
      </Modal>
      <Modal
        size="small"
        open={!!fromCache}
        onClose={() => setFromCache(null)}
        title={t('document_recovery')}
        buttons={[
          {
            children: t('restore'),
            viewStyle: 'primary',
            onClick: () => {
              if (!fromCache) return;
              window.editor?.setData(fromCache.content);
              setSides(fromCache.sides);
            }
          },
          {
            children: t('cancel'),
            viewStyle: 'tertiary',
            onClick: () => editorCache.delete(id)
          }
        ]}
      >
        <div className={styles.modalDescription}>
          {t('document_recovery_description')}
        </div>
      </Modal>
    </div>
  );
};

export default FilesEditor;

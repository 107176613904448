import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Modal } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import FillDocument from 'views/FillDocument';
import TrialEndModal from 'views/Guide/TrialEndModal';
import RequestDemoModal from 'views/Guide/RequestDemoModal';
import { NotFound } from 'pages/Common';
import webView from 'helpers/webview';
import fileToBase64 from 'helpers/fileToBase64';
import downloadFromUrl from 'helpers/downloadFromUrl';
import { hasPermission, hasPermissionsList, PERMISSIONS } from 'helpers/permissions';
import { TRIAL_ID } from 'data/constants';

import {
  ITemplateRenderData,
  useGetTemplateByNameQuery,
  useGetTemplateSidesQuery,
  useRenderTemplateMutation
} from 'store/templates/templatesApi';
import { selectUserData, selectUserPermissions } from 'store/user/userSlice';
import { addNotification } from 'store/notifications/notificationsSlice';
import { useCreateExternalFillMutation } from 'store/externalfill/externalfillApi';
import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';
import { IExternalFillItem } from 'interfaces/externalfill.interface';
import { TFormatDocument } from 'interfaces/templates.interface';

import styles from './Filling.module.css';

const getAttachmentContents = async (files: File[]) => (
  Promise.all(files?.map(async file => {
    const content = await fileToBase64(file);
    return ({
      name: file.name,
      filename: file.name,
      mimetype: file.type,
      content
    });
  }))
);

const Filling = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const navigate = useNavigate();
  const user = useSelector(selectUserData);
  const userPermissions = useSelector(selectUserPermissions);
  const canEditTemplates = hasPermission(userPermissions ?? [], PERMISSIONS.TEMPLATES.EDIT);
  const [isRequestOpen, setRequestOpen] = useState<boolean>(false);
  const [isOpenEmpty, setOpenEmpty] = useState(false);

  const { data: template, error } = useGetTemplateByNameQuery(slug ?? '');
  const { data: templateSides, isSuccess: isSidesSucess } = useGetTemplateSidesQuery(template?.id ?? '', { skip: !template });
  const { data: currentTariff } = useGetCurrentTariffQuery();

  const [createExternalFill, { isLoading: isCreateExternalFillLoading }] = useCreateExternalFillMutation();

  const [showingModal, setShowingModal] = useState<'paymentLock' | 'trialEnd' | 'signCount' | null>(null);

  const formats = useMemo(() => {
    const formats: TFormatDocument[] = [];
    if (hasPermissionsList(userPermissions ?? [], [PERMISSIONS.RENDERS.CREATE, PERMISSIONS.DOWNLOAD.PDF])) formats.push('pdf');
    if (hasPermissionsList(userPermissions ?? [], [PERMISSIONS.RENDERS.CREATE, PERMISSIONS.DOWNLOAD.DOCX])) formats.push('doc');
    return formats;
  }, [userPermissions]);

  const pageError = error as FetchBaseQueryError;

  useEffect(() => {
    if (pageError?.status === 402) navigate('/subscribe');
    // eslint-disable-next-line
  }, [pageError]);

  useEffect(() => {
    if (isSidesSucess && !templateSides?.sides.length) setOpenEmpty(true);
    // eslint-disable-next-line
  }, [isSidesSucess]);

  // if (template && sidesSucess && !templateSides.sides.length) navigate(`/editor/${slug}`, { replace: true });

  const [renderTemplate, { isLoading: isDownloadLoading }] = useRenderTemplateMutation();

  const handleCheckTrial = (mode: 'sign' | 'external') => {
    if (!currentTariff) return false;
    const { tariff, isActive, isPaid } = currentTariff;
    const isTrial = tariff.id === TRIAL_ID;

    if (isTrial && !isActive) {
      setShowingModal('trialEnd');
      return false;
    }
    if (isTrial || !isPaid || !isActive) {
      setShowingModal('paymentLock');
      return false;
    }
    if (mode === 'sign' && user && (!user.workspace.signature_available || user.workspace.signature_available <= user.workspace.signature_used)) {
      setShowingModal('signCount');
      return false;
    }

    return true;
  };

  const handleClickDownload = async (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    format: TFormatDocument = 'pdf'
  ) => {
    if (!template) return false;
    if (currentTariff?.tariff.id === TRIAL_ID && !currentTariff.isActive) {
      setShowingModal('trialEnd');
      return;
    }

    if (window?.navigator?.userAgent === 'forma-mobile') {
      webView.renderTemplate(template.id, `${name}.${format}`, { name, format, sideValues });
    } else {
      renderTemplate({ id: template.id, name, sideValues, format })
        .unwrap()
        .then(url => downloadFromUrl(url, `${name}.${format}`));
    }
  };

  const handleExternalFill = async (
    name: string,
    sideValues: IExternalFillItem['sideValues'],
    attachmentNames: string[],
    ownerId: string,
    signer?: {
      name: string,
      phone: string
    },
    files?: File[]
  ) => {
    if (!template) return false;
    if (currentTariff?.tariff.id === TRIAL_ID && !currentTariff.isActive) {
      setShowingModal('trialEnd');
      return false;
    }

    const attachmentContents = files ? await getAttachmentContents(files) : [];

    const res = await createExternalFill({
      name,
      templateId: template.id,
      entityId: ownerId,
      sideValues,
      attachmentNames,
      attachmentContents,
      signature: signer && { signer }
    });

    if (!('data' in res)) return false;

    dispatch(addNotification({ content: t('external_fill_created'), type: 'SUCCESS' }));
    localStorage.setItem('externalFillSendingId', res.data.id);
    navigate('/documents');

    return true;
  };

  if (pageError?.status === 404) return (
    <NotFound />
  );

  return (
    <AdminLayout
      title={t('fill_document_by_template')}
      breadcrumbs={{ items: [{ name: t('my_templates'), to: '/templates', as: Link }, { name: t('fill_by_template') }] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('filling_document')}</PageTitle>
      <FillDocument
        name={template?.name}
        sides={templateSides?.sides}
        blocksTree={templateSides?.blocksTree}
        onClickDownload={handleClickDownload}
        isDownloadLoading={isDownloadLoading}
        onExternalFill={handleExternalFill}
        isExternalFillLoading={isCreateExternalFillLoading}
        checkAllowSelect={handleCheckTrial}
        formats={formats}
        showNameInput
        fillMessage={t('fill_its_or_select_for_external_fill')}
      />
      <TrialEndModal
        open={showingModal === 'signCount'}
        onClose={() => setShowingModal(null)}
        title={t('extend_sign_limit')}
        description={t('extend_sign_limit_description')}
      // image={<img src="/images/trial-end.svg" alt="" />}
      />
      <TrialEndModal
        open={showingModal === 'trialEnd'}
        onClose={() => setShowingModal(null)}
        title={t('trial_period_end')}
        description={t('trial_end_render_description')}
        image={<img src="/images/trial-end.svg" alt="" />}
      />
      <TrialEndModal
        open={showingModal === 'paymentLock'}
        onClose={() => setShowingModal(null)}
        title={t('sign_only_for_paid')}
        description={
          <div>
            <input type="hidden" autoFocus />
            <Trans i18nKey="sign_only_for_paid_description" />
            <br /><br />
            <div className={styles.flex}>
              <div>
                <Trans
                  i18nKey="sign_only_for_paid_links"
                  components={{
                    ul: <ul />,
                    li: <li />,
                    demo: <span className={styles.link} onClick={() => setRequestOpen(true)} />,
                    // eslint-disable-next-line
                    telegram: <a className={styles.link} href="https://t.me/FormaHelperBot" target="_blank" rel="noreferrer" />,
                    // eslint-disable-next-line
                    whatsapp: <a className={styles.link} href="https://wa.me/79961304591" target="_blank" rel="noreferrer" />,
                    // eslint-disable-next-line
                    email: <a className={styles.link} href="mailto:hello@forma.today" />
                  }}
                />
              </div>
              <img src="/images/trial-lock.svg" alt="" />
            </div>
          </div>
        }
      // image={<img src="/images/trial-lock.svg" alt="" />}
      />
      <Modal
        size="small"
        open={isOpenEmpty}
        onClose={() => setOpenEmpty(false)}
        buttons={
          canEditTemplates ? [
            {
              viewStyle: 'primary',
              children: t('open_document_in_editor'),
              onClick: () => navigate(`/editor/${slug}`)
            }
          ] : [
            {
              viewStyle: 'primary',
              children: t('return_to_templates'),
              onClick: () => navigate('/templates')
            }
          ]
        }
      >
        <ReactSVG src="/images/empty-doc-animation.svg" />
        <p className={styles.emptyDescription}>
          <Trans i18nKey="empty_document_sides_description" />
        </p>
      </Modal>

      <RequestDemoModal
        open={isRequestOpen}
        onClose={() => setRequestOpen(false)}
      />
    </AdminLayout>
  );
};

export default Filling;

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFromTo } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import { addNotification } from 'store/notifications/notificationsSlice';
import UsersList from 'views/profile/UsersList';
import UsersMenu from 'views/profile/UsersMenu';

import {
  useGetUsersListQuery,
  useRemoveUserMutation,
  useSetUserGroupsMutation
} from 'store/user/userApi';
import {
  useGetGroupsListQuery,
} from 'store/groups/groupsApi';
import { selectUserData } from 'store/user/userSlice';

const UsersListPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState({});
  const page = searchParams.get('page');

  const user = useSelector(selectUserData);

  const { data: users, isFetching: isUsersLoading } = useGetUsersListQuery({ ...params, ...getFromTo(page) });
  const [saveUserGroups] = useSetUserGroupsMutation();
  const [removeUser, { isLoading: isRemoveLoading }] = useRemoveUserMutation();
  const { data: groups } = useGetGroupsListQuery();

  const handleChangePage = (page: number) => {
    setSearchParams(prev => ({ ...Object.fromEntries(prev.entries()), page: String(page) }));
  };

  const handleChangeSearch = (value: string) => {
    setParams(prev => ({ ...prev, filter: value }));
    handleChangePage(1);
  };

  const handleChangeFilter = (params: { [key: string]: string }) => {
    setParams(prev => ({ ...prev, ...params }));
    handleChangePage(1);
  };

  const handleChangeUserGroups = async (id: string, groups: string[]) => {
    const res = await saveUserGroups({ id: id, groupids: groups });
    if (res && 'data' in res) {
      dispatch(addNotification({ content: t('data_successfully_changed'), type: 'SUCCESS' }));
    }
  };

  if (users && users.count === 1 && Object.keys(params).length === 0) {
    return <Navigate to="/invite" />;
  }

  return (
    <AdminLayout
      title={t('users')}
      breadcrumbs={{ items: [{ name: t('settings'), to: '/profile', as: Link }, { name: t('my_users') }] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('my_users')}</PageTitle>

      <UsersMenu />
      <UsersList
        users={user && users?.items.filter(({ id }) => (user.id !== id))}
        onChangeUserGroups={handleChangeUserGroups}
        groups={groups}
        onChangeSearch={handleChangeSearch}
        onChangeFilter={handleChangeFilter}
        onRemove={removeUser}
        pagination={{
          isLoading: isUsersLoading || isRemoveLoading,
          onChange: handleChangePage,
          count: users?.count ? users.count : 0,
          page: page
        }}
      />
    </AdminLayout>
  );
};

export default UsersListPage;

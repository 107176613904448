import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, ButtonsContainer, FileDropzone, LoadingButton, Modal } from '@forma/forma-ui-kit';
import RequestProgress from './RequestProgress';
import RequestReady from './RequestReady';
// import DiscountLabel from './DiscountLabel';

import { TcRequestItem } from 'interfaces/requests.inerface';

import styles from './guide.module.css';

interface GuideProps {
  requests?: TcRequestItem[],
  onSkip: () => void,
  isSkipLoading?: boolean,
  onSendFiles: (files: File[]) => void,
  isSendLoading?: boolean,
  readyDate?: Date | null,
  onEnd: () => void
}

const Guide: FC<GuideProps> = ({
  requests, onSkip, isSkipLoading, onSendFiles, isSendLoading, readyDate, onEnd
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);

  const isTemplatesSended = !!(requests?.length && readyDate);
  const isTemplatesReady = isTemplatesSended && requests.every(({ templateAt }) => !!templateAt);

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <h1 className={styles.title}>{t('guide.welcome_to_forma')}</h1>
        <div className={styles.description}>
          {t('guide.check_list_description')}
          <img className={styles.descriptionIcon} src="/icons/social/whatsapp-color.svg" alt="WhatsApp" />
        </div>
        <ul className={styles.list}>
          <li className={styles.listItem}>{t('guide.check_list_1')}</li>
          <li className={styles.listItem}>{t('guide.check_list_2')}</li>
          <li className={styles.listItem}>{t('guide.check_list_3')}</li>
        </ul>
        {/* <div className={styles.discount}>
          <DiscountLabel />
        </div> */}
      </div>
      <div className={styles.right}>
        {!requests ? (
          <div className={classNames(styles.dropzoneSkelet, 'skeleton-loader')} />
        ) : (
          isTemplatesSended ? (
            isTemplatesReady ? (
              <RequestReady onClick={onEnd} />
            ) : (
              <RequestProgress readyDate={readyDate} />
            )
          ) : (
            <FileDropzone
              title={
                <Trans
                  i18nKey="guide.dropzone_title"
                  components={{
                    icon: <img className={styles.dropzoneIcon} src="/icons/stars-green.svg" width={24} height={24} alt="" />
                  }}
                />
              }
              onChange={setFiles}
              className={styles.dropzone}
              contentClassName={styles.dropzoneContent}
              maxFiles={3}
              accept={{
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                'application/msword': ['.doc'],
                'application/rtf': ['.rtf'],
                'application/vnd.apple.pages': ['.pages'],
                'application/pdf': ['.pdf']
              }}
            />
          )
        )}

        {(requests && !isTemplatesSended) && (
          <ButtonsContainer className={styles.buttonsBlock}>
            <Modal
              title={t('setting_all_myself')}
              size="small"
              control={
                <Button
                  viewStyle="text"
                >
                  {t('setting_all_myself')}
                </Button>
              }
              buttons={[
                {
                  className: styles.skipModalButton,
                  children: t('guide.no_setting_for_me'),
                },
                {
                  className: styles.skipModalButton,
                  viewStyle: 'tertiary',
                  children: t('guide.yes_setting_all_myself'),
                  isLoading: isSkipLoading,
                  onClick: onSkip
                }
              ]}
            >
              <div className={styles.skipModal}>
                {t('guide.setting_all_myself_description')}
              </div>
            </Modal>
            <LoadingButton
              size="small"
              onClick={() => onSendFiles(files)}
              isLoading={isSendLoading}
              disabled={!files.length}
            >
              {t('move_templates_in_forma')}
            </LoadingButton>
          </ButtonsContainer>
        )}
      </div>
    </div>
  );
};

export default Guide;

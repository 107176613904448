import { FC, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import { Modal } from '@forma/forma-ui-kit';
import FileExplorer from 'views/FileExplorer';
import { hasPermission, PERMISSIONS } from 'helpers/permissions';
import copyToClipboard from 'helpers/copyToClipboard';
import { FORMA_FOLDER_ID_BACK, FORMA_FOLDER_ID_PACKS, FORMA_FOLDER_ID_STATIC, FORMA_FOLDER_ID_STRICT, FORMA_FOLDER_ID_TRASH } from 'data/constants';

import { IFolderListChidlren, IFolderListTreeChidlren, IFoldersList } from 'interfaces/folders.interface';
import { IFoldersActionsProps, IFoldersEditingProps } from 'interfaces/fileExplorer.interface';
import {
  useGetFolderContentQuery,
  useSearchFoldersQuery,
  useMoveFolderMutation,
  useCopyFolderMutation,
  useRemoveFolderMutation,
  useChangeFolderMutation,
  useCreateFolderMutation,
  useFavouritesAddFolderMutation,
  useFavouritesRemoveFolderMutation
} from 'store/folders/foldersApi';
import {
  useMoveTemplateMutation,
  useCopyTemplateMutation,
  useRemoveTemplateMutation,
  useChangeTemplateMutation,
  useCreateTemplateMutation,
  useFavouritesAddTemplateMutation,
  useFavouritesRemoveTemplateMutation,
  useRestoreTemplateMutation
} from 'store/templates/templatesApi';
import { addNotification } from 'store/notifications/notificationsSlice';
import { selectLayout } from 'store/common/commonSlice';
import { useGetTemplateSidesQuery } from 'store/templates/templatesApi';
import { useGetGroupsListQuery } from 'store/groups/groupsApi';
import { selectUserPermissions } from 'store/user/userSlice';
import { useCopySharedTemplateMutation } from 'store/sharedTemplates/sharedTemplatesApi';

import styles from './FolderContent.module.css';

interface FolderContentProps {
  type: 'folders' | 'favourites' | 'trash',
  folder?: IFoldersList,
  foldersItems: IFolderListChidlren[],
  foldersTree: IFolderListTreeChidlren[],
  isLoading: boolean
}

const collectTreePath = (items: IFolderListTreeChidlren[], slug: string): string[] => {
  for (const folder of items) {
    if (folder.translatedName === slug) return [folder.translatedName];
    else if (folder.children) {
      const finded = collectTreePath(folder.children, slug);
      if (finded.length) return [folder.translatedName, ...finded];
    }
  }
  return [];
};

const FolderContent: FC<FolderContentProps> = ({
  type, folder, foldersItems, foldersTree, isLoading
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname: folderUrl } = useLocation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const isFavourites = type === 'favourites';
  const layout = useAppSelector(selectLayout);
  const userPermissions = useAppSelector(selectUserPermissions);
  const canEditUsers = hasPermission(userPermissions ?? [], PERMISSIONS.USERS.EDIT);
  const canEditFolders = hasPermission(userPermissions ?? [], PERMISSIONS.FOLDERS.EDIT);

  const [openedFolder, setOpenedFolder] = useState<string | null>(null);
  const [searchQuery, setSeachQuery] = useState<string>('');
  const [updatedId, setUpdatedId] = useStateIfMounted<string | null>(null);
  const [templateToOpen, setTemplateToOpen] = useState<IFolderListChidlren | null>(null);
  const [sharedTemplateToOpen, setSharedTemplateToOpen] = useState<IFolderListChidlren | null>(null);
  const [isOpenEmpty, setOpenEmpty] = useState<boolean>(false);
  const [templateToRestore, setTemplateToRestore] = useState<{ id: string, name: string, translatedName: string, type: 'template' | 'folder' } | null>(null);

  const { data: templateSides, isFetching: isSidesLoading, isSuccess: sidesSucess } =
    useGetTemplateSidesQuery(templateToOpen?.id ?? '', { skip: !templateToOpen });

  const { data: subfolders, isFetching: isLoadingSubfolder } =
    useGetFolderContentQuery(openedFolder ?? undefined, { skip: !openedFolder });

  const { data: searchResults, isFetching: isLoadingSearch } =
    useSearchFoldersQuery({ name: searchQuery, limit: 10, external: true }, { skip: !searchQuery || searchQuery === '' });

  const { data: groups } = useGetGroupsListQuery(undefined, { skip: !canEditFolders || !canEditUsers });

  const [moveFolder] = useMoveFolderMutation();
  const [copyFolder] = useCopyFolderMutation();
  const [removeFolder] = useRemoveFolderMutation();
  const [changeFolder] = useChangeFolderMutation();
  const [createFolder] = useCreateFolderMutation();
  const [favouritesAddFolder] = useFavouritesAddFolderMutation();
  const [favouritesRemoveFolder] = useFavouritesRemoveFolderMutation();

  const [moveTemplate] = useMoveTemplateMutation();
  const [copyTemplate] = useCopyTemplateMutation();
  const [removeTemplate] = useRemoveTemplateMutation();
  const [changeTemplate] = useChangeTemplateMutation();
  const [createTemplate] = useCreateTemplateMutation();
  const [restoreTemplate] = useRestoreTemplateMutation();
  const [favouritesAddTemplate] = useFavouritesAddTemplateMutation();
  const [favouritesRemoveTemplate] = useFavouritesRemoveTemplateMutation();

  const [copySharedTemplate] = useCopySharedTemplateMutation();

  useEffect(() => {
    if (!isSidesLoading && sidesSucess) {
      if (templateSides && !templateSides?.sides.length) setOpenEmpty(true);
      else if (templateToOpen) navigate(`/fill-document/${templateToOpen.translatedName}`);
    }
    // eslint-disable-next-line
  }, [isSidesLoading, sidesSucess]);

  const setUpdatedTemplate = (id: string) => {
    setUpdatedId(id);
    setTimeout(() => setUpdatedId(null), 5000);
  };
  const openFolder = (item: IFolderListChidlren, parent?: { id: string, name: string, translatedName: string } | null) => {
    if (layout === 'list' && foldersTree) navigate(`${folderUrl}/${collectTreePath(foldersTree, item.translatedName).join('/')}`);
    else navigate(`${folderUrl}/${parent?.translatedName ? `${parent.translatedName}/` : ''}${item.translatedName}`);
    // } else navigate('/templates');
    setSeachQuery('');
  };
  const openTemplate = (template: IFolderListChidlren) => {
    setTemplateToOpen(template);
  };
  const openSharedTemplate = (template: IFolderListChidlren) => {
    setSharedTemplateToOpen(template);
  };
  const openSharedFolder = (folder: IFolderListChidlren) => {
    if (folder.id === FORMA_FOLDER_ID_STATIC) navigate('/static-templates');
    else if (folder.id === FORMA_FOLDER_ID_PACKS) navigate('/templates-packs');
    else if (folder.id === FORMA_FOLDER_ID_STRICT) navigate('/strict-templates');
    else if (folder.id === FORMA_FOLDER_ID_TRASH) navigate('/templates/trash');
    else if (folder.id === FORMA_FOLDER_ID_BACK) {
      const pathArray = folderUrl?.split('/') ?? [];
      navigate(`${pathArray.slice(0, pathArray.length - 1).join('/')}`);
    }
    else if (folder.parentid) navigate(`/static-templates/${folder.parentid}?filterId=${folder.id}`);
    else navigate(`/static-templates/${folder.id}`);
  };
  const showFolderContent = (folder: IFolderListChidlren) => {
    setOpenedFolder(folder.id !== openedFolder ? folder.id : null);
  };


  const handleCreateElement: IFoldersEditingProps['onCreate'] = async ({ name, type, parentid = folder?.id }) => {
    if (!name || !type) return null;
    const parentSlug = (slug && parentid === folder?.id) ? slug : null;
    const result = (type === 'folder') ? await createFolder({ name, parentid, parentSlug }) : await createTemplate({ name, folderid: parentid, parentSlug });
    if (!('data' in result)) return null;
    setUpdatedTemplate(result.data.id);
    return result.data;
  };
  const handleChangeElement: IFoldersEditingProps['onRename'] = ({ type, ...data }) => {
    if (!data) return null;
    const parentSlug = (slug && data.parentid === folder?.id) ? slug : null;
    if (type === 'folder') changeFolder({ ...data, parentSlug });
    else changeTemplate({ ...data, parentSlug });
  };
  const handleChangeAccess: IFoldersEditingProps['onChangeAccess'] = ({ type, ...data }) => {
    if (!data) return null;
    const parentSlug = (slug && data.parentid === folder?.id) ? slug : null;
    if (type === 'folder') changeFolder({ ...data, parentSlug, action: 'access' });
    else changeTemplate({ ...data, parentSlug, action: 'access' });
  };
  const handleCopyElement: IFoldersEditingProps['onCopy'] = ({ id, targetId, type }) => {
    if (id === targetId) return null;
    if (type === 'folder') {
      copyFolder({ id, folderid: targetId }).then(result => {
        if (!('data' in result)) return;
        dispatch(addNotification({ content: t('folder_copied'), type: 'SUCCESS' }));
        setUpdatedId(result.data.folderid ?? result.data.parentid ?? null);
        setTimeout(() => setUpdatedId(null), 5000);
      });
    } else {
      copyTemplate({ id, folderid: targetId }).then(result => {
        if (!('data' in result)) return;
        dispatch(addNotification({ content: t('file_copied'), type: 'SUCCESS' }));
        setUpdatedId(result.data.folderid ?? result.data.parentid ?? null);
        setTimeout(() => setUpdatedId(null), 5000);
      });
    }
  };
  const handleMoveElement: IFoldersEditingProps['onMove'] = ({ id, targetId, name, type }) => {
    if (id === targetId) return null;
    if (type === 'folder') {
      moveFolder({ id, parentid: targetId, name }).then(result => {
        if (!('data' in result)) return;
        dispatch(addNotification({ content: t('folder_moved'), type: 'SUCCESS' }));
        setUpdatedId(result.data.folderid ?? result.data.parentid ?? null);
        setTimeout(() => setUpdatedId(null), 5000);
      });
    } else {
      moveTemplate({ id, folderid: targetId, name }).then(result => {
        if (!('data' in result)) return;
        dispatch(addNotification({ content: t('file_moved'), type: 'SUCCESS' }));
        setUpdatedId(result.data.folderid ?? result.data.parentid ?? null);
        setTimeout(() => setUpdatedId(null), 5000);
      });
    }
  };
  const handleRemoveElement: IFoldersEditingProps['onRemove'] = ({ type, ...data }) => {
    if (!data) return null;
    if (type === 'folder') removeFolder({ ...data, parentSlug: slug });
    else removeTemplate({ ...data, parentSlug: slug });
  };
  const handleCopyShared: IFoldersActionsProps['onCopyShared'] = ({ id, targetId }) => {
    copySharedTemplate({ id, folderid: targetId ?? folder?.id });
  };
  const handleFavouritesToggle: IFoldersActionsProps['onFavouritesToggle'] = ({ id, type, checked }) => {
    if (type === 'folder') { checked ? favouritesAddFolder(id) : favouritesRemoveFolder(id); }
    else { checked ? favouritesAddTemplate(id) : favouritesRemoveTemplate(id); }
  };
  const handleCopyLink: IFoldersActionsProps['onCopyLink'] = ({ translatedName, parentSlug, type }) => {
    const origin = window.location.origin;
    let url = '';
    if (type === 'template') {
      url = `${origin}/fill-document/${translatedName}`;
    } else {
      if (layout === 'list' && foldersTree)
        url = `${origin}${folderUrl}/${collectTreePath(foldersTree, translatedName).join('/')}`;
      else
        url = `${origin}${folderUrl}/${parentSlug ? `${parentSlug}/` : ''}${translatedName}`;
    }

    const res = copyToClipboard(url);
    if (res === 'success') dispatch(addNotification({ content: t('link_copied'), type: 'SUCCESS' }));
  };
  const handleRestoreElement = () => {
    if (!templateToRestore) return;
    restoreTemplate(templateToRestore.id).then(res => {
      if ('data' in res) navigate(`/editor/${templateToRestore.translatedName}`);
    });
  };
  const handleClickItem = (
    item: IFolderListChidlren,
    parent?: { id: string, name: string, translatedName: string } | null,
    inGroup?: boolean
  ) => {
    if (item.removed) {
      setTemplateToRestore({ id: item.id, name: item.name, translatedName: item.translatedName, type: item.type });
      return;
    }

    if (item.external) {
      if (item.type === 'template') openSharedTemplate(item);
      else openSharedFolder(item);
      return;
    }

    if (item.type === 'template') openTemplate(item);
    else {
      if (layout === 'grid') {
        if (!inGroup) showFolderContent(item);
      } else {
        if (inGroup) openFolder(item);
      }
    }
  };
  const handleDoubleClickItem = (item: IFolderListChidlren, parent?: { id: string, name: string, translatedName: string } | null) => {
    if (item.external) {
      if (item.type === 'template') openSharedTemplate(item);
      else openSharedFolder(item);
      return;
    }

    if (item.type === 'template') openTemplate(item);
    else openFolder(item, parent);
  };

  return (
    <AdminLayout
      title={t('my_templates')}
    // breadcrumbs={{ items: breadcrumbs.length ? breadcrumbs : null }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('my_templates')}</PageTitle>
      <FileExplorer
        foldersTree={foldersTree}
        folder={folder}
        userGroups={groups}
        items={foldersItems}
        active={openedFolder}
        updated={updatedId}
        isLoading={isLoading}

        subitems={openedFolder ? subfolders?.items : undefined}
        subitemsIsLoading={isLoadingSubfolder}

        actions={{
          onCopyLink: handleCopyLink,
          onFavouritesToggle: handleFavouritesToggle,
          onCopyShared: handleCopyShared,
          onClickItem: handleClickItem,
          onDoubleClickItem: handleDoubleClickItem,
        }}
        search={{
          results: (searchQuery && searchQuery !== '') ? searchResults : undefined,
          isLoading: isLoadingSearch,
          onChange: setSeachQuery
        }}
        editing={{
          onMove: handleMoveElement,
          onCopy: handleCopyElement,
          onDuplicate: handleCopyElement,
          onRemove: handleRemoveElement,
          onRename: handleChangeElement,
          onCreate: !isFavourites ? handleCreateElement : undefined,
          onChangeAccess: handleChangeAccess,
          onRestore: setTemplateToRestore
        }}
        favourites={{
          isOpen: !!isFavourites,
          href: isFavourites ? '/templates' : '/favourites'
        }}
      />

      <Modal
        size="small"
        open={isOpenEmpty}
        onClose={() => { setOpenEmpty(false); setTemplateToOpen(null); }}
        buttons={[
          {
            viewStyle: 'primary',
            children: t('open_document_in_editor'),
            onClick: () => templateToOpen?.translatedName && navigate(`/editor/${templateToOpen.translatedName}`)
          }
        ]}
      >
        <ReactSVG src="/images/empty-doc-animation.svg" />
        <p className={styles.emptyDescription}>
          <Trans i18nKey="empty_document_sides_description" />
        </p>
      </Modal>
      <Modal
        title={t('select_action')}
        size="small"
        open={!!sharedTemplateToOpen?.external}
        onClose={() => setSharedTemplateToOpen(null)}
        buttonsClassName={styles.copyButtons}
        buttons={[
          {
            viewStyle: 'primary',
            children: t('fill'),
            className: styles.fillButton,
            onClick: async () => {
              if (!sharedTemplateToOpen) return;
              const res = await copySharedTemplate({ id: sharedTemplateToOpen.id, folderid: folder?.id });
              if ('data' in res && res.data) navigate(`/fill-document/${res.data?.translatedName}`);
            }
          },
          {
            viewStyle: 'textLight',
            children: t('edit'),
            className: styles.editButton,
            onClick: async () => {
              if (!sharedTemplateToOpen) return;
              const res = await copySharedTemplate({ id: sharedTemplateToOpen.id, folderid: folder?.id });
              if ('data' in res && res.data) navigate(`/editor/${res.data?.translatedName}`);
            }
          }
        ]}
      >
        <p className={styles.copyDescription}>
          <Trans i18nKey="template_will_be_copied_notice" />
        </p>
      </Modal>
      <Modal
        open={!!templateToRestore}
        onClose={() => setTemplateToRestore(null)}
        size="small"
        title={t('restoring_template')}
        buttons={[
          {
            viewStyle: 'primary',
            children: t('restore'),
            onClick: handleRestoreElement,
            closing: false
          },
          {
            viewStyle: 'tertiary',
            children: t('cancel'),
            onClick: () => setTemplateToRestore(null)
          }
        ]}
      >
        <p className={styles.emptyDescription}>
          <Trans i18nKey="restore_template_question" /> “{templateToRestore?.name}”?
        </p>
      </Modal>
    </AdminLayout>
  );
};

export default FolderContent;

import { FC, useState } from 'react';
import classNames from 'classnames';
import { FolderContextMenu, TemplateContextMenu, TrashContextMenu } from '../../ExplorerContextMenu';
import { getGridItemIcon } from '../utils';

import { IUserGroupsItem } from 'interfaces/users.interface';
import { IFolderListChidlren, IFoldersParent } from 'interfaces/folders.interface';
import {
  IFoldersActionsProps,
  IFoldersEditingProps,
} from 'interfaces/fileExplorer.interface';

import styles from './explorer-grid-item.module.css';

interface ExplorerGridItemProps {
  item: IFolderListChidlren,
  items?: IFolderListChidlren[],
  parent?: IFoldersParent,
  hideOptions?: boolean,
  onToggleMenu: (id: string | null) => void,
  onToggleModal: (open: boolean) => void,
  onHoverSubmenuItem: (id: string | null) => void,
  editing?: IFoldersEditingProps,
  actions: IFoldersActionsProps,
  userGroups?: IUserGroupsItem[],
  isSelected?: boolean,
  isActive?: boolean,
  isUpdated?: boolean,
  isMenuOpen?: boolean,
  isInGroup?: boolean,
}

const ExplorerGridItem: FC<ExplorerGridItemProps> = ({
  item, parent, items, hideOptions, onToggleMenu, onToggleModal, onHoverSubmenuItem,
  editing, actions, userGroups, isSelected, isActive, isUpdated, isMenuOpen, isInGroup
}) => {
  // const timer = useRef(null);
  const [isHover, setIsHover] = useState(false);
  const [isOptionsOpen, setOptionsOpen] = useState(false);

  const isRemoved = !!item.removed;
  const isFolder = item.type === 'folder';

  let timer: NodeJS.Timeout | null = null;

  const handleClick = (e: React.MouseEvent) => {
    if (timer) clearTimeout(timer);
    if (e.detail === 1) {
      e.preventDefault();
      timer = setTimeout(() => {
        if (actions.onClickItem) actions.onClickItem(item, parent, isInGroup);
      }, 200);
    } else if (e.detail === 2) {
      if (actions.onDoubleClickItem) actions.onDoubleClickItem(item, parent, isInGroup);
    }
  };

  const icon = getGridItemIcon(item, isHover);

  return (
    <div
      id={`file_explorer_template_${item.id}`} // id for onboarding and scroll
      className={classNames(
        styles.root, isSelected && styles.selected, item.type === 'template' ? 'file-explorer-template' : 'file-explorer-folder',
        isActive && styles.active,
        isUpdated && styles.updated,
        isMenuOpen && styles.menuOpen
      )}
    >
      <div
        className={styles.container}
        onClick={handleClick}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onContextMenu={e => { e.preventDefault(); setOptionsOpen(true); }}
        data-testid={`template_${item.id}`}
      >
        <div className={styles.iconContainer}>
          <img
            className={styles.icon}
            src={icon}
            alt=""
          />
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.name}>
            {item.name}
          </div>
        </div>
        {item.favorite && (
          <div className={styles.fave}>
            <img src="/icons/favorite.svg" className={styles.faveIcon} alt="fave" />
          </div>
          // actions?.onFavouritesToggle ? (
          //   <div className={styles.fave} onClick={(e) => {
          //     e.stopPropagation();
          //     if (actions.onFavouritesToggle) actions.onFavouritesToggle({ id: item.id, type: item.type, checked: false });
          //   }}>
          //     <Tooltip control={<img src="/icons/favorite.svg" className={styles.faveIcon} alt="fave" />}>
          //       {t('remove_from_favourites')}
          //     </Tooltip>
          //   </div>
          // ) : (
          //   <div className={styles.fave}>
          //     <img src="/icons/favorite.svg" className={styles.faveIcon} alt="fave" />
          //   </div>
          // )
        )}
      </div>

      {!hideOptions &&
        <div className={styles.optionsButton}>
          {isRemoved ? (
            <TrashContextMenu
              open={isOptionsOpen}
              position={['bottom left', 'top left', 'bottom right', 'top right']}
              item={item}
              onToggleMenu={(id: string | null) => { setOptionsOpen(!!id); onToggleMenu(id); }}
              editing={editing}
            />
          ) : (
            isFolder ? (
              <FolderContextMenu
                open={isOptionsOpen}
                position={['bottom left', 'top left', 'bottom right', 'top right']}
                item={item}
                items={items}
                parent={parent}
                onToggleMenu={(id: string | null) => { setOptionsOpen(!!id); onToggleMenu(id); }}
                onToggleModal={onToggleModal}
                onHoverSubmenuItem={onHoverSubmenuItem}
                editing={editing}
                actions={actions}
                userGroups={userGroups}
              />
            ) : (
              <TemplateContextMenu
                open={isOptionsOpen}
                position={['bottom left', 'top left', 'bottom right', 'top right']}
                item={item}
                items={items}
                parent={parent}
                onToggleMenu={(id: string | null) => { setOptionsOpen(!!id); onToggleMenu(id); }}
                onToggleModal={onToggleModal}
                onHoverSubmenuItem={onHoverSubmenuItem}
                editing={editing}
                actions={actions}
              />
            )
          )}
        </div>
      }
    </div>
  );
};

export default ExplorerGridItem;

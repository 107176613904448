import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Quantity } from '@forma/forma-ui-kit';
import formatPrice from 'helpers/formatPrice';
import { POPULAR_TARIFF } from 'data/constants';
import { currencies } from 'data/mock';

import { IPromocode, ITariffItem } from 'interfaces/tariffs.interface';

import styles from './tariffs-list.module.css';

interface TariffsListItemProps {
  tariff: ITariffItem,
  disabled?: boolean,
  limitDisabled?: boolean,
  promocode?: IPromocode | null,
  selectedDuration: number,
  onSelectTariff: (data: { id: string, name: string, members: number, attachmentsSize: number }) => void
}

const USERS_INDEX = 0;
const ATTACHMENTS_INDEX = 7;

const TariffsListItem: FC<TariffsListItemProps> = ({
  tariff, disabled, limitDisabled, promocode, selectedDuration, onSelectTariff
}) => {
  const { t } = useTranslation();
  const currency = currencies.ru;

  const { id, name, canAddUsers, patterns, isPromo, advantages } = tariff;

  const [selectedMembers, setMembers] = useState<number>(0);
  const [selectedAttachmentSize, setAttachmentSize] = useState<number>(0);

  const pattern = patterns.find(({ duration }) => duration === selectedDuration);
  const durationMonths = pattern ? pattern.duration / 30 : 0;
  const durationBasePrice = pattern ? pattern.basePrice / durationMonths : 0;
  const durationPrice = pattern ? pattern.price / durationMonths : 0;
  const durationUserPrice = (pattern?.userPrice && canAddUsers) ? pattern.userPrice / durationMonths : 0;
  const durationAttachmentsSizePrice = pattern ? pattern?.attachmentsSizePrice / durationMonths : 0;
  // const maxUserPrice = Math.max(...patterns.map(({ duration, userPrice }) => userPrice ? userPrice / (duration / 30) : 0));

  const handleSelectTariff = () => {
    onSelectTariff({ id, name, members: selectedMembers, attachmentsSize: selectedAttachmentSize });
  };

  return (
    <div className={classNames(
      styles.item,
      POPULAR_TARIFF === id && styles.selected,
      disabled && styles.disabled,
      isPromo && styles.promo
    )}>
      <div className={styles.content}>
        <div className={styles.label}>
          {isPromo && t('subscription.secret_tariff')}
          {POPULAR_TARIFF === id && t('subscription.popular')}
        </div>

        <div className={styles.name}>{name}</div>

        {!!durationPrice && (
          <>
            {/* {isPromo ? (
              <>
                <div className={styles.price}>
                  {formatPrice(durationBasePrice, currency)}
                  <ReactSVG src="/icons/fire.svg" className={styles.priceIcon} wrapper="span" />
                </div>
                <div className={styles.price}>
                  {t('subscription.for_count_months', { count: durationMonths })}
                </div>
              </>
            ) : (
            )} */}
            {/* {!isPromo && (
              <div className={styles.priceDescr}>
                {t('subscription.economy_price_for_payment', {
                  price: formatPrice(
                    durationBasePrice*durationMonths - durationPrice*durationMonths
                    + selectedMembers*maxUserPrice*durationMonths - selectedMembers*durationUserPrice*durationMonths,
                    currency
                  )
                })} {t('count_months', {
                  count: durationMonths
                })}
              </div>
            )} */}
            <div className={styles.price}>
              <span className={styles.priceCurrent}>{formatPrice(durationPrice, currency)}</span>
              {durationPrice !== durationBasePrice && (
                <> <span className={styles.priceOld}>{formatPrice(durationBasePrice, currency)}</span></>
              )}
              <span className={styles.priceDuration}> / {t('subscription.per_month')}</span>
            </div>
            {durationMonths > 1 && (
              <div className={styles.price}>
                <span className={classNames(styles.priceCurrent, styles.priceBase)}>{formatPrice(pattern?.price ?? 0, currency)}</span>
                <span className={styles.priceDuration}> / {t('count_months', { count: durationMonths })}</span>
              </div>
            )}
          </>
        )}

        <Button
          className={styles.button}
          onClick={handleSelectTariff}
          fullWidth
        >
          {t('subscription.place_order')}
        </Button>

        <div className={styles.buttonNotice}>{t('subscription.first_days_free')}</div>
      </div>

      <div className={styles.features}>
        {advantages?.map((item, index) => (
          <div className={styles.feature} key={index}>
            <span className={styles.featureIcon} />
            <div className={styles.featureText}>
              {item}
              {!!(durationUserPrice && index === USERS_INDEX) && (
                <div className={styles.additionalCount}>
                  <div className={styles.additionalDescr}>
                    {t('subscription.additional_user_over_tariff', { price: formatPrice(durationUserPrice, currency).replaceAll(' ', ' ') })}
                  </div>
                  <Quantity value={selectedMembers} onChange={setMembers} disabled={limitDisabled} />
                </div>
              )}
              {index === ATTACHMENTS_INDEX && (
                <div className={styles.additionalCount}>
                  <div className={styles.additionalDescr}>
                    {t('subscription.place_over_tariff', { price: formatPrice(durationAttachmentsSizePrice, currency).replaceAll(' ', ' ') })}
                  </div>
                  <Quantity value={selectedAttachmentSize} onChange={setAttachmentSize} disabled={limitDisabled} />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TariffsListItem;

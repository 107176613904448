import { FC, ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Tags, Button, RemoveModal, ThemeContext } from '@forma/forma-ui-kit';
import { hasPermission, PERMISSIONS } from 'helpers/permissions';
import { files_icons, categories_colors } from 'data/mock';

import { useAppSelector } from 'store/hooks';
import { IAttachmentItem } from 'interfaces/attachments.interface';
import { selectUserPermissions } from 'store/user/userSlice';

import styles from './document-info.module.css';

interface DocumentInfoProps {
  data: IAttachmentItem,
  onClickEdit: (id: string) => void,
  onClickAddSubitem: (id: string) => void,
  onClickCopyLink: (id: string) => void,
  onClickDownload: (ids: string[]) => void,
  isDownloadLoading: boolean,
  onRemove: (id: string) => void,
  onSign: (id: string) => void,
}

const FeatureItem = ({ label, value, className }: { label: ReactNode, value: ReactNode, className?: string }) => {
  return (
    <div className={classNames(styles.featuresItem, className)}>
      <div className={styles.featureLabel}>{label}:</div>
      <div className={styles.featureValue}>{value}</div>
    </div>
  );
};

const DocumentInfo: FC<DocumentInfoProps> = ({
  data, onClickEdit, onClickAddSubitem, onClickCopyLink, onClickDownload, isDownloadLoading, onRemove, onSign,
}) => {
  const { t } = useTranslation();
  const userPermissions = useAppSelector(selectUserPermissions);
  const canDownloadFiles = hasPermission(userPermissions ?? [], PERMISSIONS.ATTACHMENTS.DOWNLOAD);
  const canAddFiles = hasPermission(userPermissions ?? [], PERMISSIONS.ATTACHMENTS.CREATE);
  const canEditFiles = hasPermission(userPermissions ?? [], PERMISSIONS.ATTACHMENTS.EDIT);
  const canDeleteFiles = hasPermission(userPermissions ?? [], PERMISSIONS.ATTACHMENTS.DELETE);

  const { viewport } = useContext(ThemeContext);
  const [isOpenRemove, setOpenRemove] = useState<boolean>(false);
  const {
    id, name, ext, description, size, categories, uploadedAt, dtFrom, dtTo,
    filename, inactive, number, signedAt, isProlongable
  } = data;

  const kb = Number((size / 1000).toFixed());
  const mb = Number((kb / 1000).toFixed());

  const validDates = [dtFrom && format(new Date(dtFrom), 'dd.MM.yyyy'), dtTo && format(new Date(dtTo), 'dd.MM.yyyy')];

  return (
    <div className={styles.root}>
      {inactive && (
        <div className={styles.outOfDate}>
          {t('out_of_date')}
        </div>
      )}
      <div className={styles.heading}>
        <img src={files_icons[ext] ? files_icons[ext] : files_icons.other} className={styles.fileIcon} alt="" />
        <div className={styles.title}>{name}</div>
      </div>
      <div className={styles.controls}>
        <Button
          viewStyle="textLight"
          icon={isDownloadLoading ? <ReactSVG src="/icons/loading.svg" wrapper="span" /> : <ReactSVG src="/icons/download.svg" wrapper="span" />}
          title={t('download')}
          onClick={() => onClickDownload([id])}
          disabled={!canDownloadFiles}
        // isLoading={isDownloadLoading}
        />
        <Button
          viewStyle="textLight"
          icon={<ReactSVG src="/icons/copy.svg" wrapper="span" />}
          title={t('copy_link')}
          onClick={() => onClickCopyLink(id)}
        />
        <Button
          viewStyle="textLight"
          icon={<ReactSVG src="/icons/document-add.svg" wrapper="span" />}
          title={viewport !== 'phone' ? t('add_document') : undefined}
          onClick={() => onClickAddSubitem(id)}
          disabled={!canAddFiles}
        />
        <div style={{ flexGrow: 1 }} />
        <Button
          viewStyle="textLight"
          icon={<ReactSVG src="/icons/edit.svg" wrapper="span" />}
          title={t('edit')}
          onClick={() => onClickEdit(id)}
          disabled={!canEditFiles}
        />
        <Button
          viewStyle="textLight"
          icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
          title={t('delete')}
          onClick={() => setOpenRemove(true)}
          disabled={!canDeleteFiles}
        />
      </div>
      <div className={styles.features}>
        <FeatureItem
          className={styles.halfWidth}
          label={t('extension')}
          value={ext}
        />
        <FeatureItem
          className={styles.halfWidth}
          label={t('size')}
          value={mb > 0 ? `${mb} MB` : `${kb} KB`}
        />
        <FeatureItem
          className={styles.halfWidth}
          label={t('creation_date')}
          value={uploadedAt && format(new Date(uploadedAt), 'dd.MM.yyyy')}
        />
        <FeatureItem
          className={styles.halfWidth}
          label={t('signed_date')}
          value={signedAt ? format(new Date(signedAt), 'dd.MM.yyyy') : '-'}
        />
        <FeatureItem
          className={styles.halfWidth}
          label={t('validity')}
          value={validDates.join(' - ')}
        />
        <FeatureItem
          className={styles.halfWidth}
          label={t('prolongable')}
          value={
            isProlongable ? t('yes') : t('no')
            // <Checkbox
            //   id="prolongable"
            //   name="prolongable"
            //   containerClass={styles.checkbox}
            //   checked={isProlongable}
            // />
          }
        />
        {number && (
          <FeatureItem
            className={styles.fullWidth}
            label={t('document_number')}
            value={number}
          />
        )}
        <FeatureItem
          className={classNames(styles.fullWidth, styles.description)}
          label={t('file_description')}
          value={description || '-'}
        />
        <FeatureItem
          className={styles.fullWidth}
          label={t('categories')}
          value={
            <Tags
              className={styles.categories}
              items={categories?.map(({ name, color }) => ({
                name: name,
                color: categories_colors[color]?.color,
                backgroundColor: categories_colors[color]?.background
              }))}
            />
          }
        />
      </div>
      {!signedAt && (
        <div className={styles.buttons}>
          <Button
            viewStyle="tertiary"
            size="small"
            className={styles.button}
            onClick={() => onSign(id)}
          >
            {t('sign_document')}
          </Button>
        </div>
      )}
      <RemoveModal
        open={isOpenRemove}
        onClose={setOpenRemove}
        onRemove={() => onRemove(id)}
        title={t('deleting_file')}
        itemName={name ?? filename}
      />
    </div>
  );
};

export default DocumentInfo;

const apiUrl = process.env.REACT_APP_API_URL;

const webView = {
  sendMessage: (message: string) => {
    window?.ReactNativeWebView?.postMessage(message);
  },
  sendObject: <T>(data: T) => {
    window?.ReactNativeWebView?.postMessage(JSON.stringify(data));
  },
  renderTemplate: (id: string, name: string, body: any) => {
    const message = {
      type: 'downloadDocument',
      method: 'POST',
      document: name,
      link: `${apiUrl}/templates/${id}/render`,
      body
    };
    webView.sendObject(message);
  },
  rerenderTemplate: (id: string, renderId: string, name: string, body: any) => {
    const message = {
      type: 'downloadDocument',
      method: 'POST',
      document: name,
      link: `${apiUrl}/templates/${id}/render/${renderId}`,
      body
    };
    webView.sendObject(message);
  },
  renderPack: (id: string, name: string, body: any) => {
    const message = {
      type: 'downloadDocument',
      method: 'POST',
      document: name,
      link: `${apiUrl}/packs/${id}/render`,
      body
    };
    webView.sendObject(message);
  },
  downloadDocument: (id: string, renderId: string, name: string, format: string) => {
    const message = {
      type: 'downloadDocument',
      method: 'GET',
      document: name,
      link: `${apiUrl}/templates/${id}/render/${renderId}?format=${format}`
    };
    webView.sendObject(message);
  },
  renderStrictTemplate: (id: string, name: string, body: any) => {
    const message = {
      type: 'downloadDocument',
      method: 'POST',
      document: name,
      link: `${apiUrl}/stricttemplates/${id}/render`,
      body
    };
    webView.sendObject(message);
  },
  reRenderStrictTemplate: (id: string, renderId: string, name: string, body: any) => {
    const message = {
      type: 'downloadDocument',
      method: 'POST',
      document: name,
      link: `${apiUrl}/stricttemplates/${id}/render/${renderId}`,
      body
    };
    webView.sendObject(message);
  },
  downloadStrictDocument: (id: string, renderId: string, name: string) => {
    const message = {
      type: 'downloadDocument',
      method: 'GET',
      document: name,
      link: `${apiUrl}/stricttemplates/${id}/render/${renderId}`
    };
    webView.sendObject(message);
  },
  downloadExternalFill: (key: string, name: string) => {
    const message = {
      type: 'downloadDocument',
      method: 'GET',
      document: name,
      link: `${apiUrl}/externalinfillspublic/${key}/render`
    };
    webView.sendObject(message);
  },
  createInvoice: (name: string, body: any) => {
    const message = {
      type: 'downloadDocument',
      method: 'POST',
      document: name,
      link: `${apiUrl}/accounting/documents/invoice`,
      body
    };
    webView.sendObject(message);
  },
  externalFillAttachemnts: (id: string, name: string) => {
    const message = {
      type: 'downloadDocument',
      method: 'GET',
      document: name,
      link: `${apiUrl}/externalinfills/${id}/attachments`,
    };
    webView.sendObject(message);
  },
  safeDownload: (id: string, name: string) => {
    const message = {
      type: 'downloadDocument',
      method: 'GET',
      document: name,
      link: `${apiUrl}/attachments/${id}/download`,
    };
    webView.sendObject(message);
  },
  safeDownloadArchive: (ids: string[], name: string) => {
    const message = {
      type: 'downloadDocument',
      method: 'GET',
      document: name,
      link: `${apiUrl}/attachments/downloadArchive?ids=${ids.join(',')}`,
    };
    webView.sendObject(message);
  }
};

export default webView;

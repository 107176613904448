import { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton, Input, ButtonsContainer } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { MainLayout } from 'components/Layouts';
// import RegisterBanner from 'views/account/RegisterBanner';
import getValidationSchema from 'data/validationSchema';
import { getCookie, setCookie } from 'helpers/cookies';

import { useVerifyUserMutation } from 'store/auth/authApi';

import styles from './Account.module.css';

interface FormValues {
  login: string
}

// const landingUrl = process.env.REACT_APP_LANDING_URL;

const Register = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  // const { lang } = useContext(ThemeContext);
  const [sendCode, { isLoading, isError }] = useVerifyUserMutation();

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: yupResolver(getValidationSchema(['login'])), defaultValues: { login: state?.login }
  });
  // const login = watch('login');

  // const { data: mailDomains } = useGetMailDomainQuery({ email: login }, { skip: !isValid });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const roistatVisit = getCookie('roistat_visit');
    sendCode({ ...data, roistatVisit }).then(result => {
      if ('data' in result) navigate('/register/code', { state: { login: data.login } });
    });
  };

  useEffect(() => {
    const date = new Date();
    const cookieUtm = getCookie('utm');
    const utm: { [key: string]: string } = cookieUtm ? JSON.parse(decodeURIComponent(cookieUtm)) : {};
    for (const [key, value] of searchParams.entries()) {
      utm[key] = value;
    }
    setCookie('utm', JSON.stringify(utm), {
      path: '/',
      expires: new Date(date.setDate(date.getDate() + 7)),
    });
  }, [searchParams]);

  return (
    <MainLayout
      title={<Trans i18nKey="register2" components={{ accent: <span className="accent-text" /> }} />}
      subtitle={<div className={styles.trial}>{t('account.trial_notice')}</div>}
    // sideChildren={<RegisterBanner />}
    >
      <PageTitle>{t('site_name') + ' – ' + t('register2')}</PageTitle>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputs}>
          <Controller
            control={control}
            name="login"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                id="login"
                viewStyle="secondary"
                placeholder={t('email_short')}
                maxLength={255}
                inputMode="email"
                {...rest}
                {...register}
                value={value}
                autoComplete="email"
                data-testid="login"
                meta={{
                  error: !!errors?.login?.message || isError,
                  errorMessage: !!errors?.login?.message && t('errors.' + errors.login.message),
                  showClearButton: true
                }}
              />
            )}
          />
        </div>
        {/* {!!(isValid && mailDomains?.length && mailDomains[0].name) && (
          <div className={styles.notice}>
            {t('account.use_company_email')}
          </div>
        )} */}
        <ButtonsContainer className={styles.buttons}>
          <LoadingButton type="submit" disabled={!isValid} isLoading={isLoading} fullWidth data-testid="submit">
            {t('continue')}
          </LoadingButton>
        </ButtonsContainer>
        {/* <div className={styles.agreement}>
          <Trans
            i18nKey="account.register_oferta"
            values={{
              button: t('continue')
            }}
            components={{
              // eslint-disable-next-line
              agreement_tag: <a href={`${landingUrl}/${lang}/license/`} target="_blank" />,
              // eslint-disable-next-line
              privacy_tag: <a href={`${landingUrl}/${lang}/privacy/`} target="_blank" />
            }}
          />
        </div> */}
      </form>

      <div className={styles.anotherAction}>
        {t('account.already_have_account')} <Link to="/login" data-testid="goToLogin">{t('sign_in')}</Link>
      </div>
    </MainLayout>
  );
};

export default Register;

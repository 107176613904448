import { TCounteragentSorting } from 'interfaces/counteragents.interface';

export const sortings: { [key: string]: TCounteragentSorting[] } = {
  entity: [
    'name',
    '-name',
    'fullName',
    '-fullName',
    'createdAt',
    '-createdAt'
  ],
  individual: [
    'name',
    '-name',
    'fullName',
    '-fullName',
    'createdAt',
    '-createdAt'
  ],
  person: [
    'name',
    '-name',
    'createdAt',
    '-createdAt'
  ]
};

import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';

import styles from './ErrorPage.module.css';

const AccessDenied = () => {
  const { t } = useTranslation();

  return (
    <AdminLayout title=" ">
      <PageTitle>{t('site_name') + ' – ' + t('page_error')}</PageTitle>
      <div className={styles.accessDenied}>
        <h1 className={styles.accessDeniedTitle}>{t('access_denied')}</h1>
        <img src="/images/error-robot-2.svg" className={styles.image} alt={t('page_error')} />
        <div className={styles.description}>
          <div>
            {t('access_denied_description')}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AccessDenied;

export const hasPermission = (userPermissions: string[], checking: string) => {
  return userPermissions.includes(checking);
};
export const hasPermissionsList = (userPermissions: string[], checking: string[]) => {
  return checking.every((permission) => userPermissions.includes(permission));
};
export const hasAnyPermission = (userPermissions: string[], checking: string[]) => {
  return checking.some((permission) => userPermissions.includes(permission));
};

export const PERMISSIONS = {
  FOLDERS_TEMPLATES: {
    VIEW: 'templatesFolders:view',
  },
  TEMPLATES: {
    CREATE: 'templates:create',
    EDIT: 'templates:edit',
    DELETE: 'templates:delete',
  },
  FOLDERS: {
    CREATE: 'folders:create',
    EDIT: 'folders:edit',
    DELETE: 'folders:delete',
  },
  RENDERS: {
    CREATE: 'renders:create',
    VIEW: 'renders:view',
    EDIT: 'renders:edit',
    DELETE: 'renders:delete',
  },
  SIGNED_EXTERNALINFILLS: {
    CREATE: 'signedExternalInfills:create',
    VIEW: 'signedExternalInfills:view',
    EDIT: 'signedExternalInfills:edit',
    DELETE: 'signedExternalInfills:delete',
  },
  EXTERNALINFILLS: {
    CREATE: 'externalInfills:create',
    VIEW: 'externalInfills:view',
    EDIT: 'externalInfills:edit',
    DELETE: 'externalInfills:delete',
  },
  PRODUCTS: {
    CREATE: 'products:create',
    VIEW: 'products:view',
    EDIT: 'products:edit',
    DELETE: 'products:delete',
  },
  COUNTERAGENTS: {
    CREATE: 'counteragents:create',
    VIEW: 'counteragents:view',
    EDIT: 'counteragents:edit',
    DELETE: 'counteragents:delete',
  },
  ATTACHMENTS: {
    CREATE: 'attachments:create',
    VIEW: 'attachments:view',
    EDIT: 'attachments:edit',
    DELETE: 'attachments:delete',
    DOWNLOAD: 'attachments:download',
  },
  USERS: {
    CREATE: 'users:create',
    VIEW: 'users:view',
    EDIT: 'users:edit',
    DELETE: 'users:delete',
  },
  DOWNLOAD: {
    PDF: 'pdf:download',
    DOCX: 'docx:download',
  },
  APIKEY: 'apiKey:view',
} as const;

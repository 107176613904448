import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getFormaFolders } from './model/getFormaFolders';
import getFavouritesFromTree from './model/getFavouritesFromTree';
import FolderContent from './FolderContent';

import { useAppSelector } from 'store/hooks';
import { selectLayout } from 'store/common/commonSlice';
import { useGetFavouritesQuery, useGetFoldersTreeQuery } from 'store/folders/foldersApi';
import { IFolderListTreeChidlren } from 'interfaces/folders.interface';

const Favourites = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const layout = useAppSelector(selectLayout);

  const { data: favourites, isLoading: isFoldersLoading, error: pageError }
    = useGetFavouritesQuery(undefined, { skip: layout === 'list', pollingInterval: 5000 });

  const { data: foldersTree, isLoading: isFoldersTreeLoading }
    = useGetFoldersTreeQuery(undefined, { skip: layout !== 'list', pollingInterval: 5000 });

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status === 404) navigate('/404', { replace: true });
    }
    // eslint-disable-next-line
  }, [pageError]);

  const formaFolders = getFormaFolders(t);
  const favouritesInTree: IFolderListTreeChidlren[] | undefined = foldersTree && getFavouritesFromTree(foldersTree);

  return (
    <FolderContent
      type="favourites"
      foldersItems={favourites ? [...formaFolders, ...favourites.items] : formaFolders}
      foldersTree={favouritesInTree ? [...formaFolders, ...favouritesInTree] : formaFolders}
      isLoading={isFoldersLoading || isFoldersTreeLoading}
    />
  );
};

export default Favourites;

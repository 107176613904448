import { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { LoadingButton, Modal, Input, ButtonsContainer } from '@forma/forma-ui-kit';
import UserGroupsItem, { UserGroupsItemSkelet } from './UserGroupsItem';

import { IUserGroupsItem } from 'interfaces/users.interface';

import styles from './user-groups-edit.module.css';

interface UserGroupsProps {
  permissions?: string[],
  groups?: IUserGroupsItem[],
  onChangeGroup: (data: IUserGroupsItem) => void,
  isGroupLoading: boolean,
  isAddGroupLoading: boolean,
  onAddGroup: (name: string) => void,
  onRemoveGroup: (id: string) => void
}

const UserGroups: FC<UserGroupsProps> = ({
  permissions, groups, onChangeGroup, isGroupLoading, isAddGroupLoading, onAddGroup, onRemoveGroup
}) => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState<string>('');

  const handleCickCreate = () => {
    onAddGroup(groupName);
    setGroupName('');
  };

  return (
    <div className={styles.root}>
      <div className={styles.items}>
        {groups ? (
          groups.map((item) => (
            <UserGroupsItem
              {...item}
              allPermissions={permissions}
              onChange={onChangeGroup}
              onRemove={onRemoveGroup}
              key={item.id}
            />
          ))
        ) : (
          [...Array(3)].map((item, index) => (
            <UserGroupsItemSkelet key={'row' + index} />
          ))
        )}
      </div>
      <ButtonsContainer className={styles.buttons}>
        <Modal
          size="small"
          control={
            <LoadingButton
              viewStyle="secondary"
              isLoading={isAddGroupLoading}
              icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
              className={styles.buttonCreate}
            />
          }
          title={t('input_group_name')}
          buttons={[
            {
              children: t('create'),
              onClick: handleCickCreate,
              disabled: groupName === '' || groupName.length < 3
            }
          ]}
        >
          <div className={styles.modalContent}>
            <Input
              name="group_name"
              viewStyle="secondary"
              placeholder={t('input_group_name')}
              onChange={e => setGroupName(e.target.value)}
              value={groupName}
              meta={{
                showClearButton: true
              }}
            />
          </div>
        </Modal>
      </ButtonsContainer>
    </div>
  );
};

export default UserGroups;

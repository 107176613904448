import { FC, ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AccessDenied, LoadingPage } from 'pages/Common';
import { hasAnyPermission } from 'helpers/permissions';

import { IRoute } from 'interfaces/common.interface';

interface ProtectedRouteProps extends IRoute {
  children: ReactElement,
  userLogged?: boolean,
  userPermissions?: string[]
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  logged, userLogged, redirect = '/register', skip_auth,
  permissions, userPermissions,
  children,
}) => {
  const isAllowedByAuth = (logged && userLogged) || (!logged && !userLogged) || skip_auth || false;
  const isAllowedByPermissions = !permissions || hasAnyPermission(userPermissions ?? [], permissions);

  const isMobileApp = window?.navigator?.userAgent === 'forma-mobile';
  if (isMobileApp && redirect === '/register') redirect = '/login';

  if (!isAllowedByAuth) return <Navigate to={redirect} replace={true} />;

  if (logged) {
    if (!userPermissions) return <LoadingPage />;
    if (!isAllowedByPermissions) return <AccessDenied />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;

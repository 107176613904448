import { TFunction } from 'i18next';
import {
  FORMA_FOLDER_ID_BACK,
  FORMA_FOLDER_ID_PACKS,
  FORMA_FOLDER_ID_STATIC,
  FORMA_FOLDER_ID_STRICT,
  FORMA_FOLDER_ID_TRASH
} from 'data/constants';

import { IFolderListChidlren } from 'interfaces/folders.interface';

export const getFormaFolders = (t: TFunction): IFolderListChidlren[] => ([
  { id: FORMA_FOLDER_ID_STATIC, type: 'folder', external: true, name: t('forma_templates'), translatedName: '', groups: [], favorite: true },
  { id: FORMA_FOLDER_ID_PACKS, type: 'folder', external: true, name: t('templates_packs'), translatedName: '', groups: [], favorite: true },
  { id: FORMA_FOLDER_ID_STRICT, type: 'folder', external: true, name: t('strict_templates'), translatedName: '', groups: [], favorite: true },
  { id: FORMA_FOLDER_ID_TRASH, type: 'folder', external: true, name: t('trash'), translatedName: '', groups: [], favorite: true },
]);

export const getBackFolder = (t: TFunction): IFolderListChidlren => ({
  id: FORMA_FOLDER_ID_BACK,
  type: 'folder',
  external: true,
  name: t('go_back'),
  translatedName: '',
  groups: []
});

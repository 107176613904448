import { FC, useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import update from 'immutability-helper';
import classNames from 'classnames';
import { Button, LoadingButton, PaginationProps, Table, PaginationWrapper, RemoveModal } from '@forma/forma-ui-kit';
import { hasPermission, PERMISSIONS } from 'helpers/permissions';
import SafeDocumentsHeader from './SafeDocumentsHeader';
import DocumentsItem from './DocumentsItem';

import { useAppSelector } from 'store/hooks';
import { selectUserPermissions } from 'store/user/userSlice';
import { IAttachmentHierarhyItem, IAttachmentCategoriesItem } from 'interfaces/attachments.interface';

import styles from './safe-documents-list.module.css';

interface SafeDocumentsListProps {
  active?: string,
  items: IAttachmentHierarhyItem[],
  categories: {
    items?: IAttachmentCategoriesItem[],
    onCreate: (data: { name: string, color: string }) => void,
    isLoading: boolean
  },
  onClickCategory?: (id: string) => void,
  onClickItem: (id: string) => void,
  onDownload: (selectedIds: string[], parentName?: string | null) => void,
  isDownloadLoading: boolean,
  onRemove: (selectedIds: string[]) => void,
  isRemoveLoading: boolean,
  onClickAdd: () => void,
  onChangeSearch: (value: string) => void,
  onChangeFilter: (data: { dtToFrom?: string, dtToTo?: string, dtFilter?: string, categoriesid?: string }) => void,
  pagination: PaginationProps
}

const SafeDocumentsList: FC<SafeDocumentsListProps> = ({
  active, items, categories, onClickCategory, onClickItem, onClickAdd, onDownload, isDownloadLoading, onRemove, isRemoveLoading,
  onChangeSearch, onChangeFilter, pagination
}) => {
  const { t } = useTranslation();
  const sentinelRef = useRef<HTMLDivElement>(null);
  const userPermissions = useAppSelector(selectUserPermissions);
  const canDownloadFiles = hasPermission(userPermissions ?? [], PERMISSIONS.ATTACHMENTS.DOWNLOAD);
  const canDeleteFiles = hasPermission(userPermissions ?? [], PERMISSIONS.ATTACHMENTS.DELETE);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isOpenRemove, setOpenRemove] = useState<boolean>(false);
  const [topPanelSticky, setTopPanelSticky] = useState<boolean>(false);

  useEffect(() => {
    if (!sentinelRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setTopPanelSticky(!entry.isIntersecting);
      },
      { rootMargin: '-54px 0px 0px 0px', threshold: 0.1 }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [sentinelRef]);

  useEffect(() => {
    if (!isDownloadLoading) setSelectedIds([]);
  }, [isDownloadLoading]);

  const handleSelectItems = (ids: string[], checked: boolean) => {
    if (checked) {
      setSelectedIds(prev => {
        const idsToPush = ids.filter(id => !prev.includes(id));
        return update(prev, { $push: idsToPush });
      });
    } else {
      setSelectedIds(prev => prev.filter(id => !ids.includes(id)));
    }
  };

  const handleClickDownload = () => {
    onDownload(selectedIds, (selectedIds.length === 1) ? items.find(({ id }) => id === selectedIds[0])?.filename : null);
    // setSelectedIds([]);
  };

  const handleClickRemove = () => {
    onRemove(selectedIds);
    setSelectedIds([]);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <SafeDocumentsHeader
          categories={categories}
          onChangeSearch={onChangeSearch}
          onChangeFilter={onChangeFilter}
          onClickAdd={onClickAdd}
        />
      </div>
      <div className={classNames(styles.content, isDownloadLoading && styles.blocked)}>
        <div ref={sentinelRef} style={{ height: '1px' }}></div>
        {!!selectedIds.length && (
          <div className={classNames(styles.topButtons, topPanelSticky && styles.sticky)}>
            <LoadingButton
              isLoading={isDownloadLoading}
              className={styles.downloadBtn}
              viewStyle="primary"
              size="small"
              onClick={handleClickDownload}
              disabled={!canDownloadFiles}
            >
              <div>{t('download')}</div>
            </LoadingButton>
            <LoadingButton
              isLoading={isRemoveLoading}
              className={styles.removeBtn}
              viewStyle="tertiary"
              size="small"
              onClick={() => setOpenRemove(true)}
              disabled={!canDeleteFiles}
            >
              <div>{t('delete')}</div>
            </LoadingButton>
            <div className={styles.selected}>{t('selected')}: {selectedIds.length}</div>
            <Button
              className={styles.resetBtn}
              viewStyle="text"
              size="small"
              onClick={() => setSelectedIds([])}
              icon={<ReactSVG src="/icons/close.svg" wrapper="span" />}
              title={t('reset')}
              iconClassName={styles.resetBtnIcon}
            />
          </div>
        )}
        <PaginationWrapper {...pagination}>
          <Table columns={[
            { children: null, className: styles.cellArrow },
            { children: null, className: styles.cellIcon },
            { children: t('doc_name'), className: styles.cellName },
            { children: t('file_category'), className: styles.cellCategory },
            { children: t('related_contragent'), className: styles.cellContragent },
            { children: t('activity_period'), className: styles.cellValid },
            { children: null, className: styles.cellButtons }
          ]}>
            {!!items?.length && (
              items.map(item => (
                <DocumentsItem
                  {...item}
                  active={active}
                  selected={selectedIds}
                  onClickCategory={onClickCategory}
                  onSelectItems={handleSelectItems}
                  onClick={onClickItem}
                  key={item.id}
                />
              ))
            )}
          </Table>
        </PaginationWrapper>
      </div>

      <RemoveModal
        open={isOpenRemove}
        onClose={setOpenRemove}
        onRemove={handleClickRemove}
        title={t('deleting_files')}
      // itemName={name ?? filename}
      />
    </div>
  );
};

export default SafeDocumentsList;

import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Checkbox } from '@forma/forma-ui-kit';
import { hasPermission, PERMISSIONS } from 'helpers/permissions';

import { selectUserPermissions } from 'store/user/userSlice';
import { IUserGroupsItem } from 'interfaces/users.interface';

import styles from './user-groups.module.css';

interface UserGroupsProps {
  groups?: IUserGroupsItem[],
  selected?: IUserGroupsItem[],
  onChange?: (data: { [key: string]: boolean }) => void,
  disabled?: boolean
}

const UserGroups: FC<UserGroupsProps> = ({ groups, selected, onChange, disabled }) => {
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: boolean }>({});
  const userPermissions = useSelector(selectUserPermissions);

  const canChangeUsers = userPermissions && hasPermission(userPermissions, PERMISSIONS.USERS.EDIT);

  useEffect(() => {
    if (selected?.length) {
      const _items = selected.reduce((prev, { id }) => ({ ...prev, [id]: true }), {});
      setSelectedItems(_items);
    }
    // eslint-disable-next-line
  }, [selected]);

  const handleChangeSelected = (id: string, checked: boolean) => {
    if (!canChangeUsers) return;
    let next = selectedItems;
    setSelectedItems(prev => {
      next = { ...prev, [id]: checked };
      return next;
    });
    if (onChange) onChange(next);
  };

  if (!groups) return (
    <div className={styles.root} id="user_groups_change">
      {[...Array(3)].map((_item, index) => (
        <label className={classNames(styles.item, 'skeleton-loader')} key={index} />
      ))}
    </div>
  );

  return (
    <div className={styles.root} id="user_groups_change">
      {groups.map(({ id, name }) => {
        if (!canChangeUsers && !selectedItems[id]) return null;

        return (
          <Checkbox
            id={id}
            name={id}
            label={name}
            viewStyle="square"
            size="medium"
            containerClass={styles.item}
            checked={selectedItems[id]}
            onChange={e => handleChangeSelected(id, e.target.checked)}
            disabled={!canChangeUsers || disabled}
          />
        );
      })}
    </div>
  );
};

export default UserGroups;

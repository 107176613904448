import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal, FileDropzone } from '@forma/forma-ui-kit';
import DocumentEdit from 'views/SafeDocumentsList/DocumentEdit';

import {
  useGetAttachmentCategoriesQuery,
  useCreateAttachmentCategoryMutation,
  useAddAttachmentMutation,
} from 'store/attachments/attachmentsApi';
import { IAttachmentItemEdit } from 'interfaces/attachments.interface';

// import styles from './document-modal.module.css';

interface DocumentCreateModalProps {
  open: boolean,
  onClose: (open: boolean) => void,
  parentId?: string | null
}

const DocumentCreateModal: FC<DocumentCreateModalProps> = ({
  open, onClose, parentId
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mode, setMode] = useState<'upload' | 'edit'>('upload');

  const { data: categories } = useGetAttachmentCategoriesQuery();
  const [addAttachment, { isLoading: isAddLoading }] = useAddAttachmentMutation();
  const [createCategory, { isLoading: isCreateCategoryLoading }] = useCreateAttachmentCategoryMutation();

  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (open) {
      setFile(null);
      setMode('upload');
    }
  }, [open]);

  const handleCreateAttachment = async (params: IAttachmentItemEdit) => {
    if (!file) return null;

    const result = await addAttachment({ ...params, file, parentid: parentId ?? undefined });
    if (result && ('data' in result)) {
      onClose(false);
      navigate(`/safe/${result.data.id}`);
    }
  };

  const handleAddFiles = (files: File[]) => {
    setFile(files[0]);
    setMode('edit');
  };

  const handleCreateCategory = async (data: { name: string, color: string }) => {
    return createCategory(data).then(result => {
      return ('data' in result) ? result.data : null;
    }).catch(e => null);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      viewStyle="right"
      width="100%"
      maxWidth="570px"
    >
      {mode === 'upload' && (
        <FileDropzone
          title={t('adding_new_document')}
          onChange={handleAddFiles}
          maxFiles={1}
        />
      )}
      {mode === 'edit' && (
        <DocumentEdit
          fileName={file?.name.split('.')[0]}
          onSave={handleCreateAttachment}
          onCancel={() => setMode('upload')}
          isSaveLoading={isAddLoading}
          categories={{
            items: categories,
            onCreate: handleCreateCategory,
            isCreateLoading: isCreateCategoryLoading
          }}
        />
      )}
    </Modal>
  );
};

export default DocumentCreateModal;

import { FC, ReactElement } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { FORMA_FOLDER_ID_PACKS, FORMA_FOLDER_ID_STATIC, FORMA_FOLDER_ID_STRICT, FORMA_FOLDER_ID_TRASH } from 'data/constants';

interface DropItemProps {
  id: string | null,
  dropId: string,
  name: string,
  type: 'template' | 'folder',
  className: string,
  children?: ReactElement
}

const DropItem: FC<DropItemProps> = ({ id, dropId, name, type, className, children }) => {
  const isStaticFolder = !!id && [
    FORMA_FOLDER_ID_STATIC,
    FORMA_FOLDER_ID_PACKS,
    FORMA_FOLDER_ID_STRICT,
    FORMA_FOLDER_ID_TRASH
  ].includes(id);
  const { setNodeRef: dropRef } = useDroppable({
    id: dropId,
    data: { id, name, type },
    disabled: type !== 'folder' || isStaticFolder
  });

  return (
    <div className={className} ref={dropRef}>
      {children}
    </div>
  );
};

export default DropItem;

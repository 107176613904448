import { IFolderListChidlren, IFoldersList, IFolderListTreeChidlren, IFoldersSearchItem } from 'interfaces/folders.interface';
import { baseApi } from '../store';

interface ICreatedFolder {
  id: string,
  name: string,
  favorite?: boolean,
  parentid?: string,
  folderid?: string,
  translatedName: string,
  groups?: string[]
}

interface IDeletedFolder {
  id: string
}

interface ICreateFolderData {
  name: string,
  favorite?: boolean,
  parentid?: string | null,
  folderid?: string | null,
  parentSlug?: string | null
}
interface IChangeFolderData {
  id: string,
  action?: 'access',
  name: string,
  favorite?: boolean,
  parentid?: string | null,
  folderid?: string | null,
  parentSlug?: string | null
}
interface IDeleteFolderData {
  id: string,
  translatedName: string,
  parentSlug?: string | null
}


export const foldersApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getFoldersTree: builder.query<IFolderListTreeChidlren[], void | null>({
      query: () => ({
        url: `/folders/allcontent`,
        method: 'GET'
      }),
      providesTags: ['FoldersTree'],
    }),
    getFolderContent: builder.query<IFoldersList, string | void | null>({
      query: id => ({
        url: `/folders/content${id ? `/${id}` : ''}`,
        method: 'GET'
      }),
      providesTags: (result, error, id) => id ? [{ type: 'Folders', id: id }] : [{ type: 'Folders' }],
    }),
    getFolderContentByName: builder.query<IFoldersList, string>({
      query: slug => ({
        url: `/folders/contentByName/${slug}`,
        method: 'GET'
      }),
      providesTags: (result) => [{ type: 'Folders', id: result?.id }],
    }),
    searchFolders: builder.query<IFoldersSearchItem[], { name: string, limit: number, external?: boolean }>({
      query: data => ({
        url: '/folders/search',
        method: 'GET',
        params: data
      }),
      providesTags: (result, error, { name }) => [{ type: 'Search', id: name }],
    }),
    createFolder: builder.mutation<ICreatedFolder, ICreateFolderData>({
      query: data => ({
        url: '/folders',
        method: 'POST',
        body: data
      }),
      invalidatesTags: (result, error, data) => [
        { type: 'Folders', id: data.parentid ?? undefined },
        { type: 'Folders', id: data.folderid ?? undefined },
        'Search',
        'FoldersTree'
      ],
      async onQueryStarted(data, { dispatch, getState, queryFulfilled }) {
        const id = data.parentid ? data.parentid : data.folderid;
        const updateDraft = (data: ICreateFolderData, draft: IFoldersList) => {
          const rndId = (Math.random() * (99999 - 10000) + 10000) + '';
          let firstTemplate = draft.items.findIndex(({ type }) => type !== 'folder');
          if (firstTemplate < 0) firstTemplate = draft.items.length;
          // push new folder before first template
          draft.items.splice(firstTemplate, 0, { ...data, isCreated: true, id: rndId, translatedName: '', groups: [], type: 'folder' });
        };
        const updateDraftTree = (data: ICreateFolderData, draft: IFolderListTreeChidlren[]) => {
          const rndId = (Math.random() * (99999 - 10000) + 10000) + '';
          // todo: make this code for multilevel
          let firstTemplate = draft.findIndex(({ type }) => type !== 'folder');
          if (firstTemplate < 0) firstTemplate = draft.length;
          // push new folder before first template
          draft.splice(firstTemplate, 0, { ...data, isCreated: true, id: rndId, translatedName: '', groups: [], type: 'folder' });
        };

        const putResult1 = dispatch(
          foldersApi.util.updateQueryData('getFolderContent', id ?? undefined, (draft) => updateDraft(data, draft))
        );
        const putResult2 = data.parentSlug && dispatch(
          foldersApi.util.updateQueryData('getFolderContentByName', data.parentSlug, (draft) => updateDraft(data, draft))
        );
        const putResult3 = dispatch(
          foldersApi.util.updateQueryData('getFoldersTree', undefined, (draft) => updateDraftTree(data, draft))
        );

        try {
          await queryFulfilled;
        } catch {
          putResult1.undo();
          if (putResult2) putResult2.undo();
          putResult3.undo();
        }
      },
    }),
    changeFolder: builder.mutation<ICreatedFolder, IChangeFolderData>({
      query: ({ id, action, ...data }) => ({
        url: `/folders/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Folders', 'Search', 'Favourites', 'FoldersTree'],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const parentid = data.parentid ? data.parentid : data.folderid;
        const recursiveUpdate = (items: IFolderListTreeChidlren[], data: IChangeFolderData) => {
          for (let i = 0; i < items.length; i++) {
            if (items[i].id === data.id) {
              if (data.action === 'access') items[i].isAccessAdd = true;
              items[i] = { ...items[i], ...data };
              break;
            }
            const item = items[i];
            if (item.children) items[i].children = recursiveUpdate(item.children, data);
          }
          return items;
        };
        const updateDraft = (draft: IFoldersList, data: IChangeFolderData) => {
          for (let i = 0; i < draft.items.length; i++) {
            const item = draft.items[i];
            if (item.id === data.id) {
              if (data.action === 'access') item.isAccessAdd = true;
              draft.items[i] = { ...item, ...data };
              break;
            }
          }
        };

        const putResult1 = dispatch(
          foldersApi.util.updateQueryData('getFolderContent', parentid ?? undefined, (draft) => updateDraft(draft, data))
        );
        const putResult2 = data.parentSlug && dispatch(
          foldersApi.util.updateQueryData('getFolderContentByName', data.parentSlug, (draft) => updateDraft(draft, data))
        );
        const putResult3 = dispatch(
          foldersApi.util.updateQueryData('getFoldersTree', undefined, (draft) => recursiveUpdate(draft, data))
        );

        try {
          await queryFulfilled;
        } catch {
          putResult1.undo();
          if (putResult2) putResult2.undo();
          putResult3.undo();
        }
      },
    }),
    copyFolder: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/folders/${id}/copy`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Folders', 'Search', 'FoldersTree'],
      // todo: add optimistic copying
    }),
    moveFolder: builder.mutation<ICreatedFolder, IChangeFolderData>({
      query: ({ id, ...data }) => ({
        url: `/folders/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Folders', 'Search', 'FoldersTree'],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const recursiveRemove = (items: IFolderListTreeChidlren[], id: string) => {
          return items.filter(item => {
            if (item.children) item.children = recursiveRemove(item.children, id);
            return item.id !== id;
          });
        };
        // todo: add optimistic paste
        const putResult1 = dispatch(
          foldersApi.util.updateQueryData('getFolderContent', undefined, (draft) => {
            const index = draft.items.findIndex(item => item.id === data.id);
            draft.items.splice(index, 1);
          })
        );
        const putResult2 = dispatch(
          foldersApi.util.updateQueryData('getFoldersTree', undefined, (draft) => {
            draft = recursiveRemove(draft, data.id);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          putResult1.undo();
          putResult2.undo();
        }
      },
    }),
    removeFolder: builder.mutation<IDeletedFolder, IDeleteFolderData>({
      query: ({ id }) => ({
        url: `/folders/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Folders', 'Search', 'Favourites', 'FoldersTree'],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const removeItem = (data: IDeleteFolderData, draft: IFoldersList) => {
          const index = draft.items.findIndex(item => item.id === data.id);
          draft.items.splice(index, 1);
        };
        const recursiveRemove = (items: IFolderListTreeChidlren[], id: string) => {
          return items.filter(item => {
            if (item.children) item.children = recursiveRemove(item.children, id);
            return item.id !== id;
          });
        };

        const putResult1 = dispatch(
          foldersApi.util.updateQueryData('getFolderContent', undefined, (draft) => removeItem(data, draft))
        );
        const putResult2 = data.parentSlug && dispatch(
          foldersApi.util.updateQueryData('getFolderContentByName', data.parentSlug, (draft) => removeItem(data, draft))
        );
        const putResult3 = dispatch(
          foldersApi.util.updateQueryData('getFoldersTree', undefined, (draft) => recursiveRemove(draft, data.id))
        );

        try {
          await queryFulfilled;
        } catch {
          putResult1.undo();
          if (putResult2) putResult2.undo();
          putResult3.undo();
        }
      },
    }),

    getFavourites: builder.query<IFoldersList, void>({
      query: () => ({
        url: '/folders/favorites',
        method: 'GET'
      }),
      providesTags: ['Favourites'],
      transformResponse: (result: IFolderListChidlren[]) => ({ items: result, type: 'folder', path: [] })
    }),
    favouritesAddFolder: builder.mutation<ICreatedFolder, string>({
      query: id => ({
        url: `/folders/favorites/${id}`,
        method: 'PUT'
      }),
      invalidatesTags: ['Folders', 'Search', 'Favourites', 'FoldersTree'],
      // todo: add optimistic update
    }),
    favouritesRemoveFolder: builder.mutation<ICreatedFolder, string>({
      query: id => ({
        url: `/folders/favorites/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Folders', 'Search', 'Favourites', 'FoldersTree'],
      onQueryStarted(id, { dispatch, queryFulfilled }) {
        const putResult = dispatch(
          foldersApi.util.updateQueryData('getFavourites', undefined, (draft: IFoldersList) => {
            draft.items = draft.items.filter(item => item.id !== id);
          })
        );
        queryFulfilled.catch(putResult.undo);
      },
    }),
  })
});

export const {
  useGetFoldersTreeQuery,
  useGetFolderContentQuery,
  useLazyGetFolderContentQuery,
  useGetFolderContentByNameQuery,
  useSearchFoldersQuery,

  useCreateFolderMutation,
  useCopyFolderMutation,
  useMoveFolderMutation,
  useRemoveFolderMutation,
  useChangeFolderMutation,

  useGetFavouritesQuery,
  useFavouritesAddFolderMutation,
  useFavouritesRemoveFolderMutation,
} = foldersApi;

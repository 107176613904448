import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getBackFolder } from './model/getFormaFolders';
import FolderContent from './FolderContent';

import { useGetRemovedTemplatesQuery } from 'store/templates/templatesApi';

const Trash = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: removedTemplates, isLoading: isFoldersLoading, error: pageError }
    = useGetRemovedTemplatesQuery(undefined, { pollingInterval: 5000 });

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status === 404) navigate('/404', { replace: true });
    }
    // eslint-disable-next-line
  }, [pageError]);

  const backFolder = getBackFolder(t);

  return (
    <FolderContent
      type="trash"
      foldersItems={removedTemplates ? [backFolder, ...removedTemplates] : [backFolder]}
      foldersTree={removedTemplates ?? []}
      isLoading={isFoldersLoading}
    />
  );
};

export default Trash;

import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FolderContextMenu, TemplateContextMenu, TrashContextMenu } from '../../ExplorerContextMenu';
import DragItem from '../DragItem';
import DropItem from '../DropItem';
import { getListItemIcon } from '../utils';

import { IUserGroupsItem } from 'interfaces/users.interface';
import { IFoldersParent, IFolderListTreeChidlren } from 'interfaces/folders.interface';
import {
  IFoldersActionsProps,
  IFoldersEditingProps,
  IFoldersOptionsStateParams
} from 'interfaces/fileExplorer.interface';

import styles from './explorer-list-item.module.css';

interface ExplorerListItemProps {
  item: IFolderListTreeChidlren,
  parent?: IFoldersParent,
  updated?: string | null,
  selected?: string[],
  over?: string,
  hideOptions?: boolean,
  onToggleMenu: (id: string | null) => void,
  onToggleModal: (open: boolean) => void,
  isModalOpen?: boolean,
  onHoverSubmenuItem: (id: string | null) => void,
  editing?: IFoldersEditingProps,
  actions: IFoldersActionsProps,
  userGroups?: IUserGroupsItem[],
  optionsState?: IFoldersOptionsStateParams,
  showEmptyText?: boolean,
  className?: string,
  isInGroup?: boolean
}

const ExplorerListItem: FC<ExplorerListItemProps> = ({
  item, updated, selected, over, hideOptions, onToggleMenu, onToggleModal, isModalOpen, onHoverSubmenuItem, parent,
  editing, actions, userGroups, optionsState, className, showEmptyText, isInGroup
}) => {
  const { pathname } = useLocation();
  const openedFolders = pathname.split('/');
  const { t } = useTranslation();
  // const timer = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>((item.type === 'folder' && openedFolders?.includes(item.translatedName)) || false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isOptionsOpen, setOptionsOpen] = useState<boolean>(false);

  const isRemoved = !!item.removed;
  const isFolder = item.type === 'folder';

  useEffect(() => {
    if (item.type === 'folder' && openedFolders && openedFolders?.includes(item.translatedName)) setIsOpen(true);
    else if (isOpen) setIsOpen(false);
    // eslint-disable-next-line
  }, [pathname]);

  // let timer: NodeJS.Timeout|null = null;

  const handleClick = (e: React.MouseEvent) => {
    if (actions.onClickItem)
      actions.onClickItem(item, parent && { id: parent.id, name: parent.name, translatedName: parent.translatedName }, isInGroup);

    if (item.type === 'folder') {
      setIsOpen(prev => !prev);
    }
  };

  const icon = getListItemIcon(item, isHover);

  return (
    <DropItem
      id={item.id}
      dropId={item.id}
      name={item.name}
      type={item.type}
      className={styles.container}
    >
      <>
        <DragItem
          id={item.id}
          folderId={parent?.id ?? null}
          name={item.name}
          type={item.type}
          isUpdated={updated === item.id}
          isMenuOpen={optionsState?.itemId === item.id}
          isModalOpen={isModalOpen}
        >
          <div
            id={`file_explorer_template_${item.id}`}
            className={classNames(
              styles.root,
              selected?.includes(item.id) && styles.selected,
              optionsState?.moveToItemId === item.id && styles.moveHover,
              isOpen && styles.open,
              over === item.id && styles.moveHover,
              `file-explorer-${item.type}`,
              className
            )}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            {(item.type === 'folder' && item.children?.length) && (
              <div
                className={styles.arrowButton}
                onClick={handleClick}
                onContextMenu={e => { e.preventDefault(); setOptionsOpen(true); }}
              >
                <div className={styles.count}>{item.children?.length ?? 0}</div>
                <ReactSVG src="/icons/arrow-right-thin.svg" className={styles.arrowIcon} wrapper="span" />
              </div>
            )}

            <div
              className={styles.content}
              onClick={handleClick}
              onContextMenu={e => { e.preventDefault(); setOptionsOpen(true); }}
            >
              <img
                className={styles.icon}
                src={icon}
                alt=""
              />
              <div className={styles.name}>
                {item.name}
                {(showEmptyText && item.type === 'folder' && !item.children?.length && !item.external) && (
                  <span className={styles.nameEmpty}>{t('empty_folder')}</span>
                )}
              </div>
            </div>

            <div className={styles.buttons}>
              {item.favorite && (
                <div className={styles.fave}>
                  <img src="/icons/favorite.svg" className={styles.faveIcon} alt="fave" />
                </div>
                // actions?.onFavouritesToggle ? (
                //   <div className={styles.fave} onClick={(e) => {
                //     e.stopPropagation();
                //     if (actions?.onFavouritesToggle) actions.onFavouritesToggle({ id: item.id, type: item.type, checked: false });
                //   }}>
                //     <Tooltip control={<img src="/icons/favorite.svg" className={styles.faveIcon} alt="fave" />}>
                //       {t('remove_from_favourites')}
                //     </Tooltip>
                //   </div>
                // ) : (
                //   <div className={styles.fave}>
                //     <img src="/icons/favorite.svg" className={styles.faveIcon} alt="fave" />
                //   </div>
                // )
              )}
              {(!hideOptions && editing) &&
                <div className={styles.optionsButton}>
                  {isRemoved ? (
                    <TrashContextMenu
                      open={isOptionsOpen}
                      position={['bottom right', 'top right']}
                      item={item}
                      onToggleMenu={(id: string | null) => { setOptionsOpen(!!id); onToggleMenu(id); }}
                      editing={editing}
                    />
                  ) : (
                    isFolder ? (
                      <FolderContextMenu
                        open={isOptionsOpen}
                        position={['bottom right', 'top right']}
                        item={item}
                        items={item.children ?? []}
                        parent={parent}
                        onToggleMenu={(id: string | null) => { setOptionsOpen(!!id); onToggleMenu(id); }}
                        onToggleModal={onToggleModal}
                        onHoverSubmenuItem={onHoverSubmenuItem}
                        editing={{ ...editing, onCopy: undefined, onMove: undefined }}
                        actions={actions}
                        userGroups={userGroups}
                      />
                    ) : (
                      <TemplateContextMenu
                        open={isOptionsOpen}
                        position={['bottom right', 'top right']}
                        item={item}
                        items={item.children ?? []}
                        parent={parent}
                        onToggleMenu={(id: string | null) => { setOptionsOpen(!!id); onToggleMenu(id); }}
                        onToggleModal={onToggleModal}
                        onHoverSubmenuItem={onHoverSubmenuItem}
                        editing={{ ...editing, onCopy: undefined, onMove: undefined }}
                        actions={actions}
                      />
                    )
                  )}
                </div>
              }
            </div>
          </div>
        </DragItem>

        {(isOpen && item.children) && (
          <div className={styles.children}>
            {item.children.map(subitem => (
              <ExplorerListItem
                item={subitem}
                parent={{
                  id: item.id,
                  name: item.name,
                  translatedName: item.translatedName,
                  parentId: parent?.id
                }}
                updated={updated}
                selected={selected}
                over={over}
                hideOptions={hideOptions}
                onToggleMenu={onToggleMenu}
                onToggleModal={onToggleModal}
                isModalOpen={isModalOpen}
                onHoverSubmenuItem={onHoverSubmenuItem}
                editing={editing}
                actions={actions}
                userGroups={userGroups}
                optionsState={optionsState}
                key={subitem.id}
              />
            ))}
          </div>
        )}
      </>
    </DropItem>
  );
};

export default ExplorerListItem;

import { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import { ButtonsContainer, Button, LoadingButton, Modal, Input, PhoneInput, phonemasks, ThemeContext } from '@forma/forma-ui-kit';
import LangSwitch from 'components/LangSwitch';
import UserGroups from 'views/profile/UserGroups';
import OrganizationsList from 'views/profile/OrganizationsList';
import ProfileCard from 'views/profile/ProfileCard';
import ProfileLimits from 'views/profile/ProfileLimits';
import { hasPermission, PERMISSIONS } from 'helpers/permissions';
import getValidationSchema from 'data/validationSchema';
import ChangePasswordModal from './ChangePasswordModal';

import { baseApi } from 'store/store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { logoutUser } from 'store/auth/authSlice';
import { useGetGroupsListQuery } from 'store/groups/groupsApi';
import { addNotification } from 'store/notifications/notificationsSlice';
import { useSetOnboardingMutation, useSetProfileDataMutation, useSetUserGroupsMutation } from 'store/user/userApi';
import { selectOnboarding, selectUserData, selectUserPermissions, setUserData } from 'store/user/userSlice';
import { useGetExternalFillOwnersQuery, useRemoveExternalFillOwnerMutation } from 'store/externalfill/externalfillApi';

import styles from './Profile.module.css';

interface FormValues {
  name: string,
  login: string
  phone: string
}

const fields: Array<keyof FormValues> = ['name', 'login', 'phone'];

const landingUrl = process.env.REACT_APP_LANDING_URL;

const Profile = () => {
  const { t, i18n } = useTranslation();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { lang } = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const userPermissions = useAppSelector(selectUserPermissions);
  const canChangeUsers = hasPermission(userPermissions ?? [], PERMISSIONS.USERS.EDIT);
  const canReadUserGroups = hasPermission(userPermissions ?? [], PERMISSIONS.USERS.VIEW);
  const user = useAppSelector(selectUserData);
  const [isShowChangePass, setShowChangePass] = useState<boolean>(false);
  const [selectedOwnerId, setSelectedOwnerId] = useState<string | null>(null);
  const [newUserGroups, setNewUserGroups] = useState<{ [key: string]: boolean } | null>(null);

  const [saveUserGroups, { isLoading: isSaveGoupsLoading }] = useSetUserGroupsMutation();
  const [saveUserData, { isLoading: isSaveDataLoading }] = useSetProfileDataMutation();
  const { data: owners } = useGetExternalFillOwnersQuery();
  const { data: userGroups } = useGetGroupsListQuery(undefined, { skip: !canReadUserGroups });
  const [removeExternalFillOwner] = useRemoveExternalFillOwnerMutation();

  const onboarding = useAppSelector(selectOnboarding);
  const [setOnboarding] = useSetOnboardingMutation();

  const { register, handleSubmit, control, setValue, trigger, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: user && yupResolver(getValidationSchema(fields))
  });
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (newUserGroups && user) saveUserGroups({ id: user.id, groupids: Object.keys(newUserGroups).filter(key => !!newUserGroups[key]) });
    const result = await saveUserData(data);

    if ('data' in result && result.data) {
      dispatch(addNotification({ content: t('data_successfully_changed'), type: 'SUCCESS' }));
    }
  };

  useEffect(() => {
    if (!user) return;
    fields.forEach(key => {
      if (key === 'phone') {
        // todo: change to universal format phone
        const x = (user.phone && user.phone.length >= 11) && user.phone.replace(/\D/g, '').match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
        setValue(key, x ? `+${x[1]}(${x[2]})-${x[3]}-${x[4]}-${x[5]}` : '');
      } else {
        setValue(key, user[key]);
      }
    });
    setTimeout(() => trigger(), 300);
    // eslint-disable-next-line
  }, [user]);

  const handleClickLogout = () => {
    dispatch(logoutUser());
    dispatch(setUserData(null));
    dispatch(baseApi.util.resetApiState());

    navigate('/login');
  };

  return (
    <AdminLayout title={t('my_account')}>
      <PageTitle>{t('site_name') + ' – ' + t('my_account')}</PageTitle>

      <div className={styles.cards}>
        <ProfileCard className={styles.cardUser} title={t('personal_data')}>
          <form id="profile_form" className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            {!user ? (
              [1, 2, 3].map(key => (
                <div className={classNames(styles.input, 'skeleton-loader')} key={key} />
              ))
            ) : (
              fields.map(key => (
                <Controller
                  control={control}
                  name={key}
                  render={({ field: { value = '', ...rest } }) => {
                    if (key === 'phone') return (
                      <PhoneInput
                        id={key}
                        label={t(`fields.${key}`)}
                        placeholder={t(`placeholders.${key}`)}
                        viewStyle="secondary"
                        containerClass={styles.inputContainer}
                        labelClass={styles.label}
                        className={classNames(styles.input, styles.phone)}
                        error={!!errors?.[key]?.message}
                        errorMessage={errors?.[key]?.message && t('errors.' + errors?.[key]?.message)}
                        {...rest}
                        {...register}
                        value={value}
                        defaultMaskCode={(i18n.resolvedLanguage === 'en' || !i18n.resolvedLanguage) ? 'US' : i18n.resolvedLanguage.toUpperCase()}
                        masks={phonemasks}
                      />
                    );
                    else return (
                      <Input
                        id={key}
                        placeholder={t(`placeholders.${key}`)}
                        viewStyle="secondary"
                        inputMode={key === 'login' ? 'email' : 'text'}
                        className={styles.input}
                        {...rest}
                        {...register}
                        value={value}
                        readOnly={key === 'login'}

                        meta={{
                          containerClass: styles.inputContainer,
                          label: t(`fields.${key}`),
                          labelClass: styles.label,
                          error: !!errors?.[key]?.message,
                          errorMessage: errors?.[key]?.message && t('errors.' + errors?.[key]?.message),
                        }}
                      />
                    );
                  }}
                  key={key}
                />
              ))
            )}
          </form>
        </ProfileCard>

        <ProfileCard className={styles.cardLimits}>
          <ProfileLimits user={user} />
        </ProfileCard>

        <ProfileCard
          className={styles.cardGroups}
          title={
            <div className={styles.groupsHeader}>
              {t('user_groups')}
              <Button
                as={Link}
                viewStyle="text"
                className={styles.groupsButton}
                icon={<ReactSVG src="/icons/edit.svg" />}
                iconClassName={styles.groupsButtonIcon}
                to="/user-groups"
                disabled={!canChangeUsers}
              />
            </div>
          }
        >
          {user && (
            <UserGroups
              groups={userGroups ?? user.usergroups}
              selected={user.usergroups}
              onChange={setNewUserGroups}
              disabled={!canReadUserGroups}
            />
          )}
        </ProfileCard>

        <ProfileCard className={styles.cardLang} title={t('language')}>
          <LangSwitch />
        </ProfileCard>

        <ProfileCard className={styles.cardEntities} title={t('data_for_sending_external_fill')}>
          <div className={styles.description}>
            {t('data_for_sending_external_fill_description')}<br />
            <a className={styles.link} href={`${landingUrl}/${lang}/privacy/`} target="_blank" rel="noreferrer">{t('learn_about_privacy')}</a>
          </div>
          {!!owners?.length && (
            <div className={styles.organizations}>
              <OrganizationsList
                items={owners}
                selected={selectedOwnerId}
                onSelect={setSelectedOwnerId}
                onRemove={removeExternalFillOwner}
                showInn
                showAddress
                showButtons
              />
            </div>
          )}
        </ProfileCard>

        <ButtonsContainer className={styles.buttons}>
          {/* <Button viewStyle="secondary" shadow={true} disabled={true}>{t('change_email')}</Button> */}
          <Button
            viewStyle="secondary"
            className={styles.button}
            onClick={() => setShowChangePass(true)}
          >
            {t('change_password')}
          </Button>
          <Button
            viewStyle="secondary"
            className={styles.button}
            onClick={() => onboarding && setOnboarding({
              ...Object.keys(onboarding).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
              templates_sent: onboarding.templates_sent
            })}
          >
            {t('reset_onboarding')}
          </Button>
          <Button
            viewStyle="secondary"
            className={styles.button}
            onClick={handleClickLogout}
          >
            {t('sign_out_from_account')}
          </Button>
          <LoadingButton
            viewStyle="primary"
            className={styles.button}
            type="submit"
            form="profile_form"
            isLoading={isSaveGoupsLoading || isSaveDataLoading}
            disabled={!isValid}
          >
            {t('save')}
          </LoadingButton>
        </ButtonsContainer>
      </div>

      <ChangePasswordModal open={isShowChangePass} onClose={() => setShowChangePass(false)} />
      <Modal
        size="small"
        open={hash?.slice(1) === 'email-confirm-success'}
        title={t('account.approve_success_title')}
        buttons={[
          { children: t('continue'), viewStyle: 'primary', shadow: true }
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          {t('account.approve_success_subtitle')}
        </div>
      </Modal>
      <Modal
        size="small"
        open={hash?.slice(1) === 'email-confirm-error'}
        title={t('account.approve_error_title')}
        buttons={[
          { children: t('continue'), viewStyle: 'primary', shadow: true }
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          {t('account.approve_error_subtitle')}
        </div>
      </Modal>
    </AdminLayout>
  );
};

export default Profile;

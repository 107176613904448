import {
  type IProductsItem,
  type IProductsItemUpdate,
  type IProductsItemCreate,
  IProductsCategoriesItem,
  IProductPropertiesItem
} from 'interfaces/products.interface';
import { baseApi } from '../store';

export interface IRenderProductsData extends IProductsItemUpdate {
  count: number,
}

export const productsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getProducts: builder.query<{ count: number, products: IProductsItem[] }, { from: number, to: number }>({
      query: data => ({
        url: '/products',
        method: 'GET',
        params: data
      }),
      providesTags: ['Products'],
    }),
    addProduct: builder.mutation<IProductsItem, IProductsItemCreate>({
      query: data => ({
        url: '/products',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Products', 'ProductsCategories'],
    }),
    importProducts: builder.mutation<IProductsItem[], IProductsItemCreate[]>({
      query: data => ({
        url: '/products/batch',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Products', 'ProductsCategories'],
    }),
    getProductById: builder.query<IProductsItem, string>({
      query: id => ({
        url: `/products/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, id) => [{ type: 'Product', id: id }],
    }),
    saveProduct: builder.mutation<IProductsItem, IProductsItemUpdate>({
      query: ({ id, ...data }) => ({
        url: `/products/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, data) => [{ type: 'Product', id: data.id }, 'Products', 'ProductsCategories'],
    }),
    removeProduct: builder.mutation<{ id: string }, string>({
      query: id => ({
        url: `/products/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Products'],
    }),

    getProductsCategories: builder.query<IProductsCategoriesItem[], void>({
      query: () => ({
        url: '/products/categories',
        method: 'GET'
      }),
      providesTags: ['ProductsCategories'],
    }),
    addProductsCategory: builder.mutation<IProductsCategoriesItem, { name: string }>({
      query: data => ({
        url: `/products/categories`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['ProductsCategories'],
    }),
    updateProductsCategory: builder.mutation<IProductsCategoriesItem, { id: string, name: string }>({
      query: ({ id, ...data }) => ({
        url: `/products/categories/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['ProductsCategories'],
    }),
    removeProductsCategory: builder.mutation<IProductsCategoriesItem, string>({
      query: id => ({
        url: `/products/categories/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ProductsCategories'],
    }),

    getProductsProperties: builder.query<IProductPropertiesItem[], void>({
      query: () => ({
        url: '/productproperties',
        method: 'GET'
      }),
      providesTags: ['ProductsProperties'],
    }),
    addProductsProperty: builder.mutation<IProductPropertiesItem, { name: string, type: string }>({
      query: data => ({
        url: `/productproperties`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['ProductsProperties'],
    }),
    updateProductsProperty: builder.mutation<IProductPropertiesItem, { id: string, name: string, type: string }>({
      query: ({ id, ...data }) => ({
        url: `/productproperties/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['ProductsProperties'],
    }),
    removeProductsProperty: builder.mutation<IProductPropertiesItem, string>({
      query: id => ({
        url: `/productproperties/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ProductsProperties'],
    }),
  })
});

export const {
  useGetProductsQuery,
  useAddProductMutation,
  useImportProductsMutation,
  useGetProductByIdQuery,
  useSaveProductMutation,
  useRemoveProductMutation,

  useGetProductsCategoriesQuery,
  useAddProductsCategoryMutation,
  useUpdateProductsCategoryMutation,
  useRemoveProductsCategoryMutation,

  useGetProductsPropertiesQuery,
  useAddProductsPropertyMutation,
  useUpdateProductsPropertyMutation,
  useRemoveProductsPropertyMutation,
} = productsApi;

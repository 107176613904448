import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import UserInvite, { UserInviteSkelet } from 'views/profile/UserInvite';
import UsersMenu from 'views/profile/UsersMenu';

import { useInviteUserMutation } from 'store/user/userApi';
import { useGetGroupsListQuery } from 'store/groups/groupsApi';
import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';

const UserInvitePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: currentTariff } = useGetCurrentTariffQuery();
  const { data: groups } = useGetGroupsListQuery();
  const [saveUserData, { isLoading: isSendLoading }] = useInviteUserMutation();

  const onSubmit = async (data: { users: { name: string, login: string, groupids: string }[] }) => {
    if (!data.users) return null;

    const addResult = await Promise.all(data.users.map(async (item) => {
      const result = await saveUserData({ ...item, groupids: item.groupids.split(','), method: 'mail' });
      if ('data' in result && result.data) return ({ id: result.data.id, name: item.name });
      return null;
    }));

    const addedUsers = addResult?.filter(item => !!item);
    if (addResult && addedUsers.length) navigate('/invite-success', { state: { users: addedUsers } });
  };

  return (
    <AdminLayout
      title={t('users')}
      breadcrumbs={{
        items: [
          { name: t('settings'), to: '/profile', as: Link },
          { name: t('my_users'), to: '/users', as: Link },
          { name: t('send_invite') }
        ]
      }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('send_invite')}</PageTitle>

      <UsersMenu />
      {(groups && currentTariff) ? (
        <UserInvite
          groups={groups}
          onSubmit={onSubmit}
          isSendLoading={isSendLoading}
          usersNow={currentTariff?.currentUsersCount ?? 0}
          usersMax={currentTariff?.usersCount ?? 0}
          showTitle
        />
      ) : (
        <UserInviteSkelet />
      )}
    </AdminLayout>
  );
};

export default UserInvitePage;

import { ITemplateBlocksTree, ITemplateRenderData, ITemplateSide } from '@forma/forma-ui-kit';
import {
  IStrictRenderItem,
  IStrictTemplateItem,
} from 'interfaces/strictTemplates.interface';
import { baseApi } from 'store/store';

export const strictTemplatesApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['StrictTemplates', 'StrictRenders'] })
  .injectEndpoints({
    endpoints: builder => ({
      getStrictTemplates: builder.query<IStrictTemplateItem[], void>({
        query: () => ({
          url: '/stricttemplates/',
          method: 'GET',
        }),
        providesTags: ['StrictTemplates']
      }),
      getStrictTemplateById: builder.query<IStrictTemplateItem, string>({
        query: (id) => ({
          url: `/stricttemplates/${id}`,
          method: 'GET',
        }),
        providesTags: (result, error, id) => [{ type: 'StrictTemplates', id }],
      }),
      getStrictTemplateSides: builder.query<{ sides: ITemplateSide[], blocksTree?: ITemplateBlocksTree }, string>({
        query: (id) => ({
          url: `/stricttemplates/${id}/sides`,
          method: 'GET',
        }),
        providesTags: (result, error, id) => [{ type: 'StrictTemplates', id, name: 'sides' }],
      }),
      renderStrictTemplate: builder.mutation<string, ITemplateRenderData>({
        query: ({ id, ...data }) => ({
          url: `/stricttemplates/${id}/render`,
          method: 'POST',
          body: data,
          responseHandler: async (response: Response) => {
            if (!response.ok) return response.text().then(text => JSON.parse(text));
            return response.blob().then(blob => URL.createObjectURL(blob));
          },
        }),
        invalidatesTags: ['Documents']
      }),
      reRenderStrictTemplate: builder.mutation<string, ITemplateRenderData & { renderId: string }>({
        query: ({ id, renderId, ...data }) => ({
          url: `/stricttemplates/${id}/render/${renderId}`,
          method: 'POST',
          body: data,
          responseHandler: async (response: Response) => {
            if (!response.ok) return response.text().then(text => JSON.parse(text));
            return response.blob().then(blob => URL.createObjectURL(blob));
          },
        }),
        invalidatesTags: ['Documents']
      }),
      downloadRenderedStrictTemplate: builder.query<string, { id: string, renderId: string }>({
        query: ({ id, renderId }) => ({
          url: `/stricttemplates/${id}/render/${renderId}`,
          method: 'GET',
          responseHandler: async (response: Response) => {
            if (!response.ok) return response.text().then(text => JSON.parse(text));
            return response.blob().then(blob => URL.createObjectURL(blob));
          },
        }),
        providesTags: (result, error, { id, renderId }) => [{ type: 'Documents', id, renderId }],
      }),

      getStrictRenders: builder.query<IStrictRenderItem[], void>({
        query: () => ({
          url: '/strictrenders/',
          method: 'GET',
        }),
        providesTags: ['StrictRenders']
      }),
      getStrictRenderById: builder.query<IStrictRenderItem, string>({
        query: (id) => ({
          url: `/strictrenders/${id}`,
          method: 'GET',
        }),
        providesTags: (result, error, id) => [{ type: 'StrictRenders', id }],
      }),
      renameStrictDocument: builder.mutation<{ id: string }, { id: string, name: string }>({
        query: ({ id, ...data }) => ({
          url: `/strictrenders/${id}/name`,
          method: 'PUT',
          body: data
        }),
        invalidatesTags: ['Documents']
      }),
      removeStrictDocument: builder.mutation<{ id: string }, string>({
        query: id => ({
          url: `/strictrenders/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Documents']
      }),
    })
  });

export const {
  useGetStrictTemplatesQuery,
  useGetStrictTemplateByIdQuery,
  useGetStrictTemplateSidesQuery,
  useRenderStrictTemplateMutation,
  useReRenderStrictTemplateMutation,
  useLazyDownloadRenderedStrictTemplateQuery,

  useGetStrictRendersQuery,
  useGetStrictRenderByIdQuery,
  useRenameStrictDocumentMutation,
  useRemoveStrictDocumentMutation
} = strictTemplatesApi;

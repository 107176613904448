import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import { LoadingIndicator } from '@forma/forma-ui-kit';

import styles from './LoadingPage.module.css';

const AccessDenied = () => {
  const { t } = useTranslation();

  return (
    <AdminLayout title=" ">
      <PageTitle>{t('site_name')}</PageTitle>
      <div className={styles.loader}>
        <LoadingIndicator color="var(--primary-color)" />
      </div>
    </AdminLayout>
  );
};

export default AccessDenied;

import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { PaginationWrapper, PaginationProps, Button } from '@forma/forma-ui-kit';
import NoItemsVideo from 'views/onboards/NoItemsVideo';
import { hasPermission, PERMISSIONS } from 'helpers/permissions';
import ContragentsHeader from './ContragentsHeader';
import ContragentsList from './ContragentsList';

import { useAppSelector } from 'store/hooks';
import { selectUserPermissions } from 'store/user/userSlice';
import {
  ICounteragentsItem,
  ICounteragentType,
  IDadataItem,
  TCounteragentSorting
} from 'interfaces/counteragents.interface';

import styles from './contragents.module.css';

interface ContragentsProps {
  items?: ICounteragentsItem[],
  outside?: IDadataItem[],
  type: ICounteragentType,
  pagination: PaginationProps,
  onClickImport: () => void,
  onRemove: (id: string) => void,
  onClickAdd: () => void,
  onAdd: (type: ICounteragentType, values: { [key: string]: string }) => void,
  search: {
    onChange: (value: string) => void,
    onChangeSorting: (value: TCounteragentSorting) => void,
    sorting?: string
  },
}

const staticUrl = process.env.REACT_APP_STATIC_URL;

const Contragents: FC<ContragentsProps> = ({
  items, outside, type, search, pagination, onClickImport, onRemove, onClickAdd, onAdd
}) => {
  const { t } = useTranslation();
  const userPermissions = useAppSelector(selectUserPermissions);
  const canCreateContragent = hasPermission(userPermissions ?? [], PERMISSIONS.COUNTERAGENTS.CREATE);

  const isEmpty = items && pagination.count === 0 && !outside?.length && !pagination.isLoading;
  const isShowAdd = pagination.count < 5;
  const paginationCount = pagination?.count || outside?.length || 0;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <ContragentsHeader
          type={type}
          search={search}
          onClickImport={onClickImport}
        />
      </div>
      <div className={styles.content}>
        {isEmpty ? (
          <div>
            <NoItemsVideo
              title={t('you_not_nave_contragents')}
              button={
                <Button
                  icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
                  onClick={onClickAdd}
                  disabled={!canCreateContragent}
                >
                  {t('add_contragent')}
                </Button>
              }
              src={`${staticUrl}/videos/onboarding/counteragents_no_items.mp4`}
            />
          </div>
        ) : (
          <PaginationWrapper {...pagination} count={paginationCount}>
            <ContragentsList
              type={type}
              items={items}
              outside={outside}
              onRemove={onRemove}
              onAdd={onAdd}
            />
            {isShowAdd && (
              <div className={styles.buttons}>
                <Button
                  className={styles.noItemsButton}
                  icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
                  onClick={onClickAdd}
                  disabled={!canCreateContragent}
                >
                  {t('add_contragent')}
                </Button>
              </div>
            )}
          </PaginationWrapper>
        )}
      </div>
    </div>
  );
};

export default Contragents;

import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import FilesEditor from 'views/FilesEditor';
import { NotFound } from 'pages/Common';
import { Modal } from '@forma/forma-ui-kit';

import { IFolderPathItem } from 'interfaces/folders.interface';
import {
  ITemplateContentData,
  useChangeTemplateMutation,
  useGetTemplateByNameQuery,
  useGetTemplateContentQuery,
  useSaveTemplateMutation,
} from 'store/templates/templatesApi';
import {
  useGetTemplateSectionsGroupsQuery,
  useGetTemplateSectionsVariablesQuery,
  useGetUserVariablesQuery,
} from 'store/schemas/schemasApi';
import { selectOnboarding } from 'store/user/userSlice';
import { useGetProductsQuery } from 'store/products/productsApi';
import { setOnboardingModal } from 'store/common/commonSlice';

import styles from './Editor.module.css';

const Editor = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onboarding = useSelector(selectOnboarding);

  const [templateName, setTemplateName] = useState('');
  const [isShowModalSuccess, setShowModalSuccess] = useState(false);

  const { data: template, isLoading: isPageLoading, error } = useGetTemplateByNameQuery(slug ?? '');
  const { data: templateContent } = useGetTemplateContentQuery(template?.id ?? '', { skip: !template });
  const { data: availableVariables } = useGetTemplateSectionsVariablesQuery();
  const { data: variablesGroups } = useGetTemplateSectionsGroupsQuery();
  const { data: userVariables } = useGetUserVariablesQuery();
  const { data: products } = useGetProductsQuery({ from: 0, to: 3 });

  const [changeTemplate, { isLoading: isChangeLoading }] = useChangeTemplateMutation();
  const [saveTemplate, { isLoading: isSaveLoading, isSuccess: isSaveSuccess }] = useSaveTemplateMutation();

  const pageError = error as FetchBaseQueryError;

  useEffect(() => {
    if (pageError?.status === 402) navigate('/subscribe');
    // eslint-disable-next-line
  }, [pageError]);

  const handleSaveContent = async (data: ITemplateContentData) => {
    if (!template) return false;
    if (templateName !== template.name) await changeTemplate({ id: template.id, name: templateName, folderid: template?.folderid ?? null });
    const result = await saveTemplate(data);

    return !!(result && 'data' in result);
  };

  useEffect(() => {
    if (template && onboarding) {
      if (state?.imported && !onboarding.edit_template_uploaded_tooltip) dispatch(setOnboardingModal('edit_template_uploaded_tooltip'));
      else if (!onboarding.edit_template_modal) dispatch(setOnboardingModal('edit_template_modal'));
    }
    // eslint-disable-next-line
  }, [template, onboarding, state]);

  useEffect(() => {
    if (template && template.name) setTemplateName(template.name);
    // eslint-disable-next-line
  }, [template]);

  useEffect(() => {
    if (isSaveSuccess && !isShowModalSuccess) setShowModalSuccess(true);
    // eslint-disable-next-line
  }, [isSaveSuccess]);

  // eslint-disable-next-line
  const breadcrumbs = useMemo(() => ([{ name: t('my_templates'), to: '/templates', as: Link }]), []);
  const onBreadcrumbsChange = useCallback((value: string) => setTemplateName(value), []);

  if (pageError?.status === 404) return (
    <NotFound />
  );

  return (
    <AdminLayout
      title={t('my_templates')}
      breadcrumbs={{
        items: breadcrumbs,
        value: templateName,
        placeholder: !isPageLoading ? t('set_template_name') : undefined,
        onChange: onBreadcrumbsChange
      }}
    // showFaqButton
    >
      <PageTitle>{t('site_name') + ' – ' + t('my_templates')}</PageTitle>
      <FilesEditor
        template={templateContent}
        id={template?.id ?? ''}
        onSave={handleSaveContent}
        variablesGroups={variablesGroups}
        availableVariables={availableVariables}
        userVariables={userVariables}
        isSaveLoading={isSaveLoading || isChangeLoading}
        products={products?.products}
        onboarding={onboarding}
      />

      <Modal
        size="medium"
        open={isShowModalSuccess}
        onClose={() => setShowModalSuccess(false)}
        buttons={[
          {
            viewStyle: 'primary',
            children: t('continue_editing')
          }, {
            id: 'go_to_templates_button',
            viewStyle: 'tertiary',
            children: t('go_to_templates'),
            onClick: () => navigate('/templates' + template?.path?.reduce((prev: string, { translatedName }: IFolderPathItem) => prev + `/${translatedName}`, '')),
            'data-testid': 'go_to_templates_button'
          }
        ]}
      >
        <div className={styles.modalSuccess}>
          <h2>{t('template_success_saved')}</h2>
        </div>
      </Modal>
    </AdminLayout>
  );
};

export default Editor;

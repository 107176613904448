import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AdminLayout } from 'components/Layouts';
import PageTitle from 'components/PageTitle';
import StrictTemplates from 'views/strictTemplates/StrictTemplates';

import { useGetStrictTemplatesQuery } from 'store/strictTemplates/strictTemplatesApi';

const Templates = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: templates, isLoading: isTemplatesLoading } = useGetStrictTemplatesQuery();

  return (
    <AdminLayout
      title={t('my_templates')}
      breadcrumbs={{ items: [{ as: Link, name: t('my_templates'), to: '/templates' }, { name: t('forma_templates') }] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('my_templates')}</PageTitle>
      <StrictTemplates
        items={templates}
        isLoading={isTemplatesLoading}
        actions={{
          onClick: (id: string) => navigate(`/strict-templates/${id}`),
          onDoubleClick: (id: string) => navigate(`/strict-templates/${id}`),
        }}

      />
    </AdminLayout>
  );
};

export default Templates;

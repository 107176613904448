import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@forma/forma-ui-kit';
import formatPrice from 'helpers/formatPrice';

import styles from './additional-setting.module.css';

const prices = [
  { name: 'Простой', price: 3000 },
  { name: 'Средний', price: 6000 },
  { name: 'Сложный', price: 15000 }
];

const AdditionalSetting = () => {
  const { t } = useTranslation();
  const { currencies } = useContext(ThemeContext);
  const currency = currencies.ru;

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.title}>{t('additional_setting_title')}</div>
        <div className={styles.description}>
          {t('additional_setting_description')}
        </div>
      </div>
      <div className={styles.items}>
        {prices.map(({ name, price }) => (
          <div className={styles.item} key={name}>
            <div className={styles.name}>{name}</div>
            <div className={styles.price}>{formatPrice(price, currency)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdditionalSetting;

import { FC, useEffect, useState, KeyboardEvent, ClipboardEvent, ChangeEvent } from 'react';
import { Input, LoadingIndicator } from '@forma/forma-ui-kit';

import styles from './code-input.module.css';

interface CodeInputProps {
  viewStyle?: 'primary' | 'secondary',
  isLoading?: boolean,
  onComplete: (code: string) => void,
  isError?: boolean
}

const CODE_LENGTH = 6;

const CodeInput: FC<CodeInputProps> = ({ isLoading, onComplete, isError, viewStyle }) => {
  const [code, setCode] = useState<string[]>([...Array(CODE_LENGTH).keys()].map(() => ''));

  useEffect(() => {
    if (code.length === CODE_LENGTH && code.every(symbol => !!symbol)) onComplete(code.join(''));
    // eslint-disable-next-line
  }, [code]);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    const target = e.target as HTMLInputElement;
    const hasValue = !!target.value;

    if (e.key === 'Backspace' && index > 0) {
      setTimeout(() => {
        if (!hasValue) document.getElementById(`code_${index - 1}`)?.focus();
      }, 100);
    }
    if (e.key === 'ArrowLeft' && index > 0) {
      if (!hasValue || target.selectionStart === 0) document.getElementById(`code_${index - 1}`)?.focus();
    }
    if (e.key === 'ArrowRight' && index < CODE_LENGTH - 1) {
      if (!hasValue || target.selectionStart === 1) document.getElementById(`code_${index + 1}`)?.focus();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    let value = e.target.value;
    if (value && !value.match(/[0-9]/)) return;

    if (value.length > 1) value = '';

    const hasOldSymbol = !!code[index];

    setCode(prev => {
      const next = [...prev];
      next[index] = value;
      return next;
    });

    if (value) document.getElementById(`code_${index + 1}`)?.focus();
    else if (!hasOldSymbol) document.getElementById(`code_${index - 1}`)?.focus();
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const clipboardData = e.clipboardData;
    const pastedNumbers = clipboardData.getData('Text').substring(0, 10).split('').filter(symbol => !!symbol.match(/[0-9]/));
    setCode(prev => [...Array(CODE_LENGTH).keys()].map((index) => pastedNumbers[index] ?? ''));
  };

  return (
    <div className={styles.root}>
      {isLoading ? (
        <LoadingIndicator color="var(--primary-color)" size={40} />
      ) : (
        <div className={styles.inputs}>
          {[...Array(CODE_LENGTH / 2).keys()].map(index => (
            <Input
              id={`code_${index}`}
              name={`code_${index}`}
              viewStyle={viewStyle}
              className={styles.input}
              onKeyDown={e => handleKeyDown(e, index)}
              onChange={e => handleChange(e, index)}
              onPaste={e => handlePaste(e)}
              value={code[index]}
              maxLength={1}
              autoFocus={index === 0}
              inputMode="decimal"
              autoComplete="off"
              meta={{
                containerClass: styles.inputContainer,
                error: isError,
              }}
              key={index}
            />
          ))}
          {/* <div className={styles.divider} /> */}
          {[...Array(CODE_LENGTH / 2).keys()].map(index => (
            <Input
              id={`code_${index + CODE_LENGTH / 2}`}
              name={`code_${index + CODE_LENGTH / 2}`}
              viewStyle={viewStyle}
              className={styles.input}
              onKeyDown={e => handleKeyDown(e, index + CODE_LENGTH / 2)}
              onChange={e => handleChange(e, index + CODE_LENGTH / 2)}
              onPaste={e => handlePaste(e)}
              value={code[index + CODE_LENGTH / 2]}
              maxLength={1}
              inputMode="decimal"
              autoComplete="off"
              meta={{
                containerClass: styles.inputContainer,
                error: isError,
              }}
              key={index + CODE_LENGTH / 2}
            />
          ))}
        </div>
      )}
      {/* {isError && (
        <div className={styles.errorText}>
          <Trans i18nKey="errors.confirm_code_incorrect" />
        </div>
      )} */}
    </div>
  );
};

export default CodeInput;

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, LoadingButton, Input, ButtonsContainer } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { MainLayout } from 'components/Layouts';
// import RegisterBanner from 'views/account/RegisterBanner';
import getValidationSchema from 'data/validationSchema';

import { useRecoverUserMutation } from 'store/auth/authApi';

import styles from './Account.module.css';

interface FormValues {
  login: string
}

const Recover = () => {
  const { t } = useTranslation();
  const [recoverUser, { isLoading, isSuccess, isError }] = useRecoverUserMutation();

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: yupResolver(getValidationSchema(['login']))
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => recoverUser(data);

  return (
    <MainLayout
      title={isSuccess ? t('account.recover_sended_title') : t('recovering_password')}
      subtitle={isSuccess ? t('account.recover_sended_subtitle') : t('account.recover_password_description')}
    // sideChildren={<RegisterBanner />}
    >
      <PageTitle>{t('site_name') + ' – ' + t('recovering_password')}</PageTitle>

      {!isSuccess && (
        <>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.inputs}>
              <Controller
                control={control}
                name="login"
                render={({ field: { value = '', ...rest } }) => (
                  <Input
                    id="login"
                    viewStyle="secondary"
                    placeholder={t('email_short')}
                    maxLength={255}
                    inputMode="email"
                    {...rest}
                    {...register}
                    autoComplete="username"
                    value={value}
                    meta={{
                      error: !!errors?.login?.message || isError,
                      errorMessage: errors?.login?.message && t('errors.' + errors.login.message),
                      showClearButton: true
                    }}
                  />
                )}
              />
            </div>
            <ButtonsContainer className={styles.buttonsContainer}>
              <LoadingButton type="submit" disabled={!isValid} isLoading={isLoading} fullWidth>{t('send_link')}</LoadingButton>
              <Button as={Link} viewStyle="textPrimary" to="/login">{t('login')}</Button>
            </ButtonsContainer>
          </form>
        </>
      )}
    </MainLayout>
  );
};

export default Recover;

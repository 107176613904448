import { useState, useEffect, MouseEvent, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PasswordCheck from 'views/profile/PasswordCheck';
import PageTitle from 'components/PageTitle';
import { MainLayout } from 'components/Layouts';
import { LoadingButton, Input, ButtonsContainer, ThemeContext } from '@forma/forma-ui-kit';
import { analytics } from 'helpers/analytics';
import getValidationSchema from 'data/validationSchema';

import { useConfirmInviteMutation } from 'store/auth/authApi';

import styles from './Account.module.css';

const fields = ['password', 'confirm_password'];

interface FormValues {
  password: string,
  confirm_password: string
}

const landingUrl = process.env.REACT_APP_LANDING_URL;

const SubmitInvite = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { lang } = useContext(ThemeContext);
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const [isShowPass, setShowPass] = useState(false);

  const [confirmUser, { isLoading, isSuccess }] = useConfirmInviteMutation();

  useEffect(() => {
    if (!id || !key) navigate('/404');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, key]);

  useEffect(() => {
    if (isSuccess) navigate('/approved');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const { register, handleSubmit, control, watch, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: yupResolver(getValidationSchema(fields))
  });
  const onSubmit: SubmitHandler<FormValues> = (data) => confirmUser({ ...data, id, key });

  const handleClickPasswordEye = (e: MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    setShowPass(!isShowPass);
  };

  const password = watch('password');

  return (
    <MainLayout
      title={t('account.invite_user_title')}
      subtitle={t('account.invite_user_description')}
    >
      <PageTitle>{t('site_name') + ' – ' + t('register')}</PageTitle>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
        <div className={styles.inputs}>
          <Controller
            control={control}
            name="password"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                id="password"
                viewStyle="secondary"
                type={isShowPass ? 'text' : 'password'}
                placeholder={t('account.input_new_password')}
                {...rest}
                {...register}
                value={value}
                autoComplete="new-password"
                meta={{
                  buttons: (
                    <img
                      src={isShowPass ? '/icons/eye-closed.svg' : '/icons/eye.svg'}
                      alt=""
                      onMouseDown={handleClickPasswordEye}
                    />
                  ),
                  error: !!errors?.password?.message,
                  errorMessage: errors?.password?.message && t('errors.' + errors.password.message),
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="confirm_password"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                id="confirm_password"
                viewStyle="secondary"
                type={isShowPass ? 'text' : 'password'}
                placeholder={t('repeat_password')}
                autoComplete="off"
                {...rest}
                {...register}
                value={value}
                meta={{
                  buttons: (
                    <img
                      src={isShowPass ? '/icons/eye-closed.svg' : '/icons/eye.svg'}
                      alt=""
                      onMouseDown={handleClickPasswordEye}
                    />
                  ),
                  error: !!errors?.confirm_password?.message,
                  errorMessage: errors?.confirm_password?.message && t('errors.' + errors.confirm_password.message),
                }}
              />
            )}
          />
          {password?.length ? (
            <div className={styles.passwordCheck}>
              <PasswordCheck password={password} />
            </div>
          ) : null}
        </div>

        {/* <div className={styles.errors}>
          {(isError && error && error.data && error.data.error) &&
            error.data.error.map((value, index) => <div className={styles.error} key={index}>{value}</div>)
          }
        </div> */}

        <ButtonsContainer className={styles.buttonsContainer}>
          <LoadingButton
            type="submit"
            disabled={!isValid}
            isLoading={isLoading}
            onClick={analytics.createAccount}
            fullWidth
          >
            {t('account.create_account')}
          </LoadingButton>
        </ButtonsContainer>
        <div className={styles.agreement}>
          <Trans
            i18nKey="account.register_oferta"
            values={{
              button: t('account.create_account'),
            }}
            components={{
              // eslint-disable-next-line
              agreement_tag: <a href={`${landingUrl}/${lang}/license/`} target="_blank" />,
              // eslint-disable-next-line
              privacy_tag: <a href={`${landingUrl}/${lang}/privacy/`} target="_blank" />
            }}
          />
        </div>
      </form>
    </MainLayout>
  );
};

export default SubmitInvite;

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { PopupPosition } from 'reactjs-popup/dist/types';
import { useAppSelector } from 'store/hooks';
import ContextMenu from 'components/ContextMenu';
import { hasPermission, PERMISSIONS } from 'helpers/permissions';

import { IFolderListChidlren } from 'interfaces/folders.interface';
import { IFoldersEditingProps } from 'interfaces/fileExplorer.interface';
import { selectUserPermissions } from 'store/user/userSlice';

import styles from './explorer-context-menu.module.css';

interface TrashContextMenuProps {
  open: boolean,
  item: IFolderListChidlren,
  onToggleMenu: (id: string | null) => void,
  editing?: IFoldersEditingProps,
  position: PopupPosition[] | PopupPosition
}

const TrashContextMenu: FC<TrashContextMenuProps> = ({
  open, item, onToggleMenu, editing = {}, position
}) => {
  const { t } = useTranslation();
  const permissions = useAppSelector(selectUserPermissions);
  const canDelete = hasPermission(permissions ?? [], PERMISSIONS.TEMPLATES.DELETE);

  const { onRestore } = editing;

  const options = [
    canDelete && {
      onClick: () => {
        if (onRestore) onRestore(item);
        // setShowDelete(true);
        // onToggleModal(true);
      },
      text: t('restore_template')
    }
  ].filter(Boolean);

  if (!options.length) return null;

  return (
    <ContextMenu
      open={open}
      items={options}
      position={position}
      control={open => (
        <button className={classNames(styles.button, open && styles.active)}>
          <ReactSVG src="/icons/more.svg" />
        </button>
      )}
      offsetY={12}
      offsetX={0}
      onOpen={() => onToggleMenu(item.id)}
      onClose={() => onToggleMenu(null)}
    />
  );
};

export default TrashContextMenu;

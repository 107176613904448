import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, SearchInput, Select, DateSelect } from '@forma/forma-ui-kit';
import SearchAndSelect from 'components/SearchAndSelect';
import { hasPermission, PERMISSIONS } from 'helpers/permissions';
import { categories_colors } from 'data/mock';

import { useAppSelector } from 'store/hooks';
import { selectUserPermissions } from 'store/user/userSlice';
import { IAttachmentCategoriesItem } from 'interfaces/attachments.interface';

import styles from './safe-documents-header.module.css';

interface SafeDocumentsHeaderProps {
  categories: {
    items?: IAttachmentCategoriesItem[],
    onCreate: (data: { name: string, color: string }) => void,
    isLoading: boolean
  },
  onChangeSearch: (value: string) => void,
  onChangeFilter: (params: {
    signedAtFrom?: string, signedAtTo?: string, categoriesid?: string, isProlongable?: string, counteragentids?: string[]
  }) => void,
  onClickAdd: () => void,
}

const SafeDocumentsHeader: FC<SafeDocumentsHeaderProps> = ({ categories, onChangeSearch, onChangeFilter, onClickAdd }) => {
  const { t } = useTranslation();
  const userPermissions = useAppSelector(selectUserPermissions);
  const canAddFiles = hasPermission(userPermissions ?? [], PERMISSIONS.ATTACHMENTS.CREATE);

  const categoriesOptions = categories.items && Object.values(categories.items).map(({ id, name, color }) => ({
    label:
      <span
        className={styles.label}
        style={{ color: categories_colors[color]?.color, backgroundColor: categories_colors[color]?.background }}
      >
        {name}
      </span>,
    value: id
  }));

  const prolongOptions = [
    { label: t('all'), value: '' },
    { label: t('prolongables'), value: 'true' },
    { label: t('no_prolongables'), value: 'false' }
  ];

  const handleSelectCategories = (e: { target: { name: string, value: string } }) => {
    onChangeFilter({ categoriesid: e.target.value });
  };

  const handleSelectContragents = (ids: string[]) => {
    onChangeFilter({ counteragentids: ids });
  };

  const handleSelectProlong = (e: { target: { name: string, value: string } }) => {
    onChangeFilter({ isProlongable: e.target.value });
  };

  const handleChangePeriod = (signedAtFrom: string, signedAtTo: string) => {
    onChangeFilter({ signedAtFrom: signedAtFrom === 'allTime' ? '' : signedAtFrom, signedAtTo: signedAtTo === 'allTime' ? '' : signedAtTo });
  };

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <SearchInput
          onSearch={onChangeSearch}
          className={styles.search}
          placeholder={t('enter_file_name_or_number')}
        />
        <div className={classNames(styles.select, styles.selectCategories)}>
          <Select
            name="filter_group"
            onChange={handleSelectCategories}
            options={categoriesOptions}
            placeholder={t('category')}
            multiple
            meta={{
              dropdownWidth: 240,
            }}
          />
        </div>
        <Button
          className={styles.button}
          onClick={onClickAdd}
          viewStyle="secondary"
          icon={<ReactSVG src="/icons/file-add.svg" wrapper="span" />}
          disabled={!canAddFiles}
        >
          {t('add_document')}
        </Button>
      </div>

      <div className={styles.row}>
        <SearchAndSelect
          className={styles.search}
          placeholder={t('enter_contragent_name')}
          loadingUrl="/counteragents"
          loadingMethod="get"
          loadingParam="filter"
          loadingMapper={(data: any, value: string) => data.items.map((item: any) => ({
            value: item.id,
            label: item.name || item.fullName || item.legalName,
          }))}
          onChangeSelected={handleSelectContragents}
        />
        <div className={classNames(styles.select, styles.selectPeriod)}>
          <DateSelect onChangeDate={handleChangePeriod} placeholder={t('sign_date')} />
        </div>
        <div className={classNames(styles.select, styles.selectProlong)}>
          <Select
            name="filter_prolong"
            onChange={handleSelectProlong}
            options={prolongOptions}
            placeholder={t('prolongable')}
          />
        </div>
      </div>
    </div>
  );
};

export default SafeDocumentsHeader;

import { useState, useEffect, lazy, Suspense, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs, ThemeContext, getFromTo } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import ContragentsList from 'views/contragents/Contragents';
import useQueryErrorRedirect from 'hooks/useQueryErrorRedirect';
import { contragent_types } from 'data/mock';
import ContragentCreateModal from './ContragentCreateModal';

import {
  useCreateCounteragentMutation,
  useGetCounteragentsQuery,
  useRemoveCounteragentMutation
} from 'store/counteragents/counteragentsApi';
import { ICounteragentType } from 'interfaces/counteragents.interface';
import { useAppDispatch } from 'store/hooks';
import { addNotification } from 'store/notifications/notificationsSlice';
import useCounteragentsSearchParams from 'views/contragents/ConteragentsSearch/useCounteragentsSearchParams';

const AddDataModal = lazy(() => import('./AddDataModal'));

const initialType = Object.keys(contragent_types)[0] as ICounteragentType;

const Contragents = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { viewport } = useContext(ThemeContext);
  const [openedModal, setOpenedModal] = useState<'import' | 'create' | null>(null);
  const type = searchParams.get('type') as ICounteragentType || initialType;

  const { params, setParams, page, setPage, canLoad } = useCounteragentsSearchParams({ initialType: type });
  const { data: counteragents, error: pageError, isFetching } =
    useGetCounteragentsQuery({ ...params, ...getFromTo(Number(page)) }, { skip: !canLoad });

  const [createCounteragent, { isLoading: isCreateLoading }] = useCreateCounteragentMutation();
  const [removeContragent] = useRemoveCounteragentMutation();

  useEffect(() => {
    if (searchParams.get('type') !== params.type) {
      setSearchParams(prev => ({ ...Object.fromEntries(prev.entries()), type: params.type }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.type, searchParams]);

  useEffect(() => {
    if (searchParams.get('page') !== page + '') {
      setSearchParams(prev => ({ ...Object.fromEntries(prev.entries()), page: String(page) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchParams]);

  useQueryErrorRedirect(pageError, navigate);

  const handleChangeSearch = (value: string) => {
    setParams({ filter: value });
  };

  const handleChangeSorting = (value: string) => {
    setParams({ sort: value });
  };

  const handleChangeTab = (type: string) => {
    setParams({ type: type as ICounteragentType });
  };

  const handleClickAdd = () => {
    setOpenedModal('create');
  };

  const handleClickImport = () => {
    setOpenedModal('import');
  };

  const handleRemove = (id: string) => {
    removeContragent(id);
  };

  const handleAddCounteragent = async (type: ICounteragentType, attrValues: { [key: string]: string }, redirect?: boolean) => {
    const result = await createCounteragent({
      type,
      selfowned: true,
      favorite: false,
      attrValues
    });
    if ('data' in result && result.data) {
      dispatch(addNotification({ content: t('contragent_added'), type: 'SUCCESS' }));
      if (redirect) navigate(`/contragents/${result.data.id}`);
      else setOpenedModal(null);
    }
  };

  return (
    <AdminLayout title={t('directory_contragents')}>
      <PageTitle>{t('site_name') + ' – ' + t('directory_contragents')}</PageTitle>
      <Tabs
        current={params.type}
        onChange={handleChangeTab}
        items={
          Object.keys(contragent_types).map((item) => {
            const cType = item as ICounteragentType;
            return {
              id: item,
              name: t(`contragents_types.${item}${viewport === 'phone' ? '_short' : ''}`),
              children: (
                <ContragentsList
                  items={counteragents?.items}
                  outside={counteragents?.outside}
                  type={cType}
                  search={{
                    onChange: handleChangeSearch,
                    onChangeSorting: handleChangeSorting,
                    sorting: params.sort
                  }}
                  pagination={{
                    isLoading: isFetching,
                    onChange: setPage,
                    count: counteragents?.count ?? 0,
                    page: page
                  }}
                  onClickImport={handleClickImport}
                  onRemove={handleRemove}
                  onClickAdd={handleClickAdd}
                  onAdd={(type, values) => handleAddCounteragent(type, values, true)}
                  key={item}
                />
              )
            };
          })
        }
      />
      <Suspense>
        <AddDataModal
          open={openedModal === 'import'}
          onClose={() => setOpenedModal(null)}
          type={params.type}
        />
      </Suspense>
      <ContragentCreateModal
        open={openedModal === 'create'}
        onClose={() => setOpenedModal(null)}
        onAdd={handleAddCounteragent}
        isAddLoading={isCreateLoading}
        type={params.type}
      />
    </AdminLayout>
  );
};

export default Contragents;

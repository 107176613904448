import { useState, FC } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { differenceInDays, format } from 'date-fns';
import classNames from 'classnames';
import { Tooltip, Checkbox, Table, TableRow, TableCell, Switcher, Tags } from '@forma/forma-ui-kit';
import { hasPermission, PERMISSIONS } from 'helpers/permissions';
import { files_icons, categories_colors } from 'data/mock';

import { useAppSelector } from 'store/hooks';
import { selectUserPermissions } from 'store/user/userSlice';
import { IAttachmentHierarhyItem } from 'interfaces/attachments.interface';
import { ICounteragentsItem } from 'interfaces/counteragents.interface';

import styles from './document-item.module.css';

const countChildsTotal = (items: IAttachmentHierarhyItem[]): number => {
  return items.reduce((total: number, { children }) => (children ? total + countChildsTotal(children) : total), items.length);
};

// const collectChildsIds = (items: IAttachmenHierarhyItem[]): string[] => {
//   return items.reduce((acc: string[], { id, children }) => (
//     children ? [ ...acc, id, ...collectChildsIds(children) ] : [ ...acc, id ]
//   ), []);
// };

interface DocumentsItemProps extends IAttachmentHierarhyItem {
  active?: string,
  selected: string[],
  onClick: (id: string) => void,
  onSelectItems: (ids: string[], checked: boolean) => void,
  onClickCategory?: (id: string) => void,
  level?: number,
  offsetParentId?: string,
}

const DocumentsItem: FC<DocumentsItemProps> = ({
  id, name, ext, counteragent, categories, dtFrom, dtTo, inactive, relatedCounteragents, children,
  active, selected, onClickCategory, level = 1, onSelectItems, offsetParentId, onClick
}) => {
  const { t } = useTranslation();
  const userPermissions = useAppSelector(selectUserPermissions);
  const canDownloadFiles = hasPermission(userPermissions ?? [], PERMISSIONS.ATTACHMENTS.DOWNLOAD);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const childTotalCount = children && countChildsTotal(children);
  const childsIds = children && children.map(({ id }) => id);

  const isSelected = selected.includes(id);
  const from = dtFrom && new Date(dtFrom);
  const to = dtTo && new Date(dtTo);
  const now = new Date();
  const dateDiff = to ? differenceInDays(to, now) : 999;

  const getCounteragentName = (counteragent: ICounteragentsItem) => (
    counteragent.name || counteragent.fullName || (['entity', 'individual'].includes(counteragent.type) ? t('no_name') : t('no_fullname'))
  );

  return (
    <>
      <TableRow
        className={classNames(styles.row, isSelected && styles.selected, inactive && styles.inActive, children && styles.withChilds)}
        isActive={active === id}
        isSelected={isSelected}
      >
        <TableCell
          className={styles.cellArrow}
          rowSpan={(isSelected && children) ? 2 : undefined}
          onClick={!children ? () => onClick(id) : undefined}
        >
          {children && (
            <button className={classNames(styles.dropdownBtn, isOpen && styles.open)} onClick={() => setIsOpen(!isOpen)}>
              <span>{childTotalCount}</span>
              <ReactSVG className={styles.dropdownIcon} src="/icons/dropdown-thin.svg" wrapper="span" />
            </button>
          )}
        </TableCell>
        <TableCell className={styles.cellIcon} onClick={() => onClick(id)}>
          <img src={files_icons[ext] ? files_icons[ext] : files_icons.other} className={styles.fileIcon} alt="" />
        </TableCell>
        <TableCell className={styles.cellName} title={t('doc_name')} onClick={() => onClick(id)}>
          <span className={styles.name}>
            <span>{name}</span>
          </span>
        </TableCell>
        <TableCell className={styles.cellCategory} title={t('file_category')}>
          <Tags
            className={styles.categories}
            items={categories.slice(0, 2).map(({ id, name, color }) => (
              {
                name: name,
                color: categories_colors[color]?.color,
                backgroundColor: categories_colors[color]?.background,
                onClick: () => onClickCategory && onClickCategory(id)
              }
            ))}
          />
          {categories?.length > 2 && (
            <div className={styles.more}>
              <Tooltip
                className={styles.tooltip}
                position={['bottom center', 'top center']}
                control={<div className={styles.moreLink}>{t('more')} {categories.length - 2}</div>}
              >
                <input type="hidden" autoFocus />
                <Tags
                  size="small"
                  items={categories.slice(2, categories.length).map(({ id, name, color }) => (
                    {
                      name: name,
                      color: categories_colors[color]?.color,
                      backgroundColor: categories_colors[color]?.background,
                      onClick: () => onClickCategory && onClickCategory(id)
                    }
                  ))}
                />
              </Tooltip>
            </div>
          )}
        </TableCell>
        <TableCell className={styles.cellContragent} title={t('related_contragent')} onClick={() => onClick(id)}>
          <Tooltip
            className={styles.tooltip}
            position={['top center', 'bottom center']}
            control={<div className={classNames(styles.contragent, styles.mainContragent)}>{getCounteragentName(counteragent)}</div>}
          >
            {getCounteragentName(counteragent)}
          </Tooltip>
          {relatedCounteragents?.map(item => (
            <Tooltip
              className={styles.tooltip}
              position={['top center', 'bottom center']}
              control={<div className={styles.contragent}>{getCounteragentName(item)}</div>}
              key={item.id}
            >
              {getCounteragentName(item)}
            </Tooltip>
          ))}
        </TableCell>
        <TableCell
          className={classNames(
            styles.cellValid, dateDiff <= 31 && (dateDiff <= 10 ? (dateDiff <= 0 ? styles.gray : styles.red) : styles.orange)
          )}
          title={t('activity_period')}
          onClick={() => onClick(id)}
        >
          {from && `${t('from')} ${format(from, 'dd.MM.yyyy')}`}
          {(from && to) && <br />}
          {to && `${t('to')} ${format(to, 'dd.MM.yyyy')}`}
        </TableCell>
        <TableCell className={styles.cellButtons}>
          <div className={styles.buttons}>
            <div className={styles.minHeight} />
            {canDownloadFiles &&
              <Tooltip
                control={
                  <div>
                    <Checkbox
                      id={id}
                      name={id}
                      containerClass={styles.checkbox}
                      iconClass={styles.checkboxIcon}
                      checked={isSelected}
                      onChange={e => onSelectItems([id], e.target.checked)}
                      viewStyle="square"
                    />
                  </div>
                }
                position="left center"
                offsetX={0}
                offsetY={0}
                key={id + isSelected}
              >
                {isSelected ? t('deselect') : t('select')}
              </Tooltip>
            }
            {/* <div className={styles.handler}>
              <ReactSVG src="/icons/dots-move.svg" wrapper="span" />
            </div> */}
          </div>
        </TableCell>
      </TableRow>
      {(childsIds && isSelected) && (
        <TableRow
          className={classNames(styles.row, isSelected && styles.selected, inactive && styles.inActive)}
          isActive={active === id}
          isSelected={isSelected}
        >
          <TableCell colSpan={6} className={styles.cellControls}>
            <Switcher
              name="select_all"
              label={t('select_nested_files')}
              containerClass={styles.selectAll}
              iconClass={styles.selectAllIcon}
              onChange={e => onSelectItems(childsIds, e.target.checked)}
              checked={childsIds.every(id => selected.includes(id))}
              size="small"
            />
          </TableCell>
        </TableRow>
      )}
      {(children && isOpen) && (
        <tr>
          <td colSpan={7}>
            <Table className={styles.subTable}>
              {children.map(item => (
                <DocumentsItem
                  {...item}
                  active={active}
                  level={level + 1}
                  offsetParentId={level === 1 ? id : offsetParentId}
                  selected={selected}
                  onClickCategory={onClickCategory}
                  onSelectItems={onSelectItems}
                  onClick={onClick}
                  key={item.id}
                />
              ))}
            </Table>
          </td>
        </tr>
      )}
    </>
  );
};

export default DocumentsItem;

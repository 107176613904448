import { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Input, Textarea, Button, DateInput, ButtonsContainer, FieldContainer, Tags, LoadingButton, Checkbox } from '@forma/forma-ui-kit';
import ConteragentsSearch from 'views/contragents/ConteragentsSearch';
import SelectCategories from '../DocumentCategories';
import { categories_colors } from 'data/mock';

import { IAttachmentItem, IAttachmentItemEdit, IAttachmentCategoriesItem } from 'interfaces/attachments.interface';

import styles from './document-edit.module.css';

interface DocumentEditProps {
  data?: IAttachmentItem,
  fileName?: string,
  categories: {
    items?: IAttachmentCategoriesItem[],
    onCreate: (data: { name: string, color: string }) => Promise<IAttachmentCategoriesItem | null>,
    isCreateLoading: boolean
  },
  isSaveLoading: boolean,
  onSave: (data: Omit<IAttachmentItemEdit, 'id'>) => void,
  onCancel: () => void
}

interface FormValues extends Omit<IAttachmentItem, 'id'> {
}

const DocumentEdit: FC<DocumentEditProps> = ({
  data, fileName, categories, isSaveLoading, onSave, onCancel
}) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<'fields' | 'contragent' | 'rContragents' | 'categories' | 'createCategory'>('fields');
  const [rContragentIndex, setRContragentIndex] = useState<number | null>(null);

  const { register, handleSubmit, watch, getValues, setValue, control, formState: { isValid } } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: data?.name ?? fileName ?? '',
      ...data
    }
    // resolver: yupResolver(getValidationSchema(fields))
  });
  const { fields: fieldsCategories, append: appendCategory, remove: removeCategory } = useFieldArray({
    control,
    name: 'categories',
    keyName: 'id'
  });
  const { fields: fieldsCounteragents, append: appendCounteragent, remove: removeCounteragent } = useFieldArray({
    control,
    name: 'relatedCounteragents',
    keyName: 'id'
  });

  const handleCreateCategory = async (data: { name: string, color: string }) => {
    const result = await categories.onCreate(data);
    if (result) setMode('categories');
    return result;
  };

  const handleSubmitForm = async ({ counteragent, ...data }: FormValues) => {
    onSave({
      ...data,
      counteragentid: counteragent.id,
      categories: data.categories.map(({ id }) => id),
      relatedCounteragents: data.relatedCounteragents.map(({ id }) => id),
    });
  };

  if (mode === 'contragent') return (
    <div>
      <ButtonsContainer className={styles.headButtons}>
        <Button
          viewStyle="tertiary"
          size="small"
          className={styles.button}
          onClick={() => setMode('fields')}
        >
          {t('go_back')}
        </Button>
      </ButtonsContainer>
      <ConteragentsSearch
        onClickItem={(counteragent) => {
          setValue('counteragent', counteragent);
          setMode('fields');
        }}
      />
    </div>
  );

  if (mode === 'rContragents') return (
    <div>
      <ButtonsContainer className={styles.headButtons}>
        <Button
          viewStyle="tertiary"
          size="small"
          className={styles.button}
          onClick={() => setMode('fields')}
        >
          {t('go_back')}
        </Button>
      </ButtonsContainer>
      <ConteragentsSearch
        onClickItem={(counteragent) => {
          if (rContragentIndex || rContragentIndex === 0) setValue(`relatedCounteragents.${rContragentIndex}`, counteragent);
          else appendCounteragent(counteragent);
          setMode('fields');
        }}
      />
    </div>
  );

  const selectedCategories = watch('categories');

  if (mode === 'categories' || mode === 'createCategory') return (
    <div>
      <ButtonsContainer className={styles.headButtons}>
        <Button
          viewStyle="tertiary"
          size="small"
          className={styles.button}
          onClick={() => setMode(prev => prev === 'createCategory' ? 'categories' : 'fields')}
        >
          {t('go_back')}
        </Button>
      </ButtonsContainer>
      <SelectCategories
        items={categories.items}
        isCreateLoading={categories.isCreateLoading}
        onCreate={handleCreateCategory}
        selected={selectedCategories.map(({ id }) => id)}
        onSubmit={addingIds => {
          appendCategory(categories.items!.filter(({ id }) => (
            addingIds.includes(id) && !selectedCategories.some(category => category.id === id)
          )));
          setMode('fields');
        }}
        isCreate={mode === 'createCategory'}
        onClickCreate={() => setMode('createCategory')}
      />
    </div>
  );

  const dtFrom = watch('dtFrom');
  const dtTo = watch('dtTo');
  const counteragent = watch('counteragent');

  return (
    <form className={styles.form} onSubmit={handleSubmit(handleSubmitForm)}>
      <div className={styles.inputs}>
        <Controller
          control={control}
          name="name"
          render={({ field: { value = '', ...rest } }) => (
            <Input
              id="name"
              viewStyle="secondary"
              placeholder={t('enter_document_name')}
              maxLength={255}
              data-testid="name"
              required
              {...rest}
              {...register('name', { required: true, minLength: 3 })}
              value={value}
              meta={{
                label: t('document_name'),
                showClearButton: true
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="number"
          render={({ field: { value = '', ...rest } }) => (
            <Input
              id="number"
              viewStyle="secondary"
              placeholder={t('enter_document_number')}
              maxLength={255}
              data-testid="number"
              {...rest}
              {...register('number')}
              value={value}
              meta={{
                label: t('document_number'),
                showClearButton: true
              }}
            />
          )}
        />
        <FieldContainer
          id="validity"
          label={t('validity')}
          className={styles.dates}
        >
          <Controller
            control={control}
            name="dtFrom"
            render={({ field: { value = '', ...rest } }) => (
              <DateInput
                id="dtFrom"
                viewStyle="secondary"
                placeholder="01.09.2024"
                data-testid="dtFrom"
                {...rest}
                {...register('dtFrom')}
                value={value}
                meta={{
                  maxDate: dtTo,
                  portalId: 'popup-root'
                }}
              />
            )}
          />
          <span className={styles.datesDivider}>—</span>
          <Controller
            control={control}
            name="dtTo"
            render={({ field: { value = '', ...rest } }) => (
              <DateInput
                id="dtTo"
                viewStyle="secondary"
                placeholder="02.09.2027"
                data-testid="dtTo"
                {...rest}
                {...register('dtTo')}
                value={value}
                meta={{
                  minDate: dtFrom,
                  portalId: 'popup-root'
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="isProlongable"
            render={({ field: { value, ...rest } }) => (
              <Checkbox
                id="isProlongable"
                data-testid="isProlongable"
                checked={value}
                label={t('prolongable')}
                containerClass={styles.datesCheckbox}
                {...rest}
                {...register('isProlongable')}
              />
            )}
          />
        </FieldContainer>
        <Input
          id="counteragentid"
          viewStyle="secondary"
          placeholder={t('enter_inn_or_organization_name')}
          maxLength={255}
          value={counteragent?.name || counteragent?.fullName || ''}
          onFocus={() => setMode('contragent')}
          data-testid="counteragentid"
          required
          meta={{
            label: t('contragent'),
          }}
        />
        <div className={styles.counteragents}>
          {fieldsCounteragents.map((field, index) => {
            const value = getValues(`relatedCounteragents.${index}`);
            return (
              <div className={styles.counteragentsItem} key={field.id}>
                <Input
                  id={`relatedCounteragentId_${index}`}
                  viewStyle="secondary"
                  placeholder={t('enter_inn_or_organization_name')}
                  maxLength={255}
                  value={value.name || value.fullName || ''}
                  onFocus={() => {
                    setMode('rContragents');
                    setRContragentIndex(index);
                  }}
                  data-testid={`relatedCounteragentId_${index}`}
                  required
                />
                <Button
                  viewStyle="textLight"
                  className={styles.buttonRemove}
                  iconClassName={styles.buttonRemoveIcon}
                  icon={<ReactSVG src="/icons/close.svg" wrapper="span" />}
                  title={t('remove')}
                  onClick={() => removeCounteragent(index)}
                />
              </div>
            );
          })}

          <Button
            viewStyle="secondary"
            className={styles.buttonAdd}
            iconClassName={styles.buttonAddIcon}
            icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
            title={t('add')}
            onClick={() => {
              setMode('rContragents');
              setRContragentIndex(null);
            }}
          />
        </div>
        <Controller
          control={control}
          name="description"
          render={({ field: { value = '', ...rest } }) => (
            <Textarea
              id="description"
              viewStyle="secondary"
              placeholder={t('enter_document_description')}
              maxLength={255}
              data-testid="description"
              {...rest}
              {...register('description')}
              value={value}
              meta={{
                label: t('description'),
                showClearButton: true
              }}
            />
          )}
        />
        <FieldContainer
          id="categories"
          label={t('categories')}
        >
          <div>
            {fieldsCategories.length ? (
              <Tags
                className={styles.categories}
                onClickAdd={() => setMode('categories')}
                items={fieldsCategories.map((field, index) => ({
                  id: field.id,
                  name: <div className={styles.category}>
                    {field.name}
                    <ReactSVG className={styles.categoryRemove} src="/icons/close.svg" onClick={() => removeCategory(index)} />
                  </div>,
                  color: categories_colors[field.color]?.color,
                  backgroundColor: categories_colors[field.color]?.background,
                }))}
              />
            ) : (
              <Button
                viewStyle="tertiary"
                size="small"
                className={styles.buttonAddCategory}
                icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
                onClick={() => setMode('categories')}
              >
                {t('add_category')}
              </Button>
            )}
          </div>
        </FieldContainer>
      </div>
      <ButtonsContainer className={styles.buttons}>
        <LoadingButton
          type="submit"
          viewStyle="primary"
          size="small"
          className={styles.button}
          disabled={!isValid || !counteragent}
          isLoading={isSaveLoading}
        >
          {t('save')}
        </LoadingButton>
        <Button
          onClick={onCancel}
          viewStyle="tertiary"
          size="small"
          className={styles.button}
        >
          {t('cancel')}
        </Button>
      </ButtonsContainer>
    </form>
  );
};

export default DocumentEdit;

import { useState, MouseEvent, useEffect, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { LoadingButton, Input, ButtonsContainer, ThemeContext } from '@forma/forma-ui-kit';
import { yupResolver } from '@hookform/resolvers/yup';
import PageTitle from 'components/PageTitle';
import { MainLayout } from 'components/Layouts';
import PasswordCheck from 'views/profile/PasswordCheck';
// import PasswordBanner from 'views/account/PasswordBanner';
import getValidationSchema from 'data/validationSchema';
// import { analytics } from 'helpers/analytics';

import { useAppSelector } from 'store/hooks';
import { selectUserData } from 'store/user/userSlice';
import { useSetProfilePasswordMutation } from 'store/user/userApi';

import styles from './Account.module.css';

interface FormValues {
  login: string,
  password: string
}

const landingUrl = process.env.REACT_APP_LANDING_URL;

const RegisterPassword = () => {
  const { t } = useTranslation();
  const { lang } = useContext(ThemeContext);
  const user = useAppSelector(selectUserData);
  const [isShowPass, setShowPass] = useState(false);
  const [setPassword, { isLoading, isError }] = useSetProfilePasswordMutation();

  const { register, handleSubmit, control, watch, setValue, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: yupResolver(getValidationSchema(['password']))
  });

  const password = watch('password');

  useEffect(() => {
    if (user?.login) setValue('login', user.login);
    // eslint-disable-next-line
  }, [user]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    setPassword({ newPassword: data.password }).then(result => {
      if ('data' in result) {
        // analytics.successRegistration();
        localStorage.removeItem('newRegister');
        window.location.href = '/guide';
      }
    });
  };

  const handleClickPasswordEye = (e: MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    setShowPass(!isShowPass);
  };

  return (
    <MainLayout
      title={<div className={styles.regEmail}>{t('account.you_registered_as')}: <b>{user?.login}</b></div>}
    // sideChildren={<PasswordBanner visible={isShowPass} /> }
    >
      <PageTitle>{t('site_name') + ' – ' + t('register2')}</PageTitle>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputs}>
          <Controller
            control={control}
            name="login"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                id="login"
                viewStyle="secondary"
                placeholder={t('placeholders.login')}
                maxLength={255}
                inputMode="email"
                {...rest}
                {...register}
                value={value}
                readOnly
                data-testid="login"
                hidden
                meta={{
                  // label: t('account.input_your_working_email'),
                  error: !!errors?.login?.message || isError,
                  errorMessage: !!errors?.login?.message && t('errors.' + errors.login.message)
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                id="password"
                viewStyle="secondary"
                type={isShowPass ? 'text' : 'password'}
                placeholder={t('placeholders.password_new')}
                autoFocus
                {...rest}
                {...register}
                autoComplete="new-password"
                value={value}
                meta={{
                  // label: t('password'),
                  buttons: (
                    <img
                      src={isShowPass ? '/icons/eye-closed.svg' : '/icons/eye.svg'}
                      alt=""
                      onMouseDown={handleClickPasswordEye}
                    />
                  ),
                  error: !!errors?.password?.message || isError,
                  errorMessage: errors?.password?.message && t('errors.' + errors.password.message),
                }}
              />
            )}
          />
          <div className={styles.passwordCheck}>
            <PasswordCheck password={password} />
          </div>
        </div>
        <ButtonsContainer className={styles.buttonsContainer}>
          <LoadingButton
            type="submit"
            disabled={!isValid}
            isLoading={isLoading}
            fullWidth
          >
            {t('register')}
          </LoadingButton>
        </ButtonsContainer>
        <div className={styles.agreement}>
          <Trans
            i18nKey="account.register_oferta"
            values={{
              button: t('register')
            }}
            components={{
              // eslint-disable-next-line
              agreement_tag: <a href={`${landingUrl}/${lang}/license/`} target="_blank" />,
              // eslint-disable-next-line
              privacy_tag: <a href={`${landingUrl}/${lang}/privacy/`} target="_blank" />
            }}
          />
        </div>
      </form>
    </MainLayout>
  );
};

export default RegisterPassword;

import { IFolderListTreeChidlren } from 'interfaces/folders.interface';

const getFavouritesFromTree = (items: IFolderListTreeChidlren[]) => {
  const favouritesInTree: IFolderListTreeChidlren[] = [];
  items.forEach(item => {
    if (item.favorite) favouritesInTree.push(item);
    if (item.children) favouritesInTree.push(...getFavouritesFromTree(item.children));
  });
  return favouritesInTree;
};

export default getFavouritesFromTree;

import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal } from '@forma/forma-ui-kit';
import DocumentInfo, { DocumentInfoSkelet } from 'views/SafeDocumentsList/DocumentInfo';
import DocumentEdit, { DocumentEditSkelet } from 'views/SafeDocumentsList/DocumentEdit';
import copyToClipboard from 'helpers/copyToClipboard';
import { hasPermission, PERMISSIONS } from 'helpers/permissions';
import webView from 'helpers/webview';
import downloadFromUrl from 'helpers/downloadFromUrl';

import { useAppDispatch } from 'store/hooks';
import { selectUserPermissions } from 'store/user/userSlice';
import { addNotification } from 'store/notifications/notificationsSlice';
import {
  useGetAttachmentCategoriesQuery,
  useGetAttachmentByIdQuery,
  useUpdateAttachmentMutation,
  useLazyDownloadAttachmentQuery,
  useLazyDownloadAttachmentsArchiveQuery,
  useRemoveAttachmentMutation,
  useCreateAttachmentCategoryMutation,
} from 'store/attachments/attachmentsApi';
import { TDateISO } from 'interfaces/common.interface';
import { IAttachmentItemEdit } from 'interfaces/attachments.interface';

import styles from './document-modal.module.css';

interface DocumentModalProps {
  id?: string,
  open: boolean,
  onClose: (open: boolean) => void,
  onClickAdd: (id: string) => void,
}

const DocumentModal: FC<DocumentModalProps> = ({
  id, open, onClose, onClickAdd
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [mode, setMode] = useState<'edit' | 'view'>('view');
  const [isShowModalRelevant, setShowModalRelevant] = useState(false);

  const userPermissions = useSelector(selectUserPermissions);
  const canEditFiles = hasPermission(userPermissions ?? [], PERMISSIONS.ATTACHMENTS.EDIT);

  const { data: attachment, isFetching: isLoading } = useGetAttachmentByIdQuery(id, { skip: !id });
  const { data: categories } = useGetAttachmentCategoriesQuery();
  const [removeAttachment] = useRemoveAttachmentMutation();
  const [updateAttachment, { isLoading: isUpdateLoading }] = useUpdateAttachmentMutation();
  const [createCategory, { isLoading: isCreateCategoryLoading }] = useCreateAttachmentCategoryMutation();

  const [downloadAttachment, { isFetching: isDownloadLoading }] = useLazyDownloadAttachmentQuery();
  const [downloadAttachmentsArchive] = useLazyDownloadAttachmentsArchiveQuery();

  useEffect(() => {
    if (id) setMode('view');
  }, [id]);

  const handleCopyLink = (id: string) => {
    const origin = window.location.origin;
    const res = copyToClipboard(`${origin}/safe/${id}`);
    if (res === 'success') dispatch(addNotification({ content: t('link_copied'), type: 'SUCCESS' }));
  };

  const handleRemove = async (id: string) => {
    const result = await removeAttachment(id);
    if (result && 'data' in result) {
      dispatch(addNotification({ content: t('file_deleted_successfully'), type: 'ERROR' }));
      navigate('/safe');
    }
  };

  const handleDowloadFiles = (ids: string[]) => {
    if (ids.length === 1) {
      const fileName = attachment.name;

      if (window?.navigator?.userAgent === 'forma-mobile') {
        webView.safeDownload(ids[0], fileName ?? 'Document');
      } else {
        downloadAttachment(ids[0])
          .unwrap()
          .then(url => downloadFromUrl(url, fileName ?? 'Document'));
      }
    } else {
      if (window?.navigator?.userAgent === 'forma-mobile') {
        webView.safeDownloadArchive(ids, 'Documents.zip');
      } else {
        downloadAttachmentsArchive({ ids })
          .unwrap()
          .then(url => downloadFromUrl(url, 'Documents.zip'));
      }
    }
  };

  const handleSaveAttachment = (data: IAttachmentItemEdit) => {
    updateAttachment({ id: attachment.id, ...data }).then(() => {
      setMode('view');
    });
  };

  const handleOutOfDate = () => {
    updateAttachment({ id, inactive: new Date().toISOString() as TDateISO });
  };

  const handleCreateCategory = async (data: { name: string, color: string }) => {
    return createCategory(data).then(result => {
      return ('data' in result) ? result.data : null;
    }).catch(e => null);
  };

  const handleSign = (id: string) => {
    updateAttachment({ id, signedAt: new Date().toISOString() as TDateISO });
  };

  return (
    <Modal
      open={open}
      onClose={open => {
        onClose(open);
        setMode('view');
      }}
      viewStyle="right"
      width="100%"
      maxWidth="570px"
      buttonsClassName={styles.buttons}
      buttons={(mode === 'view' && !isLoading && !attachment?.inactive) ? [{
        viewStyle: 'tertiary',
        children: t('make_invalidity_doc'),
        size: 'small',
        className: styles.modalButton,
        onClick: () => setShowModalRelevant(true),
        disabled: !canEditFiles,
        closing: false
      }] : undefined}
    >
      {mode === 'view' && (
        (isLoading || !attachment) ? (
          <DocumentInfoSkelet />
        ) : (
          <DocumentInfo
            data={attachment}
            onClickEdit={() => setMode('edit')}
            onClickAddSubitem={onClickAdd}
            onClickCopyLink={handleCopyLink}
            onClickDownload={handleDowloadFiles}
            isDownloadLoading={isDownloadLoading}
            onRemove={handleRemove}
            onSign={handleSign}
          />
        )
      )}
      {mode === 'edit' && (
        (isLoading || !attachment) ? (
          <DocumentEditSkelet />
        ) : (
          <DocumentEdit
            data={attachment}
            onSave={handleSaveAttachment}
            onCancel={() => setMode('view')}
            isSaveLoading={isUpdateLoading}
            categories={{
              items: categories,
              onCreate: handleCreateCategory,
              isCreateLoading: isCreateCategoryLoading
            }}
          />
        )
      )}

      <Modal
        size="small"
        open={isShowModalRelevant}
        onClose={() => setShowModalRelevant(false)}
        buttons={[
          {
            children: t('yes'),
            viewStyle: 'tertiary',
            onClick: () => handleOutOfDate()
          },
          {
            children: t('no'),
            viewStyle: 'tertiary'
          }
        ]}
        title={t('out_of_date_document_question')}
      >
        <div className={styles.confirmModal}>
          {t('do_yo_want_out_of_date_file')}
          <br />
          <br />
          {t('cancellation_notification')}
        </div>
      </Modal>
    </Modal>
  );
};

export default DocumentModal;

import { useEffect, useState } from 'react';
import { ICounteragentType } from 'interfaces/counteragents.interface';

const DEFAULT_SORTING = '-createdAt';

const useCounteragentsSearchParams = ({
  initialType
}: {
  initialType: ICounteragentType
}) => {
  const [params, setParams] = useState<{ sort?: string, type: ICounteragentType, filter?: string }>({ type: initialType });
  const [page, setPage] = useState(1);

  useEffect(() => {
    const sorting = localStorage.getItem(`contragentsSorting.${params.type}`);
    setParams((prev) => ({ ...prev, sort: sorting || DEFAULT_SORTING }));
  }, [params.type]);

  const handleChangeParams = (newParams: { [key: string]: string }) => {
    setParams(prev => {
      if (newParams.type) {
        newParams.filter = '';
        const sorting = localStorage.getItem(`contragentsSorting.${params.type}`);
        newParams.sorting = sorting || DEFAULT_SORTING;
      }
      if (newParams.sort && params.type) {
        localStorage.setItem(`contragentsSorting.${params.type}`, newParams.sort);
      }
      return ({ ...prev, ...newParams });
    });
    setPage(1);
  };

  const canLoad = params.type && params.sort && page;

  return {
    canLoad,
    params,
    setParams: handleChangeParams,
    page,
    setPage,
  };
};

export default useCounteragentsSearchParams;

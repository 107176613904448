
import { templatesIcons } from 'data/mock';
import { FORMA_FOLDER_ID_PACKS, FORMA_FOLDER_ID_STATIC, FORMA_FOLDER_ID_TRASH } from 'data/constants';

import { IFolderListTreeChidlren } from 'interfaces/folders.interface';

export const getListItemIcon = (item: IFolderListTreeChidlren, isHover?: boolean) => {
  if (item.type === 'folder') {
    if (item.external) {
      if (item.id === FORMA_FOLDER_ID_STATIC) return templatesIcons.list.folders.static;
      if (item.id === FORMA_FOLDER_ID_PACKS) return templatesIcons.list.folders.packs;
      if (item.id === FORMA_FOLDER_ID_TRASH) return templatesIcons.list.folders.trash;
      return templatesIcons.list.folders.strict;
    }

    if (!item.children?.length) return templatesIcons.list.folders.empty;
    if (item.isAccessAdd || item.groups?.length) return templatesIcons.list.folders.private;
    return templatesIcons.list.folders.default;
  }

  if (item.external) return templatesIcons.list.files.strict;
  return templatesIcons.list.files.default;
};

import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Button, SearchInput, Select } from '@forma/forma-ui-kit';
import ProductsCategoriesEditModal from 'views/products/ProductsCategoriesEditModal';
import { hasPermission, PERMISSIONS } from 'helpers/permissions';

import { useAppSelector } from 'store/hooks';
import { selectUserPermissions } from 'store/user/userSlice';
import { IProductsCategoriesItem } from 'interfaces/products.interface';

import styles from './products-services-header.module.css';

interface ProductsServicesHeaderProps {
  categories?: IProductsCategoriesItem[],
  onChangeSearch: (value: string) => void,
  onChangeFilter: (params: { category: string }) => void,
  onClickImport?: () => void
}

const ProductsServicesHeader: FC<ProductsServicesHeaderProps> = ({
  categories, onChangeSearch, onChangeFilter, onClickImport
}) => {
  const { t } = useTranslation();
  const userPermissions = useAppSelector(selectUserPermissions);
  const canCreateProducts = hasPermission(userPermissions ?? [], PERMISSIONS.PRODUCTS.CREATE);
  const canEditProducts = hasPermission(userPermissions ?? [], PERMISSIONS.PRODUCTS.EDIT);

  const [isEditOpen, setEditOpen] = useState<boolean>(false);

  const options = categories?.map(({ id, name }) => ({
    label: name,
    value: id
  })) ?? [];

  const handleSelectCategories = (e: { target: { name: string, value: string } }) => {
    onChangeFilter({ category: e.target.value });
  };

  return (
    <div className={styles.root}>
      <SearchInput
        onSearch={onChangeSearch}
        className={styles.search}
        placeholder={t('enter_product_name_or_code')}
      />
      <div className={classNames(styles.select, styles.selectCategories)}>
        <Select
          name="filter_group"
          onChange={handleSelectCategories}
          options={options}
          placeholder={t('category')}
          multiple
          meta={{
            afterDropdownButton: canEditProducts && (
              <div className={styles.addCategoryButton} onMouseDown={() => setEditOpen(true)}>
                {t('add_edit_categories')}
                <ReactSVG src="/icons/plus.svg" wrapper="span" />
              </div>
            ),
            dropdownWidth: 210
          }}
        />
      </div>
      {onClickImport && (
        <Button
          id="import_products" // id for onboarding
          className={styles.button}
          onClick={onClickImport}
          viewStyle="secondary"
          disabled={!canCreateProducts}
        >
          {t('add_from_file')}
        </Button>
      )}
      <Button
        id="add_product" // id for onboarding
        className={styles.button}
        as={Link}
        to="/product-create"
        viewStyle="secondary"
        disabled={!canCreateProducts}
      >
        {t('add')}
      </Button>

      <ProductsCategoriesEditModal
        open={isEditOpen}
        onClose={() => setEditOpen(false)}
      />
    </div>
  );
};

export default ProductsServicesHeader;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import FillDocument from 'views/FillDocument';
import TrialEndModal from 'views/Guide/TrialEndModal';
import RequestDemoModal from 'views/Guide/RequestDemoModal';
import { NotFound } from 'pages/Common';
import webView from 'helpers/webview';
import downloadFromUrl from 'helpers/downloadFromUrl';
import { TRIAL_ID } from 'data/constants';
import { linkedTableTattrs } from 'data/mock';

import { ITemplateRenderData } from 'store/templates/templatesApi';
import { selectUserData } from 'store/user/userSlice';
import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';
import { TFormatDocument } from 'interfaces/templates.interface';

import {
  useGetStrictTemplateByIdQuery,
  useGetStrictTemplateSidesQuery,
  useRenderStrictTemplateMutation
} from 'store/strictTemplates/strictTemplatesApi';

import styles from './Filling.module.css';

const Filling = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useSelector(selectUserData);
  const [isRequestOpen, setRequestOpen] = useState<boolean>(false);

  const { data: template, error } = useGetStrictTemplateByIdQuery(id!);
  const { data: templateSides } = useGetStrictTemplateSidesQuery(template?.id!, { skip: !template });
  const { data: currentTariff } = useGetCurrentTariffQuery();

  const [showingModal, setShowingModal] = useState<'paymentLock' | 'trialEnd' | 'signCount' | null>(null);

  const pageError = error as FetchBaseQueryError;

  useEffect(() => {
    if (pageError?.status === 402) navigate('/subscribe');
    // eslint-disable-next-line
  }, [pageError]);

  // if (template && sidesSucess && !templateSides.sides.length) navigate(`/editor/${slug}`, { replace: true });

  const [renderTemplate, { isLoading: isDownloadLoading }] = useRenderStrictTemplateMutation();

  const handleCheckTrial = (mode: 'sign' | 'external') => {
    if (!currentTariff) return false;
    const { tariff, isActive, isPaid } = currentTariff;
    const isTrial = tariff.id === TRIAL_ID;

    if (isTrial && !isActive) {
      setShowingModal('trialEnd');
      return false;
    }
    if (isTrial || !isPaid || !isActive) {
      setShowingModal('paymentLock');
      return false;
    }
    if (mode === 'sign' && user && (!user.workspace.signature_available || user.workspace.signature_available <= user.workspace.signature_used)) {
      setShowingModal('signCount');
      return false;
    }

    return true;
  };

  const handleClickDownload = async (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    format: TFormatDocument = 'xlsx'
  ) => {
    if (!template) return false;
    if (currentTariff?.tariff.id === TRIAL_ID && !currentTariff.isActive) {
      setShowingModal('trialEnd');
      return;
    }

    if (window?.navigator?.userAgent === 'forma-mobile') {
      webView.renderTemplate(template.id, `${name}.${format}`, { name, format, sideValues });
    } else {
      renderTemplate({ id: template.id, name, sideValues })
        .unwrap()
        .then((url: string) => downloadFromUrl(url, `${name}.${format}`));
    }
  };

  if (pageError?.status === 404) return (
    <NotFound />
  );

  return (
    <AdminLayout
      title={t('fill_document_by_template')}
      breadcrumbs={{ items: [{ name: t('my_templates'), to: '/templates', as: Link }, { name: t('fill_by_template') }] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('filling_document')}</PageTitle>
      <FillDocument
        name={template?.name}
        sides={templateSides?.sides}
        blocksTree={templateSides?.blocksTree}
        onClickDownload={handleClickDownload}
        isDownloadLoading={isDownloadLoading}
        checkAllowSelect={handleCheckTrial}
        formats={['xlsx']}
        hideAddSection
        showNameInput
        linkedTableTattrs={linkedTableTattrs}
      />
      <TrialEndModal
        open={showingModal === 'signCount'}
        onClose={() => setShowingModal(null)}
        title={t('extend_sign_limit')}
        description={t('extend_sign_limit_description')}
      // image={<img src="/images/trial-end.svg" alt="" />}
      />
      <TrialEndModal
        open={showingModal === 'trialEnd'}
        onClose={() => setShowingModal(null)}
        title={t('trial_period_end')}
        description={t('trial_end_render_description')}
        image={<img src="/images/trial-end.svg" alt="" />}
      />
      <TrialEndModal
        open={showingModal === 'paymentLock'}
        onClose={() => setShowingModal(null)}
        title={t('sign_only_for_paid')}
        description={
          <div>
            <input type="hidden" autoFocus />
            <Trans i18nKey="sign_only_for_paid_description" />
            <br /><br />
            <div className={styles.flex}>
              <div>
                <Trans
                  i18nKey="sign_only_for_paid_links"
                  components={{
                    ul: <ul />,
                    li: <li />,
                    demo: <span className={styles.link} onClick={() => setRequestOpen(true)} />,
                    // eslint-disable-next-line
                    telegram: <a className={styles.link} href="https://t.me/FormaHelperBot" target="_blank" rel="noreferrer" />,
                    // eslint-disable-next-line
                    whatsapp: <a className={styles.link} href="https://wa.me/79961304591" target="_blank" rel="noreferrer" />,
                    // eslint-disable-next-line
                    email: <a className={styles.link} href="mailto:hello@forma.today" />
                  }}
                />
              </div>
              <img src="/images/trial-lock.svg" alt="" />
            </div>
          </div>
        }
      // image={<img src="/images/trial-lock.svg" alt="" />}
      />

      <RequestDemoModal
        open={isRequestOpen}
        onClose={() => setRequestOpen(false)}
      />
    </AdminLayout>
  );
};

export default Filling;

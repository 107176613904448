import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import getFolderUrl from './model/getFolderUrl';
import FolderContent from './FolderContent';
import { getBackFolder, getFormaFolders } from './model/getFormaFolders';

import { useAppSelector } from 'store/hooks';
import { selectLayout } from 'store/common/commonSlice';
import { useGetFolderContentByNameQuery, useGetFoldersTreeQuery } from 'store/folders/foldersApi';

const Folder = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams();
  const layout = useAppSelector(selectLayout);

  const { data: folder, isLoading: isFoldersLoading, error: pageError }
    = useGetFolderContentByNameQuery(slug as string, { skip: layout === 'list', pollingInterval: 5000 });

  const { data: foldersTree, isLoading: isFoldersTreeLoading }
    = useGetFoldersTreeQuery(undefined, { skip: layout !== 'list', pollingInterval: 5000 });

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status === 404) navigate('/404', { replace: true });
    }
    // eslint-disable-next-line
  }, [pageError]);

  // redirect to correct folder url (based by parent ids)
  const folderUrl = folder && getFolderUrl(folder);
  const isPathCorrect = folderUrl && pathname === folderUrl;

  useEffect(() => {
    if (!isPathCorrect && folderUrl) navigate(folderUrl, { replace: true });
  }, [isPathCorrect, folderUrl, navigate]);

  const backFolder = getBackFolder(t);
  const formaFolders = getFormaFolders(t);

  return (
    <FolderContent
      type="folders"
      folder={folder}
      foldersItems={folder?.items ? [backFolder, ...folder.items] : []}
      foldersTree={foldersTree ? [...formaFolders, ...foldersTree] : []}
      isLoading={isFoldersLoading || isFoldersTreeLoading}
    />
  );
};

export default Folder;

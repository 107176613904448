import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DateSelect, SearchInput, Select } from '@forma/forma-ui-kit';

import styles from './documents-header.module.css';

interface DocumentsHeaderProps {
  onChangeSearch: (value: string) => void,
  onChangeFilter: (params: {
    fromCreatedAt?: string, toCreatedAt?: string, fromConfirmedAt?: string, toConfirmedAt?: string, confirmed?: string
  }) => void,
}

const DocumentsHeader: FC<DocumentsHeaderProps> = ({
  onChangeSearch, onChangeFilter
}) => {
  const { t } = useTranslation();

  const sigendOptions = [
    { label: t('all'), value: '' },
    { label: t('signed'), value: 'true' },
    { label: t('not_signed'), value: 'false' }
  ];

  const handleChangePeriodCreated = (fromCreatedAt: string, toCreatedAt: string) => {
    onChangeFilter({ fromCreatedAt: fromCreatedAt === 'allTime' ? '' : fromCreatedAt, toCreatedAt: toCreatedAt === 'allTime' ? '' : toCreatedAt });
  };

  const handleChangePeriodSigned = (signedAtFrom: string, signedAtTo: string) => {
    onChangeFilter({ fromConfirmedAt: signedAtFrom === 'allTime' ? '' : signedAtFrom, toConfirmedAt: signedAtTo === 'allTime' ? '' : signedAtTo });
  };

  const handleSelectSigned = (e: { target: { name: string, value: string } }) => {
    onChangeFilter({ confirmed: e.target.value });
  };

  return (
    <div className={styles.root}>
      <SearchInput
        onSearch={onChangeSearch}
        className={styles.search}
        placeholder={t('find_needed_document')}
      />
      <div className={styles.select}>
        <DateSelect onChangeDate={handleChangePeriodCreated} placeholder={t('creation_date')} />
      </div>
      <div className={styles.select}>
        <DateSelect onChangeDate={handleChangePeriodSigned} placeholder={t('sign_date')} />
      </div>
      <div className={styles.select}>
        <Select
          name="filter_signed"
          onChange={handleSelectSigned}
          options={sigendOptions}
          placeholder={t('signed')}
        />
      </div>
    </div>
  );
};

export default DocumentsHeader;

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Switcher, Table, TableCell, TableRow } from '@forma/forma-ui-kit';

import styles from './permissions-table.module.css';

interface PermissionsTableProps {
  permissions?: string[],
  selected?: string[],
  onChange: (permissions: string[]) => void
}

const PERMISSION_TYPES = ['view', 'create', 'edit', 'delete', 'download'];

const getPermissionsGroups = (permissions: string[]) => {
  const groups: string[] = [];

  permissions.forEach((permission) => {
    const parts = permission.split(':');
    if (!groups.includes(parts[0])) groups.push(parts[0]);
  });

  return groups;
};

const PermissionsTable: FC<PermissionsTableProps> = ({ permissions, selected, onChange }) => {
  const { t } = useTranslation();

  if (!permissions || !selected) return null;

  const groups: string[] = getPermissionsGroups(permissions);

  return (
    <div className={styles.root}>
      <Table
        columns={[
          { children: t('category'), className: styles.headCell },
          { children: t('permission_head.view'), className: styles.headCell },
          { children: t('permission_head.create'), className: styles.headCell },
          { children: t('permission_head.edit'), className: styles.headCell },
          { children: t('permission_head.delete'), className: styles.headCell },
          { children: t('permission_head.download'), className: styles.headCell },
        ]}
      >
        {groups.map((group) => (
          <TableRow className={styles.row} key={group}>
            <TableCell className={styles.cell}>{t(`permissions.${group}`)}</TableCell>
            {PERMISSION_TYPES.map((type) => {
              const hasPermission = permissions.includes(`${group}:${type}`);
              const isSelected = selected?.includes(`${group}:${type}`);
              return (
                <TableCell className={styles.cellSwitch} title={t(`permission_head.${type}`)} key={type}>
                  {hasPermission && (
                    <Switcher
                      name={t(group + '_' + type)}
                      size="small"
                      containerClass={styles.switcher}
                      checked={isSelected}
                      onChange={e => onChange(e.target.checked ? [...selected, `${group}:${type}`] : selected.filter((id) => id !== `${group}:${type}`))}
                    />
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </Table>
    </div>
  );
};

export default PermissionsTable;

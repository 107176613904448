import { FC, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { SearchInput, Tags, useClickOutside, useLoadingOptions } from '@forma/forma-ui-kit';

import styles from './search-and-select.module.css';

interface SearchAndSelectProps {
  className?: string,
  placeholder: string,
  loadingUrl: string,
  loadingMethod?: 'get' | 'post',
  loadingParam?: string,
  loadingMapper?: (data: any, value: string) => { label: string, value: string }[],
  onChangeSelected: (ids: string[]) => void,
}

const SearchAndSelect: FC<SearchAndSelectProps> = ({
  className, placeholder, loadingUrl, loadingMethod, loadingParam, loadingMapper, onChangeSelected
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { loadedOptions, loadOptions } = useLoadingOptions({ loadingUrl, loadingMethod, loadingParam, loadingMapper });
  useClickOutside(() => setOpen(false), dropdownRef);

  const [open, setOpen] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<{ id: string, name: string }[]>([]);


  const handleSelect = ({ label, value }: { label: string, value: string }) => {
    if (!selectedOptions.find(({ id }) => id === value)) {
      setSelectedOptions([...selectedOptions, { id: value, name: label }]);
      onChangeSelected([...selectedOptions.map(({ id }) => id), value]);
    }
    setOpen(false);
  };

  const handleUnselect = (id: string) => {
    const filtered = selectedOptions.filter(item => item.id !== id);
    setSelectedOptions(filtered);
    onChangeSelected(filtered.map(({ id }) => id));
  };

  return (
    <div className={classNames(styles.root, className)}>
      <SearchInput
        className={styles.input}
        placeholder={placeholder}
        onSearch={loadOptions}
        onFocus={() => setOpen(true)}
      />
      <div className={classNames(styles.dropdown, open && styles.open)} ref={dropdownRef}>
        <div className={styles.list}>
          {loadedOptions?.map(({ label, value }) => (
            <div className={styles.option} onClick={() => handleSelect({ label: String(label), value })} key={value}>
              {label}
            </div>
          ))}
        </div>
      </div>
      <Tags
        className={styles.tags}
        items={selectedOptions.map(({ id, name }) => ({
          name: <div className={styles.tag}>
            {name} <ReactSVG className={styles.tagRemove} src="/icons/close.svg" onClick={() => handleUnselect(id)} />
          </div>,
        }))}
      />
    </div>
  );
};

export default SearchAndSelect;

import { PERMISSIONS } from 'helpers/permissions';

export const menu = [
  {
    id: 'templates',
    href: '/templates',
    lang: 'templates',
    icon: '/icons/menu/templates.svg',
    routes: [
      '/templates/:slug', '/fill-document/:slug', '/favourites', '/static-templates', '/static-templates/:id', '/strict-templates', '/strict-templates/:id',
      '/templates-packs/', '/templates-packs/:id', '/templates-pack-create', '/templates-pack-sides', '/templates-pack-sides/:id', '/fill-templates-pack/:id'
    ],
    permissions: [PERMISSIONS.FOLDERS_TEMPLATES.VIEW]
  },
  {
    id: 'documents',
    href: '/documents',
    lang: 'documents',
    icon: '/icons/menu/documents.svg',
    permissions: [PERMISSIONS.RENDERS.VIEW, PERMISSIONS.EXTERNALINFILLS.VIEW, PERMISSIONS.SIGNED_EXTERNALINFILLS.VIEW]
    // routes: [ '/external-fill' ]
  },
  {
    id: 'products',
    href: '/products',
    lang: 'products_and_services',
    icon: '/icons/menu/products.svg',
    routes: ['/products/:id'],
    permissions: [PERMISSIONS.PRODUCTS.VIEW]
  },
  {
    id: 'contragents',
    href: '/contragents',
    lang: 'contragents',
    icon: '/icons/menu/counteragents.svg',
    routes: ['/contragents/:id'],
    permissions: [PERMISSIONS.COUNTERAGENTS.VIEW]
    // items: [
    //   {
    //     id: 'entity_persons',
    //     href: '/contragents?type=entity',
    //     lang: 'contragents_types.entity',
    //     icon: '/icons/menu/build.svg',
    //   },
    //   {
    //     id: 'individual_persons',
    //     href: '/contragents?type=individual',
    //     lang: 'contragents_types.individual',
    //     icon: '/icons/menu/business.svg',
    //   },
    //   {
    //     id: 'person_persons',
    //     href: '/contragents?type=person',
    //     lang: 'contragents_types.person',
    //     icon: '/icons/menu/user.svg',
    //   },
    // ]
  },
  {
    id: 'safe',
    href: '/safe',
    lang: 'safe',
    icon: '/icons/menu/safe.svg',
    routes: ['/safe/:id'],
    permissions: [PERMISSIONS.ATTACHMENTS.VIEW]
  },
  {
    id: 'subscribe',
    href: '/select-tariff',
    lang: 'payment_and_tariffs',
    icon: '/icons/menu/payment.svg',
  },
  {
    id: 'users',
    href: '/users',
    lang: 'users',
    icon: '/icons/menu/users.svg',
    permissions: [PERMISSIONS.USERS.VIEW],
    routes: ['users/:id', '/invite/:id', '/invite-success']
  },
  {
    id: 'invite_user',
    href: '/invite',
    lang: 'invite_user',
    icon: '/icons/menu/add-user.svg',
    permissions: [PERMISSIONS.USERS.CREATE],
    // routes: [ 'users/:id', '/invite/:id', '/invite-success' ]
  }
];

export const mobileMenu = [
  {
    id: 'templates',
    href: '/templates',
    lang: 'templates',
    icon: '/icons/menu/templates.svg',
    routes: ['/templates/:slug', '/fill-document/:slug', '/favourites'],
    permissions: [PERMISSIONS.FOLDERS_TEMPLATES.VIEW]
  },
  {
    id: 'documents',
    href: '/documents',
    lang: 'documents',
    icon: '/icons/menu/documents.svg',
    disabled: false,
    permissions: [PERMISSIONS.RENDERS.VIEW, PERMISSIONS.EXTERNALINFILLS.VIEW, PERMISSIONS.SIGNED_EXTERNALINFILLS.VIEW]
  },
  {
    id: 'settings',
    href: '/profile',
    lang: 'settings',
    icon: '/icons/menu/settings.svg',
  }
];
